import api from './api'

export default {
  list(categoryId, params = {}) {
    return api.get(`/categories/${categoryId}/breeds`, { params })
  },

  create(categoryId, body) {
    return api.post(`/categories/${categoryId}/breeds`, body)
  },

  update(categoryId, breedId, body) {
    return api.put(`/categories/${categoryId}/breeds/${breedId}`, body)
  },

  delete(categoryId, breedId) {
    return api.delete(`/categories/${categoryId}/breeds/${breedId}`)
  },
}
