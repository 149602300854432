/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import useQuery from '@/hooks/useQuery'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Flexbox from '../Flexbox'
import Form from '../Form'
import * as Styles from '../styles'
import { Container, Content } from './styles'

function fillValues(hash, values) {
  const filled = { ...hash }
  for (const key of Object.keys(hash)) {
    filled[key] = values[key] || hash[key]
  }

  return filled
}

function Filter({ title, initialValues, renderForm }) {
  const formikRef = useRef()
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()

  const q = query?.q || {}
  const [active, setActive] = useState(false)

  const onSubmit = useCallback((params) => {
    const newQuery = { ...query, q: params }
    history.replace(`${location.pathname}?${qs.stringify(newQuery)}`)
    setActive(false)
  }, [history, location.pathname, query])

  const filterLength = Object.keys(q).filter((key) => {
    const field = q[key]
    if (field === 'false') return false
    if (!field) return false
    if (typeof field === 'object') {
      if (!field[0] && !field[1]) return false
    }
    return true
  }).length

  const filtered = filterLength > 0

  function clear() {
    formikRef.current.resetForm()
    const newQuery = { ...query, q: {} }
    history.replace(`${location.pathname}?${qs.stringify(newQuery)}`)
    setActive(false)
  }

  return (
    <>
      <Styles.Button
        onClick={() => setActive(true)}
        className={`btn-link mr-2 ${filtered ? 'badge' : ''}`}
        data-badge={filterLength}
      >
        <i className="fas fa-filter" />
      </Styles.Button>
      <Form.Formik
        innerRef={formikRef}
        initialValues={fillValues(initialValues, q)}
        onSubmit={onSubmit}
      >
        {
          (options) => (
            <Form.ValidationForm>
              <Container className={active ? 'active' : ''}>
                <Flexbox.Columns className="p-2 align-center">
                  <Flexbox.Column className="col-auto">
                    <Styles.Button type="button" className="btn-link tooltip tooltip-right" data-tooltip="Recolher" onClick={() => setActive(false)}>
                      <i className="fas fa-arrow-right" />
                    </Styles.Button>
                  </Flexbox.Column>
                  <Flexbox.Column className="text-ellipsis">
                    <div className="h5 text-primary text-ellipsis">{title}</div>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <Content className="p-2">
                  {renderForm(options)}
                  <div className="text-right">
                    {
                      filtered
                      && (
                        <Styles.Button type="button" onClick={clear} className="btn btn-link btn-sm mr-1">
                          <i className="fas fa-times" />
                          &nbsp;Limpar
                        </Styles.Button>
                      )
                    }
                    <Styles.Button type="submit" className="btn-primary btn-sm">
                      <i className="fas fa-filter" />
                      &nbsp;Filtrar
                    </Styles.Button>
                  </div>
                </Content>
              </Container>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </>
  )
}

Filter.propTypes = {
  title: PropTypes.string,
  initialValues: PropTypes.objectOf(PropTypes.any),
  renderForm: PropTypes.func,
}

Filter.defaultProps = {
  title: 'Filtrar',
  initialValues: {},
  renderForm: () => null,
}

export default Filter
