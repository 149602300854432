import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import { CASTRATION_STATUS } from '@/lib/enums'

function CastrationCard({ castration }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Castração</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-cut" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Status</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {CASTRATION_STATUS.t(castration?.status)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Solicitada em</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(castration?.createdAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Última atualização</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(castration?.updatedAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
    </Card.Base>
  )
}

CastrationCard.propTypes = {
  castration: PropTypes.objectOf(Object).isRequired,
}

export default CastrationCard
