import { Form, Modal, Styles } from '@/components'
import yup from '@/lib/yupPt'
import categories from '@/services/api/categories'
import React, {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().required(),
})
const categoryForm = { description: '' }

const FormCategory = forwardRef(({ onUpdate, onCreate }, ref) => {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState(categoryForm)

  function open(preload = categoryForm) {
    setCategory(preload)
    setOpened(true)
  }

  useImperativeHandle(ref, () => ({ open }))

  function close() {
    setCategory(categoryForm)
    setOpened(false)
  }

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      if (category?.id) {
        const { data } = await categories.update(category?.id, form)
        onUpdate(data)
        toast(`${form?.description} atualizado`, { type: 'success' })
      } else {
        const { data } = await categories.create(form)
        onCreate(data)
        toast(`${form?.description} adicionado`, { type: 'success' })
      }
      close()
    } catch (e) {
      toast(e?.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }, [category?.id, onCreate, onUpdate])

  if (!opened) return null

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={category}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {
          ({
            errors, touched, isValid, dirty,
          }) => (
            <Form.ValidationForm>
              <Modal.Base className="active">
                <Modal.Overlay onClick={close} />
                <Modal.Container>
                  <Modal.Header>
                    <Modal.Close onClick={close} />
                    <Modal.Title>Espécie</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Modal.Content>
                      <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="description"
                          autoFocus
                          id="description"
                          autoComplete="off"
                          placeholder="Cachorro, Gato, Ave"
                        />
                        {
                          touched?.description && errors?.description
                            ? <Form.Hint>{errors.description}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Modal.Content>
                  </Modal.Body>
                  <Modal.Footer>
                    <Styles.Button
                      type="submit"
                      className="btn-primary"
                      disabled={!dirty || !isValid}
                    >
                      <i className="fas fa-save" />
                      &nbsp;Salvar
                    </Styles.Button>
                  </Modal.Footer>
                </Modal.Container>
              </Modal.Base>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
})

FormCategory.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default FormCategory
