import { primaryColor } from '@/styles/variables'
import styled from 'styled-components'

export const Container = styled.div.attrs({ className: 's-rounded' })`
  width: 150px;
  height: 150px;
`

export const Marker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${primaryColor};
  position: absolute;
  transform: translate(-50%, -100%);
`
