import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import { CASTRATION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export default function CastrationsCard({ castrations }) {
  if (!castrations) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fas fa-cut" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">
              Castrações <small>({castrations.length} {castrations.length === 1 ? 'castração' : 'castrações'})</small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 500, overflowY: 'auto' }}>
        {
          castrations?.length
            ? (
              <table className="table table-small">
                <thead>
                  <tr>
                    <th width="80px">#</th>
                    <th>Pet</th>
                    <th>Fornecedor</th>
                    <th>Status</th>
                    <th width="150px">Solicitado em</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    castrations.map((castration) => (
                      <tr key={castration.id}>
                        <td>{castration.id}</td>
                        <td>
                          <Avatar avatarSize="sm" name={castration?.animal?.name} photoURL={castration?.animal?.photoURL} />
                          <span>&nbsp;{castration.animal.name}</span>
                        </td>
                        <td>
                          <small>
                            <Avatar avatarSize="sm" name={castration?.provider?.fullName} photoURL={castration?.provider?.photoURL} />
                            <span>&nbsp;{castration.provider.fullName}</span>
                          </small>
                        </td>
                        <td className={CASTRATION_STATUS.class[castration.status]}>
                          {CASTRATION_STATUS.t(castration.status)}
                        </td>
                        <td>
                          <small>{dateMask(castration.createdAt, { minute: '2-digit', hour: '2-digit' })}</small>
                        </td>
                        <td className="text-right" width="130">
                          <Link
                            to={`/app/castrations/status/${castration?.status}/${castration?.id}`}
                            className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                            data-tooltip="Visualizar"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <ErrorBox
                error={{
                  icon: 'fas fa-cut fa-2x',
                  title: 'Nenhuma castração',
                  message: 'Não há histórico de castrações',
                }}
              />
            )
        }
      </Card.Body>
    </Card.Base>
  )
}

CastrationsCard.propTypes = {
  castrations: PropTypes.arrayOf(Object),
}

CastrationsCard.defaultProps = {
  castrations: [],
}
