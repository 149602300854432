import { primaryColor } from '@/styles/variables'
import styled from 'styled-components'

export const Cluster = styled.div`
  color: #fff;
  background: ${primaryColor};
  border-radius: 50%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .1s ease-in-out;
  border: 3px solid #c17e3f;
  animation-name: bounce-in;
  animation-duration: .4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`

export const Point = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #c17e3f;
  transition: all .1s ease-in-out;
  animation-name: bounce-in;
  animation-duration: .4s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
    z-index: 9999;
  }
`
