import styled from 'styled-components'

export const FormAutocomplete = styled.div.attrs({
  className: 'form-autocomplete',
})``

export const FormAutocompleteInput = styled.div.attrs({
  className: 'form-autocomplete-input form-input',
})``

export const Input = styled.input.attrs({
  className: 'form-input',
  type: 'search',
})``

export const Menu = styled.ul.attrs({
  className: 'menu',
})`
  max-height: 300px;
  overflow-y: auto;
`

export const MenuItem = styled.li.attrs({
  className: 'menu-item',
})``
