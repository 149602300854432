import {
  Card as Base, Body, Header, Footer,
} from './styles'

export default {
  Base,
  Body,
  Footer,
  Header,
}
