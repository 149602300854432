/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import {
  Flexbox, PageHeader, Styles, Form, Autocomplete,
  Mask,
} from '@/components'
import yup from '@/lib/yupPt'
import { LICENSE_TYPE } from '@/lib/enums'
import { Field } from 'formik'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import partnerLicensings from '@/services/api/partnerLicensings'

const schema = yup.object().shape({
  person: yup.object().shape({
    fullName: yup.string().trim().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string(),
    documentNumber: yup.string().required(),
    password: yup.string().min(6).required(),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Senhas não conferem').required(),
  }),
  partnerLicensing: yup.object().shape({
    city: yup.object().shape({
      id: yup.number().required(),
    }),
    licenseTypes: yup.array().of(yup.string()),
  }),
})

export default function FormLicensing({ title }) {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  usePageTitle({ title })

  async function onSubmit(values) {
    try {
      setLoading(true)
      const { passwordConfirm, ...params } = values
      await partnerLicensings.create(params)
      toast.success('Parceiro criado')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(true)
    }
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Adicionar parceiro"
        icon="fas fa-plus"
      />
      <p>Informe os dados do novo parceiro</p>
      <div className="divider text-center" data-content="Informações da conta do licenciado" />
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            person: {
              fullName: '',
              email: '',
              phoneNumber: '',
              documentNumber: '',
              password: '',
              passwordConfirm: '',
            },
            partnerLicensing: {
              licenseTypes: [],
              city: null,
            },
          }}
          validationSchema={schema}
          validateOnMount
          onSubmit={onSubmit}
        >
          {
            ({
              errors, touched, isValid, setFieldValue, values,
            }) => (
              <Form.ValidationForm>
                <Flexbox.Columns>
                  <Flexbox.Column className="col-8 col-sm-12 py-2">
                    <Form.Group className={`${touched?.person?.fullName && errors?.person?.fullName ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.fullName">Nome do licenciado <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="person.fullName"
                        id="person.fullName"
                        autoComplete="off"
                        placeholder="Nome do licenciado"
                      />
                      {
                        touched?.person?.fullName && errors?.person?.fullName
                          ? <Form.Hint>{errors.person?.fullName}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-4 py-2 col-sm-12">
                    <Form.Group className={`${touched?.person?.documentNumber && errors?.person?.documentNumber ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.documentNumber">CPF/CNPJ do licenciado <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField name="person.documentNumber">
                        {
                          ({ field }) => (
                            <Mask.CpfCnpj
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              placeholder="CPF/CNPJ do licenciado"
                              id="person.documentNumber"
                              onAccept={(value) => {
                                setFieldValue('person.documentNumber', value)
                              }}
                            />
                          )
                        }
                      </Form.ValidationField>
                      {
                        touched?.person?.documentNumber && errors?.person?.documentNumber
                          ? <Form.Hint>{errors.person?.documentNumber}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 py-2 col-sm-12">
                    <Form.Group className={`${touched?.person?.email && errors?.person?.email ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.email">E-mail do licenciado <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="person.email"
                        id="person.email"
                        autoComplete="off"
                        type="email"
                        placeholder="E-mail do licenciado"
                      />
                      {
                        touched?.person?.email && errors?.person?.email
                          ? <Form.Hint>{errors.person?.email}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 py-2 col-sm-12">
                    <Form.Group className={`${touched?.person?.phoneNumber && errors?.person?.phoneNumber ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.phoneNumber">Telefone do licenciado</Form.Label>
                      <Form.ValidationField
                        name="person.phoneNumber"
                        id="person.phoneNumber"
                        autoComplete="off"
                        placeholder="Telefone do licenciado"
                      />
                      {
                        touched?.person?.phoneNumber && errors?.person?.phoneNumber
                          ? <Form.Hint>{errors.person?.phoneNumber}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 py-2 col-sm-12">
                    <Form.Group className={`${touched?.person?.password && errors?.person?.password ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.password">Senha do licenciado <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="person.password"
                        id="person.password"
                        type="password"
                        autoComplete="off"
                        placeholder="Senha do licenciado"
                      />
                      {
                        touched?.person?.password && errors?.person?.password
                          ? <Form.Hint>{errors.person?.password}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 py-2 col-sm-12">
                    <Form.Group className={`${touched?.person?.passwordConfirm && errors?.person?.passwordConfirm ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="person.passwordConfirm">Confirme a senha do licenciado <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="person.passwordConfirm"
                        id="person.passwordConfirm"
                        type="password"
                        autoComplete="off"
                        placeholder="Senha do licenciado"
                      />
                      {
                        touched?.person?.passwordConfirm && errors?.person?.passwordConfirm
                          ? <Form.Hint>{errors.person?.passwordConfirm}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="divider text-center" data-content="Detalhes da licença" />

                <Form.Group className={`py-2 ${touched?.partnerLicensing?.city?.id && errors?.partnerLicensing?.city?.id ? 'has-error' : ''}`}>
                  <Form.Label>Selecione a cidade da licença <span className="text-error">*</span></Form.Label>
                  <Field
                    name="partnerLicensing.city"
                  >
                    {
                      () => (
                        <Autocomplete.City
                          selected={values?.partnerLicensing?.city}
                          onSelect={(v) => setFieldValue('partnerLicensing.city', v)}
                        />
                      )
                    }
                  </Field>
                </Form.Group>

                <div className="my-2">Selecione as permissões para essa licença</div>
                {
                  Object.values(LICENSE_TYPE.enum).map((i) => (
                    <div key={i} className="form-group">
                      <label className="form-switch">
                        <Field type="checkbox" name="partnerLicensing.licenseTypes" value={i} />
                        <i className="form-icon" />
                        {LICENSE_TYPE.t(i)}
                      </label>
                    </div>
                  ))
                }
                <div className="text-right">
                  <button type="submit" disabled={!isValid} className="btn btn-primary">
                    <i className="fas fa-check" />
                    &nbsp;Salvar
                  </button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </Styles.Container>
  )
}
