export const ANIMAL_SIZE = {
  enum: {
    TOY: 'toy',
    SMALL: 'small',
    MEDIUM: 'medium',
    BIG: 'big',
    GIANT: 'giant',
  },
  translations: {
    toy: 'Toy',
    small: 'Pequeno',
    medium: 'Médio',
    big: 'Grande',
    giant: 'Gigante',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const ANIMAL_GENDER = {
  enum: {
    MALE: 'male',
    FEMALE: 'female',
  },
  translations: {
    male: 'Macho',
    female: 'Fêmea',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const LICENSE_TYPE = {
  enum: {
    CASTRATION_PROVIDER: 'castrationProvider',
    CHIP_PROVIDER: 'chipProvider',
    FAIRS_MANAGER: 'fairsManager',
    CLINICAL_CARE: 'clinicalCare',
    MISTREATMENT: 'mistreatment',
  },
  translations: {
    castrationProvider: 'Prover castração',
    chipProvider: 'Prover chip',
    fairsManager: 'Gerenciar feiras',
    clinicalCare: 'Atendimentos clínicos',
    mistreatment: 'Denúncias de maus tratos',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const PARTNER_REQUEST_STATUS = {
  enum: {
    REQUESTED: 'requested',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    COMPLETED: 'completed',
  },
  translations: {
    requested: 'Solicitada',
    approved: 'Aprovada',
    rejected: 'Rejeitada',
    completed: 'Cadastro concluído',
  },
  class: {
    requested: 'text-warning',
    approved: 'text-dark',
    rejected: 'text-error',
    completed: 'text-success',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const CASTRATION_STATUS = {
  enum: {
    REQUESTED: 'requested',
    CORRECTION: 'correction',
    CORRECTED: 'corrected',
    REVISION: 'revision',
    PRE_APPROVED: 'preApproved',
    APPROVED: 'approved',
    SCHEDULED: 'scheduled',
    ATTENDANCE: 'attendance',
    AWAITING_CONFIRMATION: 'awaitingConfirmation',
    COMPLETED: 'completed',
    REJECTED: 'rejected',
    CANCELED: 'canceled',
  },
  translations: {
    requested: 'Solicitada',
    correction: 'Em correção',
    corrected: 'Corrigida',
    revision: 'Em revisão',
    preApproved: 'Pré aprovada',
    approved: 'Aprovada',
    scheduled: 'Agendada',
    attendance: 'Em atendimento',
    awaitingConfirmation: 'Aguardando confirmar',
    completed: 'Concluída',
    rejected: 'Rejeitada',
    canceled: 'Cancelada',
  },
  class: {
    requested: 'text-primary',
    correction: 'text-primary',
    corrected: 'text-primary',
    revision: 'text-primary',
    preApproved: 'text-success',
    approved: 'text-success',
    scheduled: 'text-success',
    attendance: 'text-success',
    awaitingConfirmation: 'text-success',
    completed: 'text-success',
    rejected: 'text-error',
    canceled: 'text-error',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const PARTNER_LICENSING_STATUS = {
  enum: {
    ACTIVE: 'active',
    CANCELED: 'canceled',
  },
  translations: {
    active: 'Ativo',
    canceled: 'Cancelado',
  },
  class: {
    active: 'text-success',
    canceled: 'text-error',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const RESET_EMAIL_STATUS = {
  enum: {
    REQUESTED: 'requested',
    REJECTED: 'rejected',
    APPROVED: 'approved',
  },
  translations: {
    requested: 'Solicitado',
    rejected: 'Rejeitado',
    approved: 'Aprovado',
  },
  class: {
    requested: 'text-primary',
    approved: 'text-success',
    rejected: 'text-error',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const ADOPTION_FAIR_STATUS = {
  enum: {
    DRAFT: 'draft',
    CREATED: 'created',
    RUNNING: 'running',
    CLOSED: 'closed',
    CANCELED: 'canceled',
  },
  translations: {
    draft: 'Rascunho',
    created: 'Criada',
    running: 'Em andamento',
    closed: 'Fechada',
    canceled: 'Cancelada',
  },
  class: {
    draft: '',
    created: 'text-primary',
    running: 'text-success',
    closed: 'text-error',
    canceled: 'text-error',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const ADOPTION_STATUS = {
  enum: {
    REQUESTED: 'requested',
    CONFIRMED: 'confirmed',
    REJECTEDL: 'rejected',
    CANCELEDL: 'canceled',
  },
  translations: {
    requested: 'Solicitada',
    confirmed: 'Confirmada',
    rejected: 'Rejeitada',
    canceled: 'Cancelada',
  },
  class: {
    requested: 'text-primary',
    confirmed: 'text-success',
    rejected: 'text-error',
    canceled: 'text-error',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const COMPLAINT_TYPE = {
  enum: {
    INAPPROPRIATE_CONTENT: 'inappropriateContent',
    VIOLENCE: 'violence',
    IS_NOT_A_PET: 'isNotAPet',
    FRAUD: 'fraud',
    OTHER: 'other',
  },
  translations: {
    inappropriateContent: 'Conteúdo inapropriado',
    violence: 'Violência',
    isNotAPet: 'Não é um animal',
    fraud: 'Fraude',
    other: 'Outro',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const NEWS_TYPE = {
  enum: {
    HTML: 'html',
    RAW: 'raw',
  },
  translations: {
    html: 'HTML',
    raw: 'Texto livre',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const NEWS_DISTRIBUTION = {
  enum: {
    PARTNER: 'partner',
    CITIZEN: 'citizen',
    CLINIC: 'clinic',
    ALL: 'all',
  },
  translations: {
    partner: 'Parceiros',
    citizen: 'Usuários',
    clinic: 'Clínicas',
    all: 'Todos',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const LICENSING_TYPE = {
  enum: {
    CASTRATOR: 'castrator',
    ROTECTOR: 'protector',
    CHIPS_APPLYER: 'chipsApplyer',
    NGO: 'ngo',
  },
  translations: {
    castrator: 'Castrador',
    protector: 'Protetor',
    chipsApplyer: 'Aplicador de Chip',
    ngo: 'ONG',
  },
  t(key) {
    return this.translations[key] || key
  },
}

export const VACCINE_TYPE = {
  enum: {
    MULTIPLE_PROTECTION: 'multipleProtection',
    ANTI_RABIES: 'antiRabies',
    ANTI_FLU: 'antiFlu',
    GIARDIA: 'giardia',
    VERMIFUGE: 'vermifuge',
    TICKICIDE: 'tickicide',
    OTHER: 'other',
  },
  translations: {
    multipleProtection: 'Proteção múltipla',
    antiRabies: 'Antirrábica',
    antiFlu: 'Anti gripe',
    giardia: 'Giardia',
    vermifuge: 'Vermífugo',
    tickicide: 'Pulgas/Carrapatos',
    other: 'Outro',
  },
  t(key) {
    return this.translations[key] || key
  },
}
