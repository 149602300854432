export const CASTRATION_STATUS = {
  REQUESTED: 'requested',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  APPROVED: 'approved',
  PRE_APPROVED: 'preApproved',
  SCHEDULED: 'scheduled',
  REVISION: 'revision',
  ATTENDANCE: 'attendance',
  CORRECTED: 'corrected',
  CORRECTION: 'correction',
  AWAITING_CONFIRMATION: 'awaitingConfirmation',
  COMPLETED: 'completed',
}

export const statusMap = {
  solicitadas: 'requested',
  aprovadas: 'approved',
  'pré aprovadas': 'preApproved',
  rejeitadas: 'rejected',
  agendadas: 'scheduled',
  corrigigas: 'corrected',
  canceladas: 'canceled',
  revisadas: 'revision',
  aguardando: 'awaitingConfirmation',
  'em atendimento': 'attendance',
  concluidas: 'completed',
}

export const singularStatusMapLabel = {
  requested: 'Solicitada',
  rejected: 'Rejeitada',
  canceled: 'Cancelada',
  scheduled: 'Agendada',
  approved: 'Aprovada',
  preApproved: 'Pré aprovada',
  revision: 'Em vistoria',
  correction: 'Correção',
  inTreatment: 'Em tratamento',
  corrected: 'Corrigida pelo tutor',
  attendance: 'Em atendimento',
  awaitingConfirmation: 'Aguardando confirmar',
  completed: 'Concluída',
}

export const textClasses = {
  requested: 'text-primary',
  rejected: 'text-error',
  canceled: 'text-error',
  approved: 'text-primary',
  preApproved: 'text-primary',
  scheduled: 'text-primary',
  revision: 'text-primary',
  correction: 'text-primary',
  corrected: 'text-primary',
  attendance: 'text-primary',
  awaitingConfirmation: 'text-primary',
  completed: 'text-success',
}
