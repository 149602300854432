import { Flexbox, Form, QuickFilter } from '@/components'
import React from 'react'

function CastrationsQuickFilter() {
  return (
    <QuickFilter
      initialValues={{
        'animal.categoryId_eq': '', 'animal.gender_eq': '', 'licensings.type_eq': '',
      }}
      title="Filtrar castrações"
      renderForm={() => (
        <>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animal.categoryId_eq">Espécie do pet</Form.Label>
              <Form.ValidationField name="['animal.categoryId_eq']">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animal.categoryId_eq"
                      className="select-sm"
                      placeholder="Espécie do pet"
                    >
                      <option value="">Todos</option>
                      <option value="1">Cachorro</option>
                      <option value="2">Gato</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animal.gender_eq">Gênero do pet</Form.Label>
              <Form.ValidationField name="['animal.gender_eq']">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animal.gender_eq"
                      className="select-sm"
                      placeholder="Gênero do pet"
                    >
                      <option value="">Todos</option>
                      <option value="male">Macho</option>
                      <option value="female">Fêmea</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="licensings.type_eq">Licença do tutor</Form.Label>
              <Form.ValidationField name="['licensings.type_eq']">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="licensings.type_eq"
                      className="select-sm"
                      placeholder="Licença do tutor"
                    >
                      <option value="">Todos</option>
                      <option value="protector">Protetor</option>
                      <option value="ngo">ONG</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
        </>
      )}
    />
  )
}

export default CastrationsQuickFilter
