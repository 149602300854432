import api from './api'

export default {
  list(params = {}) {
    return api.get('/reset-emails', { params })
  },
  show(id) {
    return api.get(`/reset-emails/${id}`)
  },
  reject(id, params = {}) {
    return api.post(`/reset-emails/${id}/reject`, params)
  },
  approve(id) {
    return api.post(`/reset-emails/${id}/approve`)
  },
}
