import api from './api'

export default {
  list() {
    return api.get('/rejection-reasons')
  },

  update(id, body) {
    return api.put(`/rejection-reasons/${id}`, body)
  },

  create(body) {
    return api.post('/rejection-reasons', body)
  },
}
