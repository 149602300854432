import { Avatar, Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ANIMAL_GENDER, ANIMAL_SIZE } from '@/lib/enums'
import animalUid from '@/lib/masks/animalUid'
import { Tag } from './styles'

function AnimalCard({ animal, castration }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>
            <Flexbox.Columns>
              <Flexbox.Column className="col-auto">
                <Avatar
                  avatarSize="lg"
                  name={animal?.name}
                  photoURL={animal?.photoURL}
                  smart={false}
                  alt="Imagem do animal"
                />
              </Flexbox.Column>
              <Flexbox.Column>
                <Link to={`/app/animals/${animal?.id}`}>
                  <div className="text-primary text-bold">{animal?.name}</div>
                </Link>
                <div className="text-small">{animalUid(animal?.uid)}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Raça</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.breed?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Espécie</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.category?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Sexo</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL_GENDER.t(animal?.gender)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Nascimento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(animal?.bornedAt)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Porte</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL_SIZE.t(animal?.size)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Chip</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.chip?.uid || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          animal?.adoptions?.length ? (
            <div className="pt-2">
              <Flexbox.Columns className="mb-1">
                <Tag>
                  Adotado em Feira de adoção:
                  <b>&nbsp;{animal.adoptions[0]?.fair?.title}</b>
                </Tag>
              </Flexbox.Columns>
            </div>
          ) : null
        }
        {
          castration?.animalAgeInMonths <= 6 ? (
            <div className="pt-2">
              <Flexbox.Columns className="mb-1">
                <Tag>
                  Animal com até 6 meses de idade
                </Tag>
              </Flexbox.Columns>
            </div>
          ) : null
        }
      </Card.Body>
    </Card.Base>
  )
}

AnimalCard.propTypes = {
  animal: PropTypes.objectOf(Object).isRequired,
  castration: PropTypes.objectOf(Object).isRequired,
}

export default AnimalCard
