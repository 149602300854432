import currency from '@/lib/masks/currency'
import PropTypes from 'prop-types'
import React from 'react'
import {
  CartesianGrid,
  Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts'

function ChartLine({ data, lineDataKey, xAxisDataKey }) {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis fontSize={10} dataKey={xAxisDataKey} />
        <YAxis domain={[0, Math.max(0, ...(data?.map((i) => i[lineDataKey]) || []))]} />
        <Tooltip cursor={{ fill: 'transparent' }} />
        <Line type="monotone" dataKey={lineDataKey} stroke="#e49042" transform={currency} />
      </LineChart>
    </ResponsiveContainer>
  )
}

ChartLine.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  lineDataKey: PropTypes.string.isRequired,
  xAxisDataKey: PropTypes.string.isRequired,
}

export default ChartLine
