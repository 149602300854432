export const INITIAL_STATE = { user: null }

export default function userReducer(state, action) {
  switch (action.type) {
    case ('setUser'): return { user: action.payload }
    default: return INITIAL_STATE
  }
}

export function setUser(payload) {
  return { type: 'setUser', payload }
}
