import React, {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Styles } from '@/components'
import { Field } from 'formik'
import yup from '@/lib/yupPt'

const settings = {
  approve: {
    title: 'Aprovar adesão',
    placeholder: 'Adicione um comentário',
    button: {
      label: 'Aprovar',
      icon: 'fas fa-check',
      class: 'btn-primary',
    },
    schema: yup.object().shape({ description: yup.string().required() }),
  },
  reject: {
    title: 'Rejeitar adesão',
    placeholder: 'Descreva o motivo da rejeição',
    button: {
      label: 'Rejeitar',
      icon: 'fas fa-times',
      class: 'btn-error',
    },
    schema: yup.object().shape({ description: yup.string().required() }),
  },
}

const CommentPicker = forwardRef(({ onApprove, onReject }, ref) => {
  const [mode, setMode] = useState('approve')
  const [opened, setOpened] = useState(false)

  function open(currentMode = 'approve') {
    setMode(currentMode)
    setOpened(true)
  }

  useImperativeHandle(ref, () => ({ open }))

  function close() {
    setMode('approve')
    setOpened(false)
  }

  const onSubmit = useCallback((form) => {
    if (mode === 'approve') onApprove(form.description)
    else onReject(form.description)

    close()
  }, [mode, onApprove, onReject])

  const componentSettings = settings[mode]

  if (!opened) return null

  return (
    <Form.Formik
      initialValues={{ description: '' }}
      validationSchema={componentSettings.schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {
        ({ errors, touched, isValid }) => (
          <Form.ValidationForm>
            <Modal.Base className="active">
              <Modal.Overlay onClick={close} />
              <Modal.Container>

                <Modal.Header>
                  <Modal.Close onClick={close} />
                  <Modal.Title>{componentSettings.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Modal.Content>
                    <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="description">Descrição {mode === 'reject' && <span className="text-error">*</span>}</Form.Label>
                      <Field
                        name="description"
                        className="form-input"
                        as="textarea"
                        autoFocus
                        id="description"
                        placeholder={componentSettings.placeholder}
                      />
                      {
                        touched?.description && errors?.description
                          ? <Form.Hint>{errors.description}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Modal.Content>
                </Modal.Body>
                <Modal.Footer>
                  <Styles.Button
                    type="submit"
                    className={componentSettings.button.class}
                    disabled={!isValid}
                  >
                    <i className={componentSettings.button.icon} />
                        &nbsp;{componentSettings.button.label}
                  </Styles.Button>
                </Modal.Footer>
              </Modal.Container>
            </Modal.Base>
          </Form.ValidationForm>
        )
      }
    </Form.Formik>
  )
})

CommentPicker.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
}

export default CommentPicker
