import styled from 'styled-components'

export const Base = styled.div.attrs({
  className: 'box',
})``

export const Content = styled.div.attrs({
  className: 'box-content',
})``

export const Container = styled.div.attrs({
  className: 'box-container',
})``

export const Title = styled.div.attrs({
  className: 'box-title',
})``

export const Body = styled.div.attrs({
  className: 'box-body',
})``

export const Value = styled.div.attrs({
  className: 'box-value',
})``

export const Icon = styled.div.attrs({
  className: 'box-icon',
})``
