import React from 'react'
import PropTypes from 'prop-types'
import { Chip as ChipComponent, Remove } from './styles'

function Chip({ item, onRemove }) {
  return (
    <ChipComponent>
      {item?.name} - {item?.uf}
      <Remove onClick={onRemove} />
    </ChipComponent>
  )
}

Chip.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default Chip
