import React from 'react'
import PropTypes from 'prop-types'
import LoadManager from '../LoadManager'

function ListManager({
  children, error, loading, count, emptyMessage,
   emptySubtitle, emptyIcon, emptyAction,
}) {
  if (!loading && !count && !error) {
    return (
      <div className="empty">
        <div className="empty-icon">
          <i className={`fa-3x ${emptyIcon}`} />
        </div>
        <p className="empty-title h5">{emptyMessage}</p>
        <p className="empty-subtitle">{emptySubtitle}</p>
        {emptyAction ? (
          <div className="empty-action">
            {emptyAction}
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <LoadManager error={error} loading={loading}>
      {children}
    </LoadManager>
  )
}

ListManager.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  count: PropTypes.number.isRequired,
  error: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
  emptySubtitle: PropTypes.string,
  emptyIcon: PropTypes.string,
  emptyAction: PropTypes.element,
}

ListManager.defaultProps = {
  children: <></>,
  error: null,
  loading: false,
  emptyMessage: 'Lista vazia',
  emptySubtitle: 'Nenhum registro encontrado',
  emptyIcon: 'fas fa-folder-open',
  emptyAction: null,
}
export default ListManager
