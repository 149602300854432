import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-requests', { params })
  },

  show(id) {
    return api.get(`/partner-requests/${id}`)
  },

  approve(id, body) {
    return api.post(`/partner-requests/${id}/approve`, body)
  },

  reject(id, body) {
    return api.post(`/partner-requests/${id}/reject`, body)
  },
}
