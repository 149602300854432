import React from 'react'
import { Avatar, ErrorBox } from '@/components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CASTRATION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import { castrationUrl } from '@/lib/urlMaker'

export default function CastrationsRow({ castrations }) {
  if (!castrations) return null

  return (
    <>
      {
        castrations?.length
          ? (
            <table className="table text-small">
              <thead>
                <tr>
                  <th width="80px">#</th>
                  <th>Pet</th>
                  <th>Fornecedor</th>
                  <th>Status</th>
                  <th width="150px">Solicitado em</th>
                  <th className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  castrations.map((castration) => (
                    <tr key={castration.id}>
                      <td>{castration.id}</td>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={castration?.animal?.name}
                          photoURL={castration?.animal?.photoURL}
                        />
                        <span>&nbsp;{castration?.animal?.name}</span>
                      </td>
                      <td>
                        <small>
                          <Avatar
                            avatarSize="sm"
                            name={castration?.provider?.fullName}
                            photoURL={castration?.provider?.photoURL}
                          />
                          <span>&nbsp;{castration?.provider?.fullName}</span>
                        </small>
                      </td>
                      <td className={CASTRATION_STATUS.class[castration?.status]}>
                        {CASTRATION_STATUS.t(castration?.status)}
                      </td>
                      <td>
                        <small>{dateMask(castration?.createdAt, { minute: '2-digit', hour: '2-digit' })}</small>
                      </td>
                      <td className="text-right" width="130">
                        <Link
                          to={`/app/castrations/status/${castration?.status}/${castration?.id}`}
                          className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                          data-tooltip="Visualizar"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                        <a
                          href={castrationUrl(castration)}
                          data-tooltip="Imprimir"
                          className="btn btn-action btn-sm btn-primary tooltip tooltip-top ml-1"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fas fa-print" />
                        </a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fas fa-cut fa-2x',
                title: 'Nenhuma castração',
                message: 'Não há histórico de castrações',
              }}
            />
          )
      }
    </>
  )
}

CastrationsRow.propTypes = {
  castrations: PropTypes.arrayOf(Object),
}

CastrationsRow.defaultProps = {
  castrations: [],
}
