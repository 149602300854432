import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings', { params })
  },
  create(body) {
    return api.post('/partner-licensings', body)
  },
  update(id, body = {}) {
    return api.put(`/partner-licensings/${id}`, body)
  },
}
