import {
  ErrorBox, Filter, Form, ListManager,
  Mask, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import people from '@/services/api/people'
import React from 'react'
import { Link } from 'react-router-dom'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, error, status,
  } = useAxiosRequest(people.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de usuários"
        icon="fas fa-users"
        rightAction={() => (
          <Filter
            initialValues={{
              fullName_cont: '', email_eq: '', documentNumber_eq: '', phoneNumber_eq: '',
            }}
            title="Filtrar usuários"
            renderForm={({ setFieldValue }) => (
              <>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="fullName_cont">Nome</Form.Label>
                  <Form.ValidationField
                    name="fullName_cont"
                    className="input-sm"
                    id="fullName_cont"
                    autoComplete="none"
                    placeholder="Fulano da Silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="email_eq">E-mail</Form.Label>
                  <Form.ValidationField
                    name="email_eq"
                    className="input-sm"
                    id="email_eq"
                    type="email"
                    autoComplete="none"
                    placeholder="usuario@dominio.com"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="documentNumber_eq">Documento</Form.Label>
                  <Form.ValidationField name="documentNumber_eq">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF ou CNPJ"
                          id="documentNumber_eq"
                          onAccept={(value) => {
                            setFieldValue('documentNumber_eq', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="phoneNumber_eq">Telefone</Form.Label>
                  <Form.ValidationField name="phoneNumber_eq">
                    {
                      ({ field }) => (
                        <Mask.Phone
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="(##) #####-####"
                          id="phoneNumber_eq"
                          autoComplete="none"
                          onAccept={(value) => {
                            setFieldValue('phoneNumber_eq', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Documento</th>
                <th className="text-center">É master?</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((item) => (
                  <tr key={`show-button-${item.id}`}>
                    <td>{item?.id}</td>
                    <td>{item?.fullName}</td>
                    <td>{item?.email}</td>
                    <td>{item?.documentNumber ? documentNumberMask(item?.documentNumber) : '-'}</td>
                    <td className="text-center" width="150">
                      {
                        item?.isOwner
                          ? <small className="label label-rounded label-success">Sim</small>
                          : <small className="label label-rounded">Não</small>
                      }
                    </td>
                    <td className="text-right" width="100">
                      <Link
                        to={`/app/users/${item?.id}`}
                        className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                        data-tooltip="Visualizar"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default List
