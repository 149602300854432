import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import { ADOPTION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'

export default function AdoptionsCard({ adoptions }) {
  if (!adoptions) return null

  return (
    <Card.Base style={{ maxHeight: 500, overflowY: 'auto' }}>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fas fa-heart" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">
              Adoções <small>({adoptions.length} {adoptions.length === 1 ? 'adoção' : 'adoções'})</small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          adoptions?.length
            ? (
              <table className="table text-small">
                <thead>
                  <tr>
                    <th width="80px">#</th>
                    <th>Doador</th>
                    <th>Novo tutor</th>
                    <th>Data</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    adoptions.map((adoption) => (
                      <tr key={adoption.id}>
                        <td>{adoption.id}</td>
                        <td>
                          <Avatar avatarSize="sm" name={adoption?.donor?.fullName} photoURL={adoption?.donor?.photoURL} />
                          <span>&nbsp;{adoption.donor.fullName}</span>
                        </td>
                        <td>
                          <Avatar avatarSize="sm" name={adoption?.newTutor?.fullName} photoURL={adoption?.newTutor?.photoURL} />
                          <span>&nbsp;{adoption.newTutor.fullName}</span>
                        </td>
                        <td>{dateMask(adoption.createdAt, { minute: '2-digit', hour: '2-digit' })}</td>
                        <td className={ADOPTION_STATUS.class[adoption.status]}>
                          {ADOPTION_STATUS.t(adoption.status)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <ErrorBox
                error={{
                  icon: 'fas fa-heart fa-2x',
                  title: 'Nenhuma adoção',
                  message: 'Não há histórico de adoções',
                }}
              />
            )
        }
      </Card.Body>
    </Card.Base>
  )
}

AdoptionsCard.propTypes = {
  adoptions: PropTypes.arrayOf(Object),
}

AdoptionsCard.defaultProps = {
  adoptions: [],
}
