import Axios from 'axios'
import api from './api'

function generatePresignedUrl(extension) {
  return api.post('/uploads/presigned-url', { extension })
}
async function send(blob, presignedUrl, publicURL, extension) {
  await Axios.put(presignedUrl, blob, {
    headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': `${extension === 'pdf' ? 'application' : 'image'}/${extension}` },
  })
  return { data: { url: publicURL } }
}
export default {
  async sendFile(file) {
    const extension = file.type.split('/').pop()
    const { data } = await generatePresignedUrl(extension)

    return send(file, data.url, data.publicUrl, extension)
  },
}
