/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import Form from '@/components/Form'
import {
  FormAutocomplete, FormAutocompleteInput,
  Input, Menu, MenuItem,
} from './styles'

let cooldown = null
function Autocomplete({
  list, onSearch, timeout, onSelect,
  loading, inputAttrs, ItemComponent,
  ChipComponent, selected,
}) {
  const [search, setSearch] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [focus, setFocus] = useState(false)

  const filteredList = useMemo(() => (
    list?.filter((item) => item?.id !== selected?.id)
  ), [list, selected])

  useEffect(() => {
    if (selectedIndex > -1) {
      const element = document.getElementById(`option-${selectedIndex}`)
      element?.scrollIntoView()
    }
  }, [selectedIndex])

  const select = useCallback((item) => {
    onSelect(item)
    setSearch('')
    setSelectedIndex(-1)
  }, [onSelect])

  useEffect(() => {
    if (list.length === 1) select(list[0])
  }, [list, list?.length, select])

  const ArrowDown = useCallback(() => {
    if (selectedIndex === list.length - 1) return
    setSelectedIndex(selectedIndex + 1)
  }, [list?.length, selectedIndex])

  const ArrowUp = useCallback(() => {
    if (selectedIndex === -1) return
    setSelectedIndex(selectedIndex - 1)
  }, [selectedIndex])

  const Enter = useCallback(() => {
    const item = filteredList[selectedIndex]
    if (item) select(item)
  }, [filteredList, select, selectedIndex])

  const Backspace = useCallback(() => {
    if (!search) onSelect(null)
  }, [onSelect, search])

  const handleKeyDown = ({ key }) => {
    const functions = {
      ArrowDown, ArrowUp, Backspace, Enter,
    }
    const callback = functions[key]
    if (callback) callback()
  }

  useEffect(() => {
    clearTimeout(cooldown)
    if (search) {
      cooldown = setTimeout(() => {
        onSearch(search)
      }, timeout)
    }
  }, [onSearch, search, timeout])

  useEffect(() => () => clearTimeout(cooldown), [])

  return (
    <div className="p-relative">
      <FormAutocomplete>
        <FormAutocompleteInput>
          {ChipComponent && <ChipComponent />}
          {
            !selected && (
              <Form.HasIconLeft>
                <Form.Icon className={loading ? 'loading' : 'fas fa-search'} />
                <Input
                  {...inputAttrs}
                  onKeyDown={handleKeyDown}
                  onFocus={() => setFocus(true)}
                  onBlur={() => setTimeout(() => setFocus(false), 300)}
                  autoComplete="none"
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                />
              </Form.HasIconLeft>
            )
          }
        </FormAutocompleteInput>
      </FormAutocomplete>
      {
        filteredList.length > 0 && focus
          ? (
            <Menu className="p-absolute" style={{ width: '100%' }}>
              {
                filteredList.map((item, index) => (
                  <MenuItem key={String(item.id)}>
                    <a
                      id={`option-${index}`}
                      onClick={() => select(item)}
                      className={`c-hand ${selectedIndex === index ? 'active' : ''}`}
                      onKeyPress={() => null}
                      role="button"
                      tabIndex="0"
                    >
                      <ItemComponent item={item} />
                    </a>
                  </MenuItem>
                ))
              }
            </Menu>
          ) : null
      }
    </div>
  )
}

Autocomplete.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  timeout: PropTypes.number,
  loading: PropTypes.bool,
  inputAttrs: PropTypes.objectOf(PropTypes.any),
  ItemComponent: PropTypes.func.isRequired,
  ChipComponent: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.any,
}

Autocomplete.defaultProps = {
  list: [],
  onSearch: () => null,
  onSelect: () => null,
  timeout: 500,
  loading: false,
  inputAttrs: {},
  ChipComponent: null,
  selected: null,
}

export default Autocomplete
