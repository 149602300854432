import { LOCAL_STORAGE_AUTH_TOKEN } from './auth/constants'

export async function authorizationInterceptor(config) {
  const token = await localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN)
  if (!token) return config
  const { headers } = config
  return {
    ...config,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
}

export function errorHandlerInterceptor(errors) {
  return (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject({
        code: 'networkError',
        message: 'Falha na conexão',
      })
    }
    const e = {
      code: error.response?.data?.code,
      message: errors[error.response?.data?.code] || error.response?.data?.message,
    }

    return Promise.reject(e)
  }
}
