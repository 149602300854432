import React from 'react'
import PropTypes from 'prop-types'
import thumbor from '@/lib/thumbor'

function ImageThumb({
 src, width, height, smart, alt, className,
}) {
  const uri = thumbor.thumb(src, { width, height, smart })

  return <img src={uri} alt={alt} className={className} />
}

ImageThumb.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  smart: PropTypes.bool,
}

ImageThumb.defaultProps = {
  alt: '',
  width: null,
  height: null,
  className: '',
  smart: false,
}

export default ImageThumb
