import {
  Avatar, ErrorBox, Modal, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import { COMPLAINT_TYPE } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import animalFairs from '@/services/api/animalFairs'
import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// import { Container } from './styles';

function Complaints() {
  const { id } = useParams()
  const history = useHistory()
  const [removing, setRemoving] = useState(false)

  const query = useQuery()
  const {
    data, error, loading, status,
  } = useAxiosRequest(animalFairs.complaints, id, query)

  const close = useCallback(() => {
    history.goBack()
  }, [history])

  const onClear = useCallback(async () => {
    try {
      setRemoving(true)
      await animalFairs.clearComplaints(id)
      toast.success('Denúncias removidas')
      setRemoving(false)
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
      setRemoving(false)
    }
  }, [history, id])

  return (
    <Modal.Base className="active">
      <Modal.Overlay onClick={close} />
      <Modal.Container>
        <Modal.Header>
          <Modal.Close onClick={close} />
          <Modal.Title>Denúncias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Content>
            {loading && <Styles.Loading />}
            {status === API_STATUS.ERROR && (<div className="flex-centered"><ErrorBox error={error} /></div>)}
            {Boolean(data?.rows) && (
              <>
                <table className="table text-small">
                  <thead>
                    <tr>
                      <th>Denunciante</th>
                      <th>Detalhes</th>
                      <th width={140}>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.rows.map((complaint) => (
                        <tr key={complaint?.id}>
                          <td>
                            <Avatar
                              avatarSize="sm"
                              name={complaint?.author?.fullName}
                              photoURL={complaint?.author?.photoURL}
                            />
                            &nbsp;{complaint?.author?.fullName}
                            <div>
                              <small>
                                {complaint?.author?.email}
                              </small>
                            </div>
                            <div>
                              <small>
                                {complaint?.author?.phoneNumber || 'Sem telefone'}
                              </small>
                            </div>
                          </td>
                          <td>
                            <div className="text-small">{COMPLAINT_TYPE.t(complaint?.type)}</div>
                            <div className="text-small">{complaint?.description}</div>
                          </td>
                          <td>{dateMask(complaint?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div className="text-right">
                  <Paginator paginatedList={data} />
                </div>
              </>
            )}
          </Modal.Content>
        </Modal.Body>
        {
          data?.rows?.length && (
            <Modal.Footer>

              <div className="popover popover-top text-left">
                <Styles.Button disabled={removing} className={`btn-primary btn-sm ${removing ? 'loading' : ''}`}>
                  <i className="fas fa-broom" />
                  &nbsp;Limpar denúncias
                </Styles.Button>
                <div className="popover-container">
                  <div className="card">
                    <div className="card-header">
                      <div className="h6">Limpar lista de denúncias</div>
                    </div>
                    <div className="card-body">
                      Confirma a remoção de todas as denúncias desta publicação
                    </div>
                    <div className="card-footer text-right">
                      <Styles.Button disabled={removing} onClick={onClear} className={`btn-primary btn-sm ${removing ? 'loading' : ''}`}>
                        <i className="fas fa-broom" />
                        &nbsp;Confirmar
                      </Styles.Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          )
        }
      </Modal.Container>
    </Modal.Base>
  )
}

export default Complaints
