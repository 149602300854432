import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import { Link, useLocation } from 'react-router-dom'
import AdoptionsRow from './AdoptionsRow'

export default function AdoptionsCard({ lastAdoptions }) {
  const { pathname } = useLocation()

  if (!lastAdoptions) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fab fa-gratipay" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Adoções</div>
            <small>Últimas adoções feitas pelo usuário</small>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 500, overflowY: 'auto' }}>
        <AdoptionsRow adoptions={lastAdoptions} />
      </Card.Body>
      <Card.Footer className="text-right">
        <Link to={`${pathname}/adoptions`} className="btn btn-sm btn-primary">
          <i className="fas fa-search-plus" />
          &nbsp;Ver todas
        </Link>
      </Card.Footer>
    </Card.Base>
  )
}

AdoptionsCard.propTypes = {
  lastAdoptions: PropTypes.arrayOf(Object),
}

AdoptionsCard.defaultProps = {
  lastAdoptions: [],
}
