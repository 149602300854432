import { ErrorBox, PageHeader, Styles } from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import onlineFair from '@/services/api/onlineFair'
import React from 'react'
import { NavLink } from 'react-router-dom'
import Form from './Form'
import { Tab } from './styles'

function OnlineFair({ title }) {
  usePageTitle({ title })
  const {
    data, error, status, setData,
  } = useAxiosRequest(onlineFair.current)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Feirinha online"
        icon="fas fa-heart"
      />
      <Form fair={data || null} onChange={setData} />
      <div className="divider" />
      <Tab>
        <li className="tab-item">
          <NavLink to="/app/online-fair/animal-fairs">
            <i className="fas fa-paw fa-fw" />
            &nbsp;Animais cadastrados
          </NavLink>
        </li>
        <li className="tab-item">
          <NavLink to="/app/online-fair/adoptions">
            <i className="fas fa-heart fa-fw" />
            &nbsp;Adoções
          </NavLink>
        </li>
      </Tab>
    </Styles.Container>
  )
}

export default OnlineFair
