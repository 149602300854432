import React from 'react'
import PropTypes from 'prop-types'

import Flexbox from '../Flexbox'

function PageHeader({
  title, icon, rightAction, subtitle,
}) {
  return (
    <>
      <Flexbox.Columns className="align-center py-2 mb-2">
        {
          icon
            ? (
              <Flexbox.Column className="col-auto">
                <i className={`fa-fw fa-2x ${icon}`} />
              </Flexbox.Column>
            )
            : null
        }
        <Flexbox.Column>
          <div className="h5">{title}</div>
          {subtitle && <div className="text-small"><cite>{subtitle}</cite></div>}
        </Flexbox.Column>
        <Flexbox.Column className="col-auto">
          {
            rightAction ? rightAction() : null
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  subtitle: PropTypes.string,
  rightAction: PropTypes.func,
}

PageHeader.defaultProps = {
  icon: '',
  subtitle: '',
  rightAction: null,
}

export default PageHeader
