import {
  ErrorBox, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import categories from '@/services/api/categories'
import React, { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import usePageTitle from '@/hooks/usePageTitle'
import Form from '../components/Form'

function List({ title }) {
  usePageTitle({ title })

  const [deleting, setDeleting] = useState(null)
  const query = useQuery()
  const formRef = useRef()
  const {
    data, setData, error, status,
  } = useAxiosRequest(categories.list, query)

  const onCreate = useCallback((category) => {
    setData({ ...data, rows: [category, ...data?.rows] })
  }, [data, setData])

  const onUpdate = useCallback((category) => {
    const rows = data?.rows?.map((item) => (item?.id === category?.id ? category : item))
    setData({ ...data, rows })
  }, [data, setData])

  const onDelete = useCallback(async (category) => {
    try {
      setDeleting(category?.id)
      await categories.delete(category?.id)
      const rows = data?.rows?.filter((item) => item?.id !== category?.id)
      setData({ ...data, rows })
      toast(`${category?.description} excluído`, { type: 'success' })
    } catch (e) {
      toast(e.message, { type: 'error' })
    } finally {
      setDeleting(null)
    }
  }, [data, setData])

  const checkDelete = useCallback(async (category) => {
    confirmAlert({
      title: 'Excluir espécie',
      message: `Você está certo que deseja excluir ${category?.description}?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: () => onDelete(category),
        },
      ],
    })
  }, [onDelete])

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de espécies"
        icon="fas fa-dog"
        rightAction={() => (
          <Styles.Button onClick={() => formRef.current?.open()} className="btn-primary">
            <i className="fas fa-plus" />
            &nbsp;Adicionar
          </Styles.Button>
        )}
      />
      <table className="table text-small table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Descrição</th>
            <th>Criação</th>
            <th>Alteração</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((item) => (
              <tr key={`show-button-${item.id}`}>
                <td>{item?.id}</td>
                <td>{item?.description}</td>
                <td>{new Date(item?.createdAt).toLocaleString()}</td>
                <td>{new Date(item?.updatedAt).toLocaleString()}</td>
                <td className="text-right" width="130">
                    <Link
                      disabled={item?.id === deleting}
                      to={`/app/categories/${item?.id}/breeds`}
                      className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                      data-tooltip="Raças"
                    >
                      <i className="fas fa-paw" />
                    </Link>

                    <button
                      disabled={item?.id === deleting}
                      onClick={() => formRef.current?.open(item)}
                      type="button"
                      className="btn btn-primary btn-action btn-sm tooltip tooltip-left ml-1"
                      data-tooltip="Editar"
                    >
                      <i className="fas fa-pen" />
                    </button>
                    <button
                      disabled={item?.id === deleting}
                      type="button"
                      className="btn btn-error btn-action btn-sm tooltip tooltip-left ml-1"
                      data-tooltip="Excluir"
                      onClick={() => checkDelete(item)}
                    >
                      <i className="fas fa-trash" />
                    </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className="float-right my-2">
        <Paginator paginatedList={data} />
      </div>

      <Form ref={formRef} onCreate={onCreate} onUpdate={onUpdate} />

    </Styles.Container>
  )
}

export default List
