import React from 'react'
import Login from '@/views/auth/Login'
import GuestRoute from './components/GuestRoute'

function Auth() {
  return (
    <>
      <GuestRoute exact path="/login">
        <Login title="Petis - Gestor / Login" />
      </GuestRoute>
    </>
  )
}

export default Auth
