import styled from 'styled-components'

export const Tag = styled.div`
  background: #fae9d9;
  border-radius: 5rem;
  font-size: 90%;
  line-height: 0.8rem;
  margin: 0.1rem;
  padding: 0.2rem 0.4rem;
`
