import {
  Avatar, ErrorBox, Filter, Form,
  ListManager, Mask, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { ANIMAL_GENDER, ANIMAL_SIZE } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import animals from '@/services/api/animals'
import React from 'react'
import { Link } from 'react-router-dom'

export default function List({ title }) {
  usePageTitle({ title })

  const query = useQuery()

  const {
    loading, data, error, status,
  } = useAxiosRequest(animals.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista os pets"
        icon="fas fa-paw"
        rightAction={() => (
          <Filter
            initialValues={{
              name_cont: '',
              'tutor.fullName_cont': '',
              'tutor.documentNumber_eq': '',
              'tutor.email_eq': '',
              'tutor.phoneNumber_eq': '',
            }}
            title="Filtrar pets"
            renderForm={({ setFieldValue }) => (
              <>
                <div className="divider text-center" data-content="Pet" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="name_cont">Nome do pet</Form.Label>
                  <Form.ValidationField
                    name="name_cont"
                    className="input-sm"
                    autoFocus
                    id="name_cont"
                    autoComplete="none"
                    placeholder="Pet da Silva"
                  />
                </Form.Group>
                <div className="divider text-center" data-content="Tutor" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.fullName_cont">Nome do tutor</Form.Label>
                  <Form.ValidationField
                    name="['tutor.fullName_cont']"
                    className="input-sm"
                    autoFocus
                    id="tutor.fullName_cont"
                    autoComplete="none"
                    placeholder="Fulano da Silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.documentNumber_eq">Documento do tutor</Form.Label>
                  <Form.ValidationField name="['tutor.documentNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF ou CNPJ"
                          id="['tutor.documentNumber_eq']"
                          autoComplete="none"
                          onAccept={(value) => {
                            setFieldValue("['tutor.documentNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.email_eq">E-mail do tutor</Form.Label>
                  <Form.ValidationField
                    name="['tutor.email_eq']"
                    className="input-sm"
                    autoFocus
                    id="tutor.email_eq"
                    autoComplete="none"
                    type="email"
                    placeholder="tutor@email.com"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.phoneNumber_eq">Telefone do tutor</Form.Label>
                  <Form.ValidationField name="['tutor.phoneNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.Phone
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="(##) #####-####"
                          id="['tutor.phoneNumber_eq']"
                          autoComplete="none"
                          onAccept={(value) => {
                            setFieldValue("['tutor.phoneNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th width="80px">#</th>
                <th>Pet</th>
                <th>Tutor atual</th>
                <th width="150px">Adicionado em</th>
                <th className="text-right" width="1">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((pet) => (
                  <tr key={pet.id}>
                    <td>{pet.id}</td>
                    <td>
                      <div className="d-flex">
                        <Avatar avatarSize="sm" name={pet?.name} photoURL={pet?.photoURL} />
                        <span>&nbsp;{pet.name}</span>
                      </div>
                      <div>
                        <small>{pet?.category?.description} - {pet?.breed?.description}</small>
                      </div>
                      <div>
                        <small>
                          {ANIMAL_GENDER.t(pet.gender)} - {ANIMAL_SIZE.t(pet.size)}
                        </small>
                      </div>
                    </td>
                    <td>
                      <small>
                        <Avatar avatarSize="sm" name={pet?.tutor?.fullName} photoURL={pet?.tutor?.photoURL} />
                        <span>&nbsp;{pet.tutor.fullName}</span>
                      </small>
                    </td>
                    <td>
                      <small>
                        {dateMask(pet.createdAt, { hour: '2-digit', minute: '2-digit' })}
                      </small>
                    </td>
                    <td className="text-right" width="100">
                      <Link
                        to={`/app/animals/${pet?.id}`}
                        className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                        data-tooltip="Visualizar"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}
