import api from './api'

export default {
  all(categoryId = null) {
    return api.get('/vaccine-models/all', { params: { q: { categoryId } } })
  },

  list(params) {
    return api.get('/vaccine-models', { params })
  },

  update(id, body) {
    return api.put(`/vaccine-models/${id}`, body)
  },

  create(body) {
    return api.post('/vaccine-models', body)
  },

  show(id) {
    return api.get(`/vaccine-models/${id}`)
  },

  destroy(id) {
    return api.delete(`/vaccine-models/${id}`)
  },
}
