/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'

/**
 *
 * @param {import('react-datepicker').ReactDatePickerProps} props
 * @returns
 */
export default function DatePickerField(props) {
  const { setFieldValue } = useFormikContext() ?? {}
  const [field] = useField(props)

  return (
    <DatePicker
      {...props}
      {...field}
      locale="ptBR"
      autoComplete="off"
      onChange={(val) => {
        setFieldValue(field.name, val)
      }}
    />
  )
}
