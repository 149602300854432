import api from './api'

export default {
  all() {
    return api.get('/categories/all')
  },

  list(params = {}) {
    return api.get('/categories', { params })
  },

  create(body) {
    return api.post('/categories', body)
  },

  update(id, body) {
    return api.put(`/categories/${id}`, body)
  },

  delete(id) {
    return api.delete(`/categories/${id}`)
  },
}
