import styled from 'styled-components'
import logo from '@/assets/img/logo.png'

export const FOOTER_HEIGHT = 28

export const FooterContainer = styled.footer.attrs({
  className: 'd-flex',
})`
  align-items: flex-start;
  justify-content: flex-end;
  font-size: .6rem;
  padding: 5px;
`

export const Logo = styled.img.attrs({
  src: logo,
})`
  width: 15px;
  margin-right: .2rem;
`
