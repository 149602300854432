import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import DonationsRow from './DonationsRow'

export default function DonationsCard({ lastDonations }) {
  const { pathname } = useLocation()

  if (!lastDonations) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fas fa-hand-holding-heart" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Doações</div>
            <small>Últimas doações feitas pelo usuário</small>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 500, overflowY: 'auto' }}>
        <DonationsRow donations={lastDonations} />
      </Card.Body>
      <Card.Footer className="text-right">
        <Link to={`${pathname}/donations`} className="btn btn-sm btn-primary">
          <i className="fas fa-search-plus" />
          &nbsp;Ver todas
        </Link>
      </Card.Footer>
    </Card.Base>
  )
}

DonationsCard.propTypes = {
  lastDonations: PropTypes.arrayOf(Object),
}

DonationsCard.defaultProps = {
  lastDonations: [],
}
