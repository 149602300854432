/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import { singularStatusMapLabel, textClasses } from '@/constants/castration'
import { Link } from 'react-router-dom'
import { Cluster, Point } from './styles'

function Marker({ cluster, points, mapRef }) {
  const [openPopover, setOpenPopover] = useState(false)
  const [longitude, latitude] = cluster.geometry.coordinates
  const { cluster: isCluster, count } = cluster.properties

  const clusterSize = 10 + (count / points.length) / 10

  if (isCluster) {
    return (
      <Cluster
        style={{
          width: `${clusterSize}px`,
          height: `${clusterSize}px`,
          maxWidth: 100,
          maxHeight: 100,
        }}
        onClick={() => {
          try {
            mapRef.current.setZoom(mapRef.current?.getZoom() + 1)
            mapRef.current.panTo({ lat: latitude, lng: longitude })
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Cluster fail')
          }
        }}
      >
        {count}
      </Cluster>
    )
  }

  const { properties } = cluster

  return (
    <div
      className={`popover popover-top popover-only-click ${openPopover ? 'active' : ''}`}
    >
      <Point
        onClick={() => {
          setOpenPopover(true)
          mapRef.current.panTo({ lat: latitude + 0.00006, lng: longitude })
        }}
      >
        <i className={`fas fa-cut fa-2x ${textClasses[properties.status]}`} />
      </Point>
      <div className="popover-container">
        <div className="card">
          <div className="card-header">
            <Flexbox.Columns className="align-center">
              <Flexbox.Column>
                <div className="card-title h6">Detalhes da castração</div>
              </Flexbox.Column>
              <Flexbox.Column className="col-auto">
                <a className="btn btn-link" onClick={() => setOpenPopover(false)}>
                  <i className="fas fa-times" />
                </a>
              </Flexbox.Column>
            </Flexbox.Columns>
          </div>
          <div className="card-body">
            <Flexbox.Columns className="col-gapless">
              <Flexbox.Column className="col-auto mr-1">
                Pet:
              </Flexbox.Column>
              <Flexbox.Column>
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="xs"
                    name={properties?.animalName}
                    photoURL={properties?.animalAvatar}
                    alt="Imagem do animal"
                  />
                  <span> {properties.animalName}</span>
                </Flexbox.Column>
                <Flexbox.Column className="ml-1">
                  <div className="mt-1">{properties.animalBreed}</div>
                  <div className="mt-1">{properties.animalCategory}</div>
                </Flexbox.Column>
              </Flexbox.Column>
            </Flexbox.Columns>
            <div className="divider" />
            <Flexbox.Columns className="col-gapless">
              <Flexbox.Column className="col-auto mr-1">
                Tutor:
              </Flexbox.Column>
              <Flexbox.Column>
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="xs"
                    name={properties?.tutorName}
                    photoURL={properties?.tutorAvatar}
                    alt="Imagem de usuário"
                  />
                  <span> {properties.tutorName}</span>
                </Flexbox.Column>
                <Flexbox.Column className="ml-1">
                  <div className="mt-1">{documentNumberMask(properties.tutorDocument)}</div>
                </Flexbox.Column>
              </Flexbox.Column>
            </Flexbox.Columns>
            {
              Boolean(properties.clinicName) && (
                <>
                  <div className="divider" />
                  <Flexbox.Columns className="col-gapless">
                    <Flexbox.Column className="col-auto mr-1">
                      Clínica:
                    </Flexbox.Column>
                    <Flexbox.Column>
                      <Flexbox.Column className="col-auto">
                        <Avatar
                          avatarSize="xs"
                          name={properties?.clinicName}
                          photoURL={properties?.clinicAvatar}
                          alt="Imagem de usuário"
                        />
                        <span> {properties.clinicName}</span>
                      </Flexbox.Column>
                      <Flexbox.Column className="ml-1">
                        <div className="mt-1">{documentNumberMask(properties.clinicDocument)}</div>
                      </Flexbox.Column>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                </>
              )
            }
          </div>
          <div className="card-footer">
            <div className="divider" />
            <Flexbox.Columns className="align-center">
              <Flexbox.Column>
                <b className={textClasses[properties.status]}>
                  {singularStatusMapLabel[properties.status]}
                </b>
              </Flexbox.Column>
              <Flexbox.Column className="col-auto">
                <Link to={`/app/castrations/status/${properties.status}/${properties.id}`} className="btn btn-primary btn-sm">
                  <i className="fas fa-link" /> Abrir castração
                </Link>
              </Flexbox.Column>
            </Flexbox.Columns>
          </div>
        </div>
      </div>
    </div>

  )
}

Marker.propTypes = {
  cluster: PropTypes.objectOf(Object).isRequired,
  points: PropTypes.arrayOf(Object).isRequired,
  mapRef: PropTypes.objectOf(Object).isRequired,
}

export default Marker
