import React from 'react'
import { Avatar, ErrorBox } from '@/components'
import PropTypes from 'prop-types'
import { ADOPTION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'

export default function DonationsRow({ donations }) {
  if (!donations) return null

  return (
    <>
      {
        donations?.length
          ? (
            <table className="table text-small">
              <thead>
                <tr>
                  <th width="50px">#</th>
                  <th>Pet</th>
                  <th>Novo tutor</th>
                  <th>Data</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  donations.map((donation) => (
                    <tr key={donation?.id}>
                      <td>{donation?.id}</td>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={donation?.animal?.name}
                          photoURL={donation?.animal?.photoURL}
                        />
                        <span>&nbsp;{donation?.animal?.name}</span>
                      </td>
                      <td>
                        <Avatar avatarSize="sm" name={donation?.newTutor?.fullName} photoURL={donation?.newTutor?.photoURL} />
                        <span>&nbsp;{donation?.newTutor?.fullName}</span>
                      </td>
                      <td>
                        <small>{dateMask(donation?.createdAt, { minute: '2-digit', hour: '2-digit' })}</small>
                      </td>
                      <td className={ADOPTION_STATUS?.class[donation?.status]}>
                        {ADOPTION_STATUS.t(donation?.status)}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fas fa-hand-holding-heart fa-2x',
                title: 'Nenhuma doação',
                message: 'Não há histórico de doações',
              }}
            />
          )
      }
    </>
  )
}

DonationsRow.propTypes = {
  donations: PropTypes.arrayOf(Object),
}

DonationsRow.defaultProps = {
  donations: [],
}
