import { PageHeader, Styles } from '@/components'
import envs from '@/constants/envs'
import useAuth from '@/hooks/useAuth'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import convertBounds from '@/lib/convertBounds'
import reports from '@/services/api/reports'
import GoogleMapReact from 'google-map-react'
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import FilterAnimals from '../components/FilterAnimals'
import Marker from './Marker'
import { MapContainer } from './styles'

function AnimalsPointMap({ title }) {
  usePageTitle({ title })
  const [report, setReport] = useState([])
  const [mapOptions, setMapOptions] = useState({ zoom: 12 })
  const query = useQuery()
  const mapRef = useRef()
  const { user } = useAuth()

  const params = useMemo(() => {
    let obj = {}
    if (mapOptions) {
      const { zoom } = mapOptions
      const bounds = convertBounds(mapOptions?.bounds)
      obj = { ...obj, zoom, bounds }
    }
    return { ...query, ...obj }
  }, [mapOptions, query])

  const fetchReport = useCallback(async () => {
    const { data } = await reports.animalsPointMap(params)
    setReport(data)
  }, [params])

  const points = report.map((item) => ({
    type: 'Feature',
    id: item.id,
    properties: { cluster: item?.count > 1, ...item },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
    },
  }))

  useEffect(() => {
    if (user) fetchReport()
  }, [fetchReport, params, user])

  if (!user) return null

  const center = user?.location ? {
    lat: user?.location?.coordinates[1],
    lng: user?.location?.coordinates[0],
  } : {}

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-map-marker-alt"
        title="Pets no mapa"
        subtitle="Mostra os pets no lugar onde moram os tutores"
        rightAction={() => <FilterAnimals />}
      />
      <MapContainer className="bg-red">
        <GoogleMapReact
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
          }}
          center={query?.lat && query?.lng ? { lat: query.lat, lng: query.lng } : center}
          bootstrapURLKeys={{
            key: envs.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['visualization'],
          }}
          onChange={setMapOptions}
          defaultZoom={query?.zoom ? Number(query.zoom) : 12}
          yesIWantToUseGoogleMapApiInternals
        >
          {points.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates

            return (
              <Marker
                key={`cluster-${cluster.id || cluster.properties.uid}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                points={points}
                mapRef={mapRef}
              />
            )
          })}
        </GoogleMapReact>
      </MapContainer>
    </Styles.Container>
  )
}

export default AnimalsPointMap
