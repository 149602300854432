import { Avatar, Card, Flexbox } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import animalUid from '@/lib/masks/animalUid'
import dateMask from '@/lib/masks/date'
import { ANIMAL_SIZE } from '@/lib/enums'

export default function PetCard({ pet }) {
  if (!pet) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw fa-lg" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Pet</div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="lg"
              name={pet?.name}
              photoURL={pet?.photoURL}
              alt="Imagem do pet"
            />
          </Flexbox.Column>
          <Flexbox.Column className="text-ellipsis">
            <div className="h4 text-ellipsis">
              {pet.name}
            </div>
            <div className="text-ellipsis">
              <small>
                {pet.category.description} - {pet.breed.description}
              </small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>RGA</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {animalUid(pet.uid)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Adicionado em</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {dateMask(pet.createdAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Nascido em</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {dateMask(pet.bornedAt)}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          Boolean(pet.diedAt) && (
            <Flexbox.Columns>
              <Flexbox.Column>
                <b>Faleceu em</b>
              </Flexbox.Column>
              <Flexbox.Column className="col-auto">
                {dateMask(pet.diedAt)}
              </Flexbox.Column>
            </Flexbox.Columns>
          )
        }
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Cor</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {pet.color}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Tamanho</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {ANIMAL_SIZE.t(pet.size)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Castrado</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {pet.isCastrated ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>De rua</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {pet.ofStreet ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>
            <b>Chip</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            {pet.chip ? pet.chip.uid : '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
    </Card.Base>
  )
}

PetCard.propTypes = {
  pet: PropTypes.objectOf(Object),
}

PetCard.defaultProps = {
  pet: {},
}
