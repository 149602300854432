import React from 'react'
import { Avatar, ListManager, Paginator } from '@/components'
import { Link, useParams } from 'react-router-dom'
import dateMask from '@/lib/masks/date'
import { ANIMAL_GENDER, CASTRATION_STATUS } from '@/lib/enums'
import usePageTitle from '@/hooks/usePageTitle'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import castrations from '@/services/api/castrations'
import useQuery from '@/hooks/useQuery'
import mapTitles from '../mapTitles'

function ListStatus({ title }) {
  usePageTitle({ title })
  const params = useParams()
  const query = useQuery()
  const { status } = params
  const { data, loading, error } = useAxiosRequest(castrations.list, { status, ...query })
  const titleParams = mapTitles(params.status)
  return (
    <ListManager loading={loading} count={data?.count || 0} error={error}>
      <>
        <table className="table text-small table-hover">
          <thead>
            <tr>
              <th>Tutor</th>
              <th>Pet</th>
              <th>Clínica</th>
              <th>Solicitado em</th>
              <th width="100px" className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              Boolean(data) && data.rows.map((castration) => (
                <tr key={castration.id}>
                  <td>
                    <Avatar
                      avatarSize="sm"
                      className="mr-1"
                      name={castration?.tutor?.fullName}
                      photoURL={castration?.tutor?.photoURL}
                      alt="Imagem de usuário"
                    />
                    {castration?.tutor?.fullName}
                  </td>
                  <td>
                    <Avatar
                      avatarSize="sm"
                      className="mr-1"
                      name={castration?.animal?.name}
                      photoURL={castration?.animal?.photoURL}
                      smart={false}
                      alt="Imagem do animal"
                    />
                    {castration?.animal?.name}
                    <div>
                      <small>{castration?.animal?.category?.description}</small>
                      <small> - {ANIMAL_GENDER.t(castration?.animal?.gender)}</small>
                    </div>
                    <div>
                      <small>{castration?.animal?.breed?.description}</small>
                    </div>
                  </td>
                  <td>
                    {
                      castration?.clinic ? (
                        <>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={castration?.clinic?.fullName}
                            photoURL={castration?.clinic?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {castration?.clinic?.fullName || '-'}
                        </>
                      ) : '-'
                    }
                  </td>
                  <td>
                    <div>{dateMask(castration.createdAt, { hour: '2-digit', minute: '2-digit' })}</div>
                    <div>
                      <small>{castration?.city?.name}</small>
                      <small> - {castration?.city?.uf}</small>
                    </div>
                    <div>
                      <small className={CASTRATION_STATUS.class[castration.status]}>
                        {
                          titleParams.title === 'Todas as castrações'
                            ? CASTRATION_STATUS.t(castration.status)
                            : ''
                        }
                      </small>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-center" style={{ justifyContent: 'flex-end' }}>
                      {
                        castration.countComments > 0 && (
                          <div className="tooltip tooltip-left" data-tooltip="Comentários na castração">
                            <span className="badge mr-2" data-badge={castration.countComments}>
                              <i className="fas fa-comment" />
                            </span>
                          </div>
                        )
                      }
                      <Link
                        to={`/app/castrations/status/${params?.status}/${castration.id}`}
                        className="btn btn-action btn-sm btn-primary"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        {
          Boolean(data) && (
            <div className="float-right my-2">
              <Paginator paginatedList={data} />
            </div>
          )
        }
      </>
    </ListManager>

  )
}

export default ListStatus
