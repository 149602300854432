/* eslint-disable jsx-a11y/anchor-is-valid */
import useAuth from '@/hooks/useAuth'
import PropTypes from 'prop-types'
import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Avatar from '../../Avatar'
import { Container } from '../../styles'
import * as HeaderStyles from './styles'

function Header({ routes }) {
  const auth = useAuth()
  const history = useHistory()

  const childrens = routes
    .filter(({ children }) => Boolean(children))
    .map(({ children }) => children)
  const breadcrumbs = useBreadcrumbs(
    [...routes.filter(({ path }) => path), ...childrens],
    {
      excludePaths: [
        '/app/online-fair',
        '/app/online-fair/animal-fairs/*',
        '/app/castrations/status',
      ],
    },
)

  function handleSignOut() {
    confirmAlert({
      title: 'Sair do Petis',
      message: 'Você está certo que deseja fazer logout?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: () => auth.signOut(() => {
            history.replace('/')
          }),
        },
      ],
    })
  }

  return (
    <Container className="grid-xl">
      <HeaderStyles.HiddenTop />
      <HeaderStyles.Navbar>
        <HeaderStyles.NavbarSection>
          <HeaderStyles.Breadcrumb>
            {breadcrumbs.filter(({ match }) => match.url !== '/').map(({
              match,
              breadcrumb,
            }) => (
              <HeaderStyles.BreadcrumbItem key={match.url}>
                <HeaderStyles.NavLink to={match.url}>
                  {breadcrumb}
                </HeaderStyles.NavLink>
              </HeaderStyles.BreadcrumbItem>
            ))}
          </HeaderStyles.Breadcrumb>
        </HeaderStyles.NavbarSection>
        <HeaderStyles.NavbarSection className="flex-centered">
          {
            auth.user
              ? (
                <div className="dropdown dropdown-right">
                  <a href="#" className="btn btn-link dropdown-toggle flex-centered" tabIndex={0}>
                    <Avatar
                      name={auth?.user?.fullName}
                      photoURL={auth?.user?.photoURL}
                      alt="Imagem de usuário"
                    />
                    <span className="hide-sm">
                      &nbsp;{auth?.user?.fullName}
                    </span>
                    <i className="icon icon-caret" />
                  </a>
                  <ul className="menu">
                    <li className="menu-item">
                      <a onClick={handleSignOut} aria-hidden="true" className="text-error c-hand">
                        <i className="fas fa-sign-out-alt fa-fw" />
                        &nbsp;Sair
                      </a>
                    </li>
                  </ul>
                </div>
              )
              : null
          }
        </HeaderStyles.NavbarSection>
      </HeaderStyles.Navbar>
    </Container>
  )
}

Header.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default Header
