import {
  ErrorBox, Filter, Form, ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { LICENSE_TYPE, PARTNER_REQUEST_STATUS } from '@/lib/enums'
import partnerRequests from '@/services/api/partnerRequests'
import React from 'react'
import { Link } from 'react-router-dom'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, status, error,
  } = useAxiosRequest(partnerRequests.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de solicitações para parceria"
        icon="fas fa-edit"
        rightAction={() => (
          <Filter
            initialValues={{ requesterName_cont: '', status_eq: '' }}
            title="Filtrar solicitações"
            renderForm={() => (
              <>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="requesterName_contt">Nome do solicitante</Form.Label>
                  <Form.ValidationField
                    name="requesterName_cont"
                    className="input-sm"
                    autoFocus
                    id="requesterName_cont"
                    autoComplete="none"
                    placeholder="Fulano da silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="status_eq">Status</Form.Label>
                  <Form.ValidationField name="status_eq">
                    {
                      ({ field }) => (
                        <Form.Select
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          id="status_eq"
                          className="select-sm"
                          placeholder="Status da solicitação"
                        >
                          <option value="">Todos os status</option>
                          <option value="requested">Solicitada</option>
                          <option value="approved">Aprovada</option>
                          <option value="rejected">Rejeitada</option>
                          <option value="completed">Cadastro concluído</option>
                        </Form.Select>
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Cidade</th>
                <th>Tipo de licença</th>
                <th>Solicitante</th>
                <th>Data</th>
                <th>Status</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((item) => (
                  <tr key={`item-${item?.id}`}>
                    <td>{item?.id}</td>
                    <td>{`${item?.city?.name} - ${item?.city?.uf}`}</td>
                    <td>{LICENSE_TYPE.t(item?.licenseType)}</td>
                    <td>{item?.requesterName}</td>
                    <td>{new Date(item?.createdAt).toLocaleString()}</td>
                    <td className={`text-bold ${PARTNER_REQUEST_STATUS.class[item?.status]}`}>
                      {PARTNER_REQUEST_STATUS.t(item?.status)}
                    </td>
                    <td className="text-right">
                      <Link
                        to={`/app/partner-requests/${item?.id}`}
                        className="btn btn-action btn-sm btn-info tooltip tooltip-left"
                        data-tooltip="Visualizar"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default List
