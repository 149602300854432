import {
  ErrorBox, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import partnerLicensings from '@/services/api/partnerLicensings'
import React from 'react'
import { Link } from 'react-router-dom'
import Row from './Row'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    data, status, error, refreshData,
  } = useAxiosRequest(partnerLicensings.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de licenças"
        icon="fas fa-id-badge"
        rightAction={() => (
          <Link to="/app/partner-licensings/add" className="btn btn-primary">
            <i className="fas fa-plus" />
            &nbsp;Adicionar
          </Link>
        )}
      />
      <table className="table text-small table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Licenciado</th>
            <th width="150px">Cidade</th>
            <th width="200px">Licenças</th>
            <th>Licenciado em</th>
            <th>Status</th>
            <th width="1" className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((item) => (
              <Row
                item={item}
                key={item.id}
                onUpdate={refreshData}
              />
            ))
          }
        </tbody>
      </table>

      <div className="float-right my-2">
        <Paginator paginatedList={data} />
      </div>
    </Styles.Container>
  )
}

export default List
