import {
  ErrorBox, Filter, Flexbox, Form, ListManager, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import onlineFair from '@/services/api/onlineFair'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Row from './Row'

function AnimalFairs({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const location = useLocation()
  const {
    loading, data, error, status, refreshData,
  } = useAxiosRequest(onlineFair.animalFairs, query)

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="my-2 py-2 loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="my-2 py-2 flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <Flexbox.Columns className="align-center" style={{ marginTop: 10, marginBottom: 5 }}>
        <Flexbox.Column>
          <div className="h5">Pets na feira</div>
        </Flexbox.Column>
        <Flexbox.Column className="col-auto">
          <Filter
            initialValues={{
              'animal.name_cont': '',
              'person.fullName_cont': '',
            }}
            title="Filtrar pets"
            renderForm={() => (
              <>
                <div className="divider text-center" data-content="Pet" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="animal.name_cont">Nome do pet</Form.Label>
                  <Form.ValidationField
                    name="['animal.name_cont']"
                    className="input-sm"
                    autoFocus
                    id="animal.name_cont"
                    autoComplete="none"
                    placeholder="Pet da silva"
                  />
                </Form.Group>
                <div className="divider text-center" data-content="Tutor" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="person.fullName_cont">Nome do tutor</Form.Label>
                  <Form.ValidationField
                    name="['person.fullName_cont']"
                    className="input-sm"
                    id="person.fullName_cont"
                    autoComplete="none"
                    placeholder="Tutor da silva"
                  />
                </Form.Group>
              </>
            )}
          />
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small">
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Pet</th>
                <th>Tutor</th>
                <th width={200}>Adicionado em</th>
                <th className="text-center" width={80}>Adotado?</th>
                <th className="text-center" width={80}>Denúncias</th>
                <th className="text-right" width={50}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((animalFair) => <Row animalFair={animalFair} key={animalFair?.id} />)
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                {Boolean(data) && <Paginator paginatedList={data} />}
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default AnimalFairs
