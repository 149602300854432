import envs from '@/constants/envs'
import axios from 'axios'
import { authorizationInterceptor, errorHandlerInterceptor } from '../interceptors'
import errors from './errors'

const api = axios.create({
  baseURL: envs.REACT_APP_BASE_AUTH_URL,
})

api.interceptors.request.use(authorizationInterceptor)
api.interceptors.response.use((response) => response, errorHandlerInterceptor(errors))

export default api
