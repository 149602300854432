const mappings = {
  requested: {
    label: 'Solicitadas',
    icon: 'far fa-fw fa-question-circle',
    sequence: 0,
  },
  correction: {
    label: 'Em correção',
    icon: 'fas fa-fw fa-edit',
    sequence: 10,
  },
  corrected: {
    label: 'Corrigidas',
    icon: 'fas fa-fw fa-edit',
    sequence: 12,
  },
  revision: {
    label: 'Em revisão',
    icon: 'fas fa-fw fa-glasses',
    sequence: 20,
  },
  preApproved: {
    label: 'Pré aprovadas',
    icon: 'fas fa-fw fa-check-circle',
    sequence: 29,
  },
  approved: {
    label: 'Aprovadas',
    icon: 'fas fa-fw fa-thumbs-up',
    sequence: 30,
  },
  scheduled: {
    label: 'Agendadas',
    icon: 'fas fa-fw fa-calendar',
    sequence: 35,
  },
  inTreatment: {
    label: 'Em tratamento',
    icon: 'fas fa-fw fa-briefcase-medical',
    sequence: 36,
  },
  attendance: {
    label: 'Em atendimento',
    icon: 'fas fa-fw fa-clinic-medical',
    sequence: 40,
  },
  awaitingConfirmation: {
    label: 'Aguardando confirmar',
    icon: 'fas fa-fw fa-user-clock',
    sequence: 50,
  },
  completed: {
    label: 'Concluídas',
    icon: 'fas fa-fw fa-check',
    sequence: 60,
  },
  rejected: {
    label: 'Rejeitadas',
    icon: 'fas fa-fw fa-thumbs-down',
    sequence: 90,
  },
  canceled: {
    label: 'Canceladas',
    icon: 'fas fa-fw fa-ban',
    sequence: 99,
  },
  all: {
    label: 'Total de castrações',
    icon: 'fas fa-fw fa-cut',
    sequence: 999,
  },
}

export default (status) => mappings[status] || { label: status }
