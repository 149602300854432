import React from 'react'
import { FooterContainer, Logo } from './styles'
import { version } from '../../../package.json'

function Footer() {
  return (
    <FooterContainer className="text-ellipsis container grid-lg">
      <Logo />
      <div className="text-primary pr-1">Petis</div>
      <span className="text-ellipsis">
        - Desenvolvido por
        <a href="https://paliari.com.br" target="_blank" rel="noreferrer"> Paliari Engenharia de Software </a>
        ©  {new Date().getFullYear()}
        <code> <small>v{version}</small></code>
      </span>
    </FooterContainer>
  )
}

export default Footer
