import {
  Avatar,
  Card, ErrorBox, Flexbox, PageHeader, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import { LICENSE_TYPE, PARTNER_REQUEST_STATUS } from '@/lib/enums'
import partnerRequests from '@/services/api/partnerRequests'
import React, { useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CommentPicker from '../components/CommentPicker'

function Show({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const commentPickerRef = useRef(null)
  const history = useHistory()
  const { id } = useParams()
  const {
    data, status, error,
  } = useAxiosRequest(partnerRequests.show, id)

  async function onApprove(description) {
    try {
      await partnerRequests.approve(id, { description })
      toast.success('Solicitação aprovada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  async function onReject(description) {
    try {
      await partnerRequests.reject(id, { description })
      toast.success('Solicitação rejeitada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Solicitação para parceria"
        icon="fas fa-edit"
      />

      <Flexbox.Columns>
        <Flexbox.Column className="col-7 col-md-10 col-sm-12">
          <Card.Base>
            <Card.Header>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column>
                  <div className="h4 card-title">Detalhes da solicitação</div>
                </Flexbox.Column>
                <Flexbox.Column className={`col-auto text-bold ${PARTNER_REQUEST_STATUS.class[data?.status]}`}>
                  {PARTNER_REQUEST_STATUS.t(data?.status)}
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Header>
            <Card.Body>
              <div className="divider text-center" data-content="Dados da prefeitura" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Cidade:</b>
                    &nbsp;{data?.city?.name} - {data?.city?.uf}
                  </div>
                  <div className="text-ellipsis mb-2">
                    <b>CNPJ:</b>
                    &nbsp;{data?.partnerCNPJ}
                  </div>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Fone:</b>
                    &nbsp;{data?.partnerPhoneNumber}
                  </div>
                  <div className="text-ellipsis mb-2">
                    <b>E-mail:</b>
                    &nbsp;{data?.partnerEmail}
                  </div>
                </Flexbox.Column>
              </Flexbox.Columns>

              <div className="divider text-center" data-content="Dados do solicitante" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Nome:</b>
                    &nbsp;{data?.requesterName}
                  </div>
                  <div className="text-ellipsis mb-2">
                    <b>CPF:</b>
                    &nbsp;{data?.requesterCPF}
                  </div>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Fone:</b>
                    &nbsp;{data?.requesterPhoneNumber}
                  </div>
                  <div className="text-ellipsis mb-2">
                    <b>E-mail:</b>
                    &nbsp;{data?.requesterEmail}
                  </div>
                </Flexbox.Column>
                <Flexbox.Column className="col-12">
                  <div className="text-ellipsis mb-2">
                    <b>Cargo:</b>
                    &nbsp;{data?.requesterOccupation}
                  </div>
                </Flexbox.Column>
              </Flexbox.Columns>

              <div className="divider text-center" data-content="Detalhes da solicitação" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Tipo de licença:</b>
                    &nbsp;{LICENSE_TYPE.t(data?.licenseType)}
                  </div>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-md-12 text-ellipsis">
                  <div className="text-ellipsis mb-2">
                    <b>Solicitado em:</b>
                    &nbsp;{new Date(data?.createdAt).toLocaleString()}
                  </div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Body>
            {
              data?.status === PARTNER_REQUEST_STATUS.enum.REQUESTED && (
                <Card.Footer className="text-right">
                  <Styles.Button className="btn-error mr-2" disabled={loading} onClick={() => commentPickerRef.current?.open('reject')}>
                    <i className="fas fa-times" />
                      &nbsp;Rejeitar
                  </Styles.Button>
                  <Styles.Button className="btn-primary" disabled={loading} onClick={() => commentPickerRef.current?.open('approve')}>
                    <i className="fas fa-check" />
                      &nbsp;Aprovar
                  </Styles.Button>
                </Card.Footer>
              )
            }
          </Card.Base>
        </Flexbox.Column>
        {
          data?.comments?.length > 0
          && (
            <Flexbox.Column>
              <Card.Base>
                <Card.Header>
                  <div className="h4 card-title">Comentários</div>
                </Card.Header>
                <Card.Body>
                  {
                    data.comments.map((comment) => (
                      <div key={`comment-${comment?.id}`} className="tile mb-2">
                        <div className="tile-icon">
                          <Avatar
                            name={comment?.author?.fullName}
                            photoURL={comment?.author?.photoURL}
                            alt="Imagem de usuário"
                          />
                        </div>
                        <div className="tile-content">
                          <Flexbox.Columns className="align-center text-ellipsis">
                            <Flexbox.Column className="text-ellipsis">
                              <div className="tile-title text-bold">{comment?.author?.fullName}</div>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-auto">
                              <cite className="text-gray">
                                {new Date(comment?.createdAt).toLocaleString()}
                              </cite>
                            </Flexbox.Column>

                          </Flexbox.Columns>
                          <div className="tile-subtitle">{comment?.description}</div>
                        </div>
                      </div>
                    ))
                  }
                </Card.Body>
              </Card.Base>
            </Flexbox.Column>
          )
        }
      </Flexbox.Columns>
      <CommentPicker ref={commentPickerRef} onApprove={onApprove} onReject={onReject} />
    </Styles.Container>
  )
}

export default Show
