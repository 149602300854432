import {
  ErrorBox, Flexbox, PageHeader, Styles,
  Card,
  Avatar,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import { RESET_EMAIL_STATUS } from '@/lib/enums'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumberMask from '@/lib/masks/phoneNumber'
import resetEmails from '@/services/api/resetEmails'
import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Image } from './styles'

function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const {
    data, status, error,
  } = useAxiosRequest(resetEmails.show, id)

  const { person, resetEmail } = data || { person: null, resetEmail: null }

  async function approve() {
    try {
      setLoading(true)
      await resetEmails.approve(id)
      toast('Email alterado com sucesso', { type: 'success' })
      setLoading(false)
      history.replace('/app/reset-emails')
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  function handleApprove() {
    confirmAlert({
      title: 'Aprovar solicitação',
      message: 'Você está certo que deseja aprovar esta solicitação?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-success',
          onClick: approve,
        },
      ],
    })
  }

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Solicitação para alteração de e-mail"
        icon="fas fa-envelope"
      />
      <Flexbox.Columns>
        <Flexbox.Column className="col-7 col-md-10 col-sm-12">
          <Card.Base>
            <Card.Header>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column>
                  <div className="h4 card-title">Detalhes da solicitação</div>
                  {
                    resetEmail?.status === RESET_EMAIL_STATUS.enum.REJECTED && (
                      <small>
                        Motivo da rejeição: <span className="text-error">{resetEmail?.rejectionReason}</span>
                      </small>
                    )
                  }
                </Flexbox.Column>
                <Flexbox.Column
                  className={`col-auto text-bold ${RESET_EMAIL_STATUS.class[resetEmail?.status]}`}
                >
                  {RESET_EMAIL_STATUS.t(resetEmail?.status)}
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Header>
            <Card.Body>
              <div className="divider" />
              <div className="text-ellipsis mb-2">
                <b>Nome:</b>
                &nbsp;{resetEmail?.fullName}
              </div>
              <div className="text-ellipsis mb-2">
                <b>Documento:</b>
                &nbsp;{documentNumberMask(resetEmail?.documentNumber)}
              </div>
              <div className="text-ellipsis mb-2">
                <b>E-mail:</b>
                &nbsp;{resetEmail?.email}
              </div>
              <div className="text-ellipsis mb-2">
                <b>Fone:</b>
                &nbsp;{phoneNumberMask(resetEmail?.phoneNumber)}
              </div>
              <a href={resetEmail?.selfieURL} style={{ boxShadow: 'none' }} rel="noreferrer" target="_blank">
                <Image src={resetEmail?.selfieURL} alt="" />
              </a>
              <div className="divider text-center" data-content="Dados da conta" />
              {
                person ? (
                  <Link to={`/app/users/${person?.id}`} className="text-dark">
                    <Flexbox.Columns>
                      <Flexbox.Column className="col-auto">
                        <Avatar avatarSize="lg" photoURL={person?.photoURL} name={person?.fullName} />
                      </Flexbox.Column>
                      <Flexbox.Column>
                        <b>{person?.fullName}</b>
                        <div>{person?.email}</div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Link>
                ) : (
                  <cite>Não foi possível encontrar nenhuma conta vinculada à este documento</cite>
                )
              }
            </Card.Body>
            {
              resetEmail?.status === RESET_EMAIL_STATUS.enum.REQUESTED && (
                <Card.Footer className="text-right">
                  <Link to={`${id}/reject`} className="btn btn-error mr-2">
                    <i className="fas fa-ban" />
                    &nbsp;Rejeitar
                  </Link>
                  <button
                    disabled={loading}
                    type="button"
                    className={`btn btn-success ${loading && 'loading'}`}
                    onClick={handleApprove}
                  >
                    <i className="fas fa-check" />
                    &nbsp;Aprovar
                  </button>
                </Card.Footer>
              )
            }
          </Card.Base>
        </Flexbox.Column>
      </Flexbox.Columns>
    </Styles.Container>
  )
}

export default Show
