import React from 'react'
import PropTypes from 'prop-types'
import mapTitles from '../mapTitles'

function Breadcrumb({ match }) {
  const titleParams = mapTitles(match.params.status)
  return <span>{titleParams.title}</span>
}

Breadcrumb.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
}

export default Breadcrumb
