import { useContext } from 'react'
import userContext from '@/contexts/userContext'

/**
 * @typedef {Object} UserReducer
 * @property {Object} state - State of user
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {UserReducer}
 */
 export default function useUser() {
  return useContext(userContext)
}
