import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { confirmAlert } from 'react-confirm-alert'
import vaccineModels from '@/services/api/vaccineModels'
import { toast } from 'react-toastify'
import { Flexbox } from '@/components'
import { VACCINE_TYPE } from '@/lib/enums'

function Row({ vaccineModel, onPresUpdate, onDestroy }) {
  const [loading, setLoading] = useState(false)

  async function destroy() {
    try {
      setLoading(true)
      await vaccineModels.destroy(vaccineModel.id)
      toast.success('Modelo de vacina excluído')
      onDestroy(vaccineModel)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  function handleDestroy() {
    confirmAlert({
      title: 'Excluir vacina',
      message: `Você está certo que deseja excluir '${vaccineModel.description}'?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, excluir',
          className: 'bg-error',
          onClick: destroy,
        },
      ],
    })
  }

  return (
    <tr key={vaccineModel.id}>
      <td>#{vaccineModel.id}</td>
      <td>
        <Flexbox.Columns className="align-center">
          {
            Boolean(vaccineModel.cover) && (
              <Flexbox.Column className="col-auto">
                <img src={vaccineModel.cover} style={{ width: 50 }} alt="Imagem da vacina" className="s-rounded image-responsive" />
              </Flexbox.Column>
            )
          }
          <Flexbox.Column>
            <div>
              {vaccineModel.description}
              <span className="text-gray">&nbsp;- {VACCINE_TYPE.t(vaccineModel.type)}</span>
            </div>
            {
              Boolean(vaccineModel.nextVaccineModel) && (
                <small className="text-gray">
                  <i className="fas fa-level-up-alt fa-rotate-90" />
                  &nbsp;{vaccineModel.nextVaccineModel.description}
                  &nbsp;{vaccineModel.daysUntilNextDose > 0 ? `(após ${vaccineModel.daysUntilNextDose} dias)` : '(dias indeterminados)'}
                </small>
              )
            }
          </Flexbox.Column>
        </Flexbox.Columns>
      </td>
      <td>{vaccineModel.periodic ? 'Sim' : 'Não'}</td>
      <td>{vaccineModel.category.description}</td>
      <td>{vaccineModel.order}</td>
      <td className={vaccineModel.active ? 'text-success' : 'text-error'}>{vaccineModel.active ? 'Sim' : 'Não'}</td>
      <td className="text-right">
        <button
          onClick={handleDestroy}
          type="button"
          disabled={loading}
          className={`btn btn-error btn-action btn-sm tooltip tooltip-left ${loading && 'loading'}`}
          data-tooltip="Excluir"
        >
          <i className="fas fa-trash" />
        </button>
        <button
          onClick={() => onPresUpdate(vaccineModel)}
          disabled={loading}
          type="button"
          className="btn btn-primary btn-action btn-sm tooltip tooltip-left ml-1"
          data-tooltip="Editar"
        >
          <i className="fas fa-pen" />
        </button>
      </td>
    </tr>
  )
}

Row.propTypes = {
  vaccineModel: PropTypes.objectOf(Object).isRequired,
  onPresUpdate: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
}

export default Row
