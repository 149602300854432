import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import animalUid from '@/lib/masks/animalUid'
import dateMask from '@/lib/masks/date'

function Animal({ animal, address }) {
  return (
    <div className="panel">
      <div className="panel-header">
        <div className="panel-title">
          <div className="h5">Animal</div>
        </div>
      </div>
      <div className="panel-body">
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar avatarSize="lg" name={animal?.name} photoURL={animal?.photoURL} />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">{animal?.name}</div>
            <div className="text-small">
              {animal?.category?.description} - {animal?.breed?.description}
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column className="my-2 col-8 col-sm-12">
            <small>RGA</small>
            <div>
              <b>{animalUid(animal?.uid)}</b>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="my-2 col-4 col-sm-12">
            <small>Nascimento</small>
            <div>
              <b>{dateMask(animal?.bornedAt)}</b>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="my-2 col-12">
            <small>Endereço</small>
            <div>
              <b>{address ? address?.formattedAddress : 'Não informado'}</b>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </div>
    </div>
  )
}

Animal.propTypes = {
  animal: PropTypes.objectOf(PropTypes.any).isRequired,
  address: PropTypes.objectOf(PropTypes.any),
}

Animal.defaultProps = {
  address: null,
}

export default Animal
