import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import CastrationsRow from './CastrationsRow'

export default function CastrationsCard({ lastCastrations }) {
  const { pathname } = useLocation()

  if (!lastCastrations) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fa-lg fas fa-cut" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Castrações</div>
            <small>Últimas castrações solicitadas pelo usuário</small>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 500, overflowY: 'auto' }}>
        <CastrationsRow castrations={lastCastrations} />
      </Card.Body>
      <Card.Footer className="text-right">
        <Link to={`${pathname}/castrations`} className="btn btn-sm btn-primary">
          <i className="fas fa-search-plus" />
          &nbsp;Ver todas
        </Link>
      </Card.Footer>
    </Card.Base>
  )
}

CastrationsCard.propTypes = {
  lastCastrations: PropTypes.arrayOf(Object),
}

CastrationsCard.defaultProps = {
  lastCastrations: [],
}
