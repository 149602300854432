import styled from 'styled-components'
import logo from '@/assets/img/logo.png'
import { NavLink as RouterNavLink } from 'react-router-dom'

export const OffCanvas = styled.div.attrs({
  className: 'off-canvas off-canvas-sidebar-show',
})`
  min-height: 100vh;
`

export const Toggle = styled.a.attrs({
  className: 'off-canvas-toggle btn btn-primary btn-action',
  href: '#sidebar',
})``

export const ToggleIcon = styled.i.attrs({
  className: 'fas fa-bars',
})``

export const Sidebar = styled.div.attrs({
  id: 'sidebar',
  className: 'off-canvas-sidebar',
})`
  position: sticky !important;
  top: 0;
  height: 100vh;

  @media (max-width: 960px) {
    position: fixed !important;
  }
`

export const Overlay = styled.a.attrs({
  className: 'off-canvas-overlay',
  href: '#close',
})``

export const Content = styled.div.attrs({
  className: 'off-canvas-content',
})``

export const AccordionContainer = styled.div.attrs({
  className: 'accordion-container',
})``

export const Nav = styled.div`
  bottom: 1.5rem;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: .5rem 1.5rem;
  position: fixed;
  top: 3.5rem;
`

export const Brand = styled.div`
  height: 2rem;
  left: 1.5rem;
  position: fixed;
  top: .85rem;
  display: flex;
  align-items: center;
`

export const BrandLogo = styled.img.attrs({
  src: logo,
})`
  width: 1.8rem;
`

export const NavLinkContainer = styled.div.attrs({
  className: 'mb-2',
})``

export const NavLink = styled(RouterNavLink)`
  text-decoration: none !important;
  color: inherit !important;
  
  :focus {
    box-shadow: none;
  }

  &.active {
    color: #e49042 !important;
  }
`

export const Accordion = styled.div.attrs({
  className: 'accordion mb-2',
})``

export const AccordionCheckbox = styled.input.attrs({
  type: 'checkbox',
  hidden: true,
})``

export const AccordionHeader = styled.label.attrs({
  className: 'accordion-header c-hand',
})`
  padding: 0 0 !important;
`

export const AccordionBody = styled.div.attrs({
  className: 'accordion-body',
})``

export const Menu = styled.div.attrs({
  className: 'menu menu-nav',
})`
  padding: 0 0.4rem;

  a {
    font-size: .78rem;
  }

  .menu-item a {
    padding: 0 0.4rem;
  }
  
  .menu-item a:hover, .menu-item a.active {
    background-color: transparent;
  }
`
