import {
  Flexbox, Form, Modal, Styles,
} from '@/components'
import { ANIMAL_SIZE } from '@/lib/enums'
import yup from '@/lib/yupPt'
import breeds from '@/services/api/breeds'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import React, {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().required(),
  order: yup.number().required(),
  size: yup.string().required(),
})
const breedForm = { description: '', order: 0, size: ANIMAL_SIZE.enum.MEDIUM }

const FormBreed = forwardRef(({ categoryId, onUpdate, onCreate }, ref) => {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [breed, setBreed] = useState(breedForm)

  function open(preload = breedForm) {
    setBreed(preload)
    setOpened(true)
  }

  useImperativeHandle(ref, () => ({ open }))

  function close() {
    setBreed(breedForm)
    setOpened(false)
  }

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      if (breed?.id) {
        const { data } = await breeds.update(categoryId, breed?.id, form)
        onUpdate(data)
        toast(`${form?.description} atualizado`, { type: 'success' })
      } else {
        const { data } = await breeds.create(categoryId, form)
        onCreate(data)
        toast(`${form?.description} adicionado`, { type: 'success' })
      }
      close()
    } catch (e) {
      toast(e?.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }, [breed?.id, categoryId, onCreate, onUpdate])

  if (!opened) return null

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={breed}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {
          ({
            errors, touched, isValid, dirty,
          }) => (
            <Form.ValidationForm>
              <Modal.Base className="active">
                <Modal.Overlay onClick={close} />
                <Modal.Container>
                  <Modal.Header>
                    <Modal.Close onClick={close} />
                    <Modal.Title>Raça</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Modal.Content>
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              autoFocus
                              id="description"
                              autoComplete="off"
                              placeholder="Sem Raça Definida, Shih Tzu, Yorkshire, Siamês..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint>{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-9 col-md-8 col-sm-12">
                          <Form.Group className={`${touched?.order && errors?.order ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="size">Tamanho <span className="text-error">*</span></Form.Label>
                            <Field
                              className="form-select"
                              as="select"
                              name="size"
                              id="size"
                            >
                              {
                                Object.values(ANIMAL_SIZE.enum).map((value) => (
                                  <option key={value} value={value}>{ANIMAL_SIZE.t(value)}</option>
                                ))
                              }
                            </Field>
                            {
                              touched?.order && errors?.order
                                ? <Form.Hint>{errors.order}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-3 col-md-4 col-sm-12">
                          <Form.Group className={`${touched?.order && errors?.order ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="order">Ordem <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="order"
                              id="order"
                              autoComplete="off"
                              type="number"
                              placeholder="1, 2, 3"
                            />
                            {
                              touched?.order && errors?.order
                                ? <Form.Hint>{errors.order}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Modal.Content>
                  </Modal.Body>
                  <Modal.Footer>
                    <Styles.Button
                      type="submit"
                      className="btn-primary"
                      disabled={!dirty || !isValid}
                    >
                      <i className="fas fa-save" />
                      &nbsp;Salvar
                    </Styles.Button>
                  </Modal.Footer>
                </Modal.Container>
              </Modal.Base>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
})

FormBreed.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default FormBreed
