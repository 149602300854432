import React from 'react'
import PropTypes from 'prop-types'
import ErrorBox from '../ErrorBox'

function LoadManager({ children, error, loading }) {
  if (loading) {
    return (
      <div className="empty">
        <div className="empty-icon">
          <div className="loading loading-lg" />
        </div>
        <p className="h5 empty-title">Carregando dados</p>
        <p className="empty-subtitle">Aguarde enquanto buscamos as informações</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex-centered pt-2">
        <ErrorBox error={error} />
      </div>
    )
  }

  return children || null
}

LoadManager.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  error: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
}

LoadManager.defaultProps = {
  children: <></>,
  error: null,
  loading: false,
}

export default LoadManager
