import api from './api'

export default {
  list(params) {
    return api.get('/news', { params })
  },
  create(body) {
    return api.post('/news', body)
  },
  show(id) {
    return api.get(`/news/${id}`)
  },
  update(id, body) {
    return api.put(`/news/${id}`, body)
  },
}
