/* eslint-disable jsx-a11y/label-has-associated-control */
import { LICENSE_TYPE, PARTNER_LICENSING_STATUS } from '@/lib/enums'
import partnerLicensings from '@/services/api/partnerLicensings'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { Form } from '@/components'
import { Field, useFormikContext } from 'formik'

const AutoSubmit = ({ item, setUpdating, onUpdate }) => {
  const { values, submitForm } = useFormikContext()

  async function updatePermissions() {
    try {
      setUpdating(true)
      await partnerLicensings.update(item.id, values)
      toast.success('Permissões atualizadas')
      onUpdate()
    } catch (e) {
      toast.error(e.message)
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    if ((item.licenseTypes || []).length !== values.licenseTypes.length) updatePermissions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, submitForm, item])

  return null
}

export default function Row({ item, onUpdate }) {
  const [updating, setUpdating] = useState(false)

  async function update(id, body) {
    try {
      setUpdating(true)
      const { data } = await partnerLicensings.update(id, body)
      toast.success('Alterado com sucesso')
      onUpdate(data)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }

  return (
    <tr key={`item-${item?.id}`}>
      <td>{item?.id}</td>
      <td>{item?.licensed?.fullName}</td>
      <td>{`${item?.city?.name} - ${item?.city?.uf}`}</td>
      <td>
        <fieldset disabled={updating}>
            <Form.Formik
              initialValues={{
                licenseTypes: item.licenseTypes || [],
              }}
            >
              {
                () => (
                  <Form.ValidationForm>
                    {
                      Object.values(LICENSE_TYPE.enum).map((i) => (
                        <div key={i} className="form-group">
                          <label className="form-switch">
                            <Field type="checkbox" name="licenseTypes" value={i} />
                            <i className="form-icon" />
                            {LICENSE_TYPE.t(i)}
                          </label>
                        </div>
                      ))
                    }
                    <AutoSubmit item={item} setUpdating={setUpdating} onUpdate={onUpdate} />
                  </Form.ValidationForm>
                )
              }
            </Form.Formik>
        </fieldset>
      </td>
      <td>{new Date(item?.createdAt).toLocaleString()}</td>
      <td className={PARTNER_LICENSING_STATUS.class[item?.status]}>
        {PARTNER_LICENSING_STATUS.t(item?.status)}
      </td>
      <td className="text-right">
        {
          item?.status === PARTNER_LICENSING_STATUS.enum.ACTIVE && (
            <button
              onClick={() => {
                update(item?.id, { status: PARTNER_LICENSING_STATUS.enum.CANCELED })
              }}
              disabled={updating}
              className="btn btn-sm btn-action btn-error tooltip tooltip-left"
              data-tooltip="Cancelar"
              type="button"
            >
              <i className="fas fa-ban" />
            </button>
          )
        }
        {
          item?.status === PARTNER_LICENSING_STATUS.enum.CANCELED && (
            <button
              onClick={() => {
                update(item?.id, { status: PARTNER_LICENSING_STATUS.enum.ACTIVE })
              }}
              disabled={updating}
              className="btn btn-sm btn-action btn-success tooltip tooltip-left"
              data-tooltip="Reativar"
              type="button"
            >
              <i className="fas fa-check" />
            </button>
          )
        }
      </td>
    </tr>
  )
}

Row.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
}
