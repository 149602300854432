import React from 'react'
import {
  Styles, PageHeader, ErrorBox, Paginator, ListManager,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import cities from '@/services/api/cities'
import usePageTitle from '@/hooks/usePageTitle'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, status, error,
  } = useAxiosRequest(cities.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de cidades"
        icon="fas fa-city"
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>UF</th>
                <th>UID</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((item) => (
                  <tr key={`item-${item?.id}`}>
                    <td>{item?.id}</td>
                    <td>{item?.name}</td>
                    <td>{item?.uf}</td>
                    <td>{item?.uid}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default List
