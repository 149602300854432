import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumberMask from '@/lib/masks/phoneNumber'

function Person({ person }) {
  return (
    <div className="panel">
      <div className="panel-header">
        <div className="panel-title">
          <div className="h5">Doador</div>
        </div>
      </div>
      <div className="panel-body">
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar avatarSize="lg" name={person?.fullName} photoURL={person?.photoURL} />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">{person?.fullName}</div>
            <div className="text-small">
              {documentNumberMask(person?.documentNumber)}
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column className="my-2 col-8 col-sm-12">
            <small>E-mail</small>
            <div>
              <b>{person?.email}</b>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="my-2 col-4 col-sm-12">
            <small>Fone</small>
            <div>
              <b>{person?.phoneNumber ? phoneNumberMask(person?.phoneNumber) : 'Não informado'}</b>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="my-2 col-12 col-sm-12">
            <small>Endereço</small>
            <div>
              <b>{person?.address?.formattedAddress}</b>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </div>
    </div>
  )
}

Person.propTypes = {
  person: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Person
