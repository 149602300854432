import sessions from '@/services/api/sessions'
import { useState } from 'react'

export default function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  const signIn = (params = { user: null, token: null }, cb = () => null) => {
    setUser(params.user)
    setToken(params.token)
    cb()
  }

  const signOut = async (cb) => {
    await sessions.signOut()
    setUser(null)
    setToken(null)
    cb()
  }

  return {
    user, token, signIn, signOut,
  }
}
