import { LOCAL_STORAGE_AUTH_TOKEN } from '@/services/api/auth/constants'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
export default function GuestRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={() => (
        !localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN) ? (
          children
        )
          : (
            <Redirect
              to={{ pathname: '/app' }}
            />
          )
      )}
    />
  )
}
