import React from 'react'
import { Avatar, ErrorBox } from '@/components'
import PropTypes from 'prop-types'
import { ADOPTION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'

export default function AdoptionsRow({ adoptions }) {
  if (!adoptions) return null

  return (
    <>
      {
        adoptions?.length
          ? (
            <table className="table text-small">
              <thead>
                <tr>
                  <th width="50px">#</th>
                  <th>Pet</th>
                  <th>Doador</th>
                  <th>Data</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  adoptions.map((adoption) => (
                    <tr key={adoption?.id}>
                      <td>{adoption?.id}</td>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={adoption?.animal?.name}
                          photoURL={adoption?.animal?.photoURL}
                        />
                        <span>&nbsp;{adoption?.animal?.name}</span>
                      </td>
                      <td>
                        <Avatar avatarSize="sm" name={adoption?.donor?.fullName} photoURL={adoption?.donor?.photoURL} />
                        <span>&nbsp;{adoption?.donor?.fullName}</span>
                      </td>
                      <td>
                        <small>{dateMask(adoption?.createdAt, { minute: '2-digit', hour: '2-digit' })}</small>
                      </td>
                      <td className={ADOPTION_STATUS?.class[adoption?.status]}>
                        {ADOPTION_STATUS.t(adoption?.status)}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fab fa-gratipay fa-2x',
                title: 'Nenhuma adoção',
                message: 'Não há histórico de adoções',
              }}
            />
          )
      }
    </>
  )
}

AdoptionsRow.propTypes = {
  adoptions: PropTypes.arrayOf(Object),
}

AdoptionsRow.defaultProps = {
  adoptions: [],
}
