import { ErrorBox, Modal, Styles } from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import animalFairs from '@/services/api/animalFairs'
import React, { useCallback, useEffect, useState } from 'react'
import {
 Link, useHistory, useLocation, useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import Animal from './Animal'
import Person from './Person'

function Show({ title }) {
  usePageTitle({ title })
  const [removing, setRemoving] = useState(false)
  const params = useParams()
  const { id } = params
  const history = useHistory()
  const location = useLocation()

  const close = useCallback(() => history.goBack(), [history])

  const {
    data, status, error, loading, refreshData,
  } = useAxiosRequest(animalFairs.show, id)

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const onRemove = useCallback(async () => {
    try {
      setRemoving(true)
      await animalFairs.delete(id)
      toast.success(`${data?.animal?.name} removido com sucesso`)
      history.goBack()
    } finally {
      setRemoving(false)
    }
  }, [data?.animal?.name, history, id])

  return (
    <Modal.Base className="active">
      <Modal.Overlay onClick={close} />
      <Modal.Container>
        <Modal.Header>
          <Modal.Close onClick={close} />
          <Modal.Title>Detalhes do pet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Content>
            {loading && <Styles.Loading />}
            {status === API_STATUS.ERROR && (<div className="flex-centered"><ErrorBox error={error} /></div>)}
            {Boolean(data) && (
              <>
                <div className="mb-2">
                  <Animal animal={data?.animal} address={data?.address} />
                </div>
                <div className="mt-2">
                  <Person person={data?.person} />
                </div>
              </>
            )}
          </Modal.Content>
        </Modal.Body>
        <Modal.Footer>
          {
            Boolean(!loading && !data?.adopted) && (
              <>
                {
                  Boolean(data?.complaints) && (
                    <Link to={`/app/online-fair/animal-fairs/${data?.id}/complaints`} className="btn btn-link mr-2">
                      Exibir {data?.complaints} {data?.complaints > 1 ? ' denúncias' : ' denúncia'}
                    </Link>
                  )
                }
                <div className="popover popover-top text-left">
                  <Styles.Button disabled={removing} className={`btn-error btn-sm ${removing ? 'loading' : ''}`}>
                    <i className="fas fa-trash" />
                    &nbsp;Remover da feira
                  </Styles.Button>
                  <div className="popover-container">
                    <div className="card">
                      <div className="card-header">
                        <div className="h6">Remover da feira</div>
                      </div>
                      <div className="card-body">
                        Confirma a remoção deste pet da feira?
                      </div>
                      <div className="card-footer text-right">
                        <Styles.Button disabled={removing} onClick={onRemove} className={`btn-error btn-sm ${removing ? 'loading' : ''}`}>
                          <i className="fas fa-trash" />
                          &nbsp;Confirmar
                        </Styles.Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </Modal.Footer>
      </Modal.Container>
    </Modal.Base>
  )
}

export default Show
