import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Flexbox, Form, Styles } from '@/components'
import yup from '@/lib/yupPt'
import { Field } from 'formik'
import { ADOPTION_FAIR_STATUS } from '@/lib/enums'
import { toast } from 'react-toastify'
import onlineFair from '@/services/api/onlineFair'

const schema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  status: yup.string().required(),
  terms: yup.string().required(),
})
function FormFair({ fair, onChange }) {
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      const { data } = await onlineFair.createOrUpdate(form)
      onChange(data)
      toast.success('Feira salva com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [onChange])

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{
          title: fair?.title || '',
          description: fair?.description || '',
          status: fair?.status || 'draft',
          terms: fair?.terms || '',
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {
          ({
            errors, touched, isValid, dirty,
          }) => (
            <Form.ValidationForm>
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.title && errors?.title ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="title">Título da feira <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="title"
                      id="title"
                      autoComplete="off"
                      placeholder="Feira de adoção online"
                    />
                    {
                      touched?.title && errors?.title
                        ? <Form.Hint>{errors.title}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12">
                  <Form.Group className={`${touched?.status && errors?.status ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="status">Situação da feira <span className="text-error">*</span></Form.Label>
                    <Field
                      className="form-select"
                      as="select"
                      name="status"
                      id="status"
                    >
                      {
                        Object.values(ADOPTION_FAIR_STATUS.enum).map((value) => (
                          <option key={value} value={value}>{ADOPTION_FAIR_STATUS.t(value)}</option>
                        ))
                      }
                    </Field>
                    {
                      touched?.status && errors?.status
                        ? <Form.Hint>{errors.status}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-12">
                  <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="description"
                      id="description"
                      autoComplete="off"
                      placeholder="A feira de adoção vai acontecer assim..."
                    />
                    {
                      touched?.description && errors?.description
                        ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-12">
                  <Form.Group className={`${touched?.terms && errors?.terms ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="terms">Termos de adoção <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      name="terms"
                      id="terms"
                    >
                      {
                        ({ field }) => (
                          <textarea
                            name="terms"
                            className="form-input"
                            rows={5}
                            placeholder="Você precisa concordar com os seguintes termos..."
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                          />
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.terms && errors?.terms
                        ? <Form.Hint className="text-error">{errors.terms}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="mt-2 text-right">
                <Styles.Button
                  type="submit"
                  className={`${loading && 'loading'} btn-primary`}
                  disabled={!dirty || !isValid}
                >
                  <i className="fas fa-save" />
                  &nbsp;Salvar
                </Styles.Button>
              </div>
            </Form.ValidationForm>

          )
        }
      </Form.Formik>
    </fieldset>
  )
}

FormFair.propTypes = {
  fair: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
}

FormFair.defaultProps = {
  fair: null,
}

export default FormFair
