import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from '../views/Home'
import App from './App'
import Auth from './Auth'
import AuthContainer from './components/AuthContainer'
import ProvideAuth from './components/ProvideAuth'

export default function Routes() {
  return (
    <ProvideAuth>
      <AuthContainer>
        <Router>
          <Switch>
            <Auth />
          </Switch>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/app">
              <App />
            </Route>
          </Switch>
        </Router>
      </AuthContainer>
    </ProvideAuth>
  )
}
