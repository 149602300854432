import { ErrorBox, PageHeader, Styles } from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import rejectionReasons from '@/services/api/rejectionReasons'
import React, { useCallback, useRef } from 'react'
import Form from '../components/Form'

function List({ title }) {
  usePageTitle({ title })
  const formRef = useRef()
  const {
    data, setData, error, status,
  } = useAxiosRequest(rejectionReasons.list)

  const onCreate = useCallback((reason) => {
    setData([...data, reason])
  }, [data, setData])

  const onUpdate = useCallback((reason) => {
    setData(data.map((i) => (i?.id === reason?.id ? reason : i)))
  }, [data, setData])

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de motivos para rejeição"
        icon="fas fa-ban"
        rightAction={() => (
          <Styles.Button onClick={() => formRef.current?.open()} className="btn-primary">
            <i className="fas fa-plus" />
            &nbsp;Adicionar
          </Styles.Button>
        )}
      />
      <table className="table text-small table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Código</th>
            <th>Descrição</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((item) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td>{item?.code}</td>
                <td>{item?.description}</td>
                <td className="text-right" width="130">
                    <button
                      onClick={() => formRef.current?.open(item)}
                      type="button"
                      className="btn btn-primary btn-action btn-sm tooltip tooltip-left ml-1"
                      data-tooltip="Editar"
                    >
                      <i className="fas fa-pen" />
                    </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Form ref={formRef} onCreate={onCreate} onUpdate={onUpdate} />
    </Styles.Container>
  )
}

export default List
