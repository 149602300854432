import { Avatar } from '@/components'
import { ADOPTION_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'

function Row({ adoption }) {
  return (
    <tr key={adoption?.id}>
      <td>{adoption?.id}</td>
      <td>
        <Avatar avatarSize="sm" name={adoption?.animal?.name} photoURL={adoption?.animal?.photoURL} />
        <span className="ml-2">{adoption?.animal?.name}</span>
      </td>
      <td>
        <Avatar avatarSize="sm" name={adoption?.donor?.fullName} photoURL={adoption?.donor?.photoURL} />
        <span className="ml-2">{adoption?.donor?.fullName}</span>
      </td>
      <td>
        <Avatar avatarSize="sm" name={adoption?.newTutor?.fullName} photoURL={adoption?.newTutor?.photoURL} />
        <span className="ml-2">{adoption?.newTutor?.fullName}</span>
      </td>
      <td className={ADOPTION_STATUS.class?.[adoption?.status]}>
        {ADOPTION_STATUS.t(adoption?.status)}
      </td>
      <td className="text-center">
        {adoption?.adoptedAt ? dateMask(adoption?.adoptedAt, { hour: '2-digit', minute: '2-digit' }) : '-'}
      </td>
    </tr>
  )
}

Row.propTypes = {
  adoption: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Row
