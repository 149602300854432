import {
  ErrorBox, ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import states from '@/services/api/states'
import React from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import usePageTitle from '@/hooks/usePageTitle'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, status, error,
  } = useAxiosRequest(states.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de estados"
        icon="fas fa-map"
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th>UF</th>
                <th className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((item) => (
                  <tr key={`row-${item.id}`}>
                    <td>{item?.uf}</td>
                    <td className="text-right">
                      <Link
                        to={`/app/cities?${qs.stringify({ q: { uf: item?.uf } })}`}
                        className="btn btn-sm btn-action btn-info tooltip tooltip-left"
                        data-tooltip="Cidades"
                      >
                        <i className="fas fa-city" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default List
