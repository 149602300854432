const mapping = {
  requested: {
    title: 'Castração solicitada. \nAnalise a imagem antes de aprová-la!',
    icon: '',
  },
  rejected: {
    title: 'Castração rejeitada.',
    bgIconClass: 'bg-error',
    icon: 'fas fa-ban',
  },
  revision: {
    title: 'Castração enviada para revisão',
    icon: 'fas fa-question',
  },
  correction: {
    title: 'Castração aguardando a correção do tutor',
    icon: 'fas fa-edit fa-xs',
  },
  corrected: {
    title: 'O tutor informou que efetuou a correção',
    icon: 'fas fa-check',
  },
  inTreatment: {
    title: 'A clínica iniciou um tratamento para o pet',
    icon: 'fas fa-briefcase-medical',
  },
  preApproved: {
    title: 'Castração pré aprovada.',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check-circle',
  },
  approved: {
    title: 'Castração aprovada.',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check',
  },
  scheduled: {
    title: 'Castração agendada',
    icon: 'fas fa-calendar',
  },
  canceled: {
    title: 'Castração cancelada',
    icon: 'fas fa-ban',
    bgIconClass: 'bg-error',
  },
  attendance: {
    title: 'Atendimento iniciado.',
    icon: 'fas fa-xs fa-play',
  },
  awaitingConfirmation: {
    title: 'A clínica concluiu a castração.\nO tutor deve confirmar o serviço e avaliar a clínica',
    bgIconClass: 'bg-success',
    icon: 'fas fa-clock',
  },
  completed: {
    title: 'Atendimento confirmado',
    bgIconClass: 'bg-success',
    icon: 'fas fa-star',
  },
}

export default (status) => mapping[status] || { title: status, icon: 'fas fa-question' }
