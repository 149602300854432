import {
  Card, Flexbox, Footer, Form, Styles,
} from '@/components'
import useAuth from '@/hooks/useAuth'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import sessions from '@/services/api/sessions'
import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AppContainer, Logo, Overflow } from './styles'

const schema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().min(6).required(),
})

function Login({ title }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const auth = useAuth()

  usePageTitle({ title })

  const signIn = useCallback(async (form) => {
    try {
      setError(null)
      setLoading(true)
      const { token } = await sessions.signIn(form)
      const { data } = await sessions.currentUser()
      auth.signIn({ user: data, token })
      const from = location.state?.from || '/app'
      history.replace(from)
    } catch (e) {
      setError(e)
      setLoading(false)
    }
  }, [auth, history, location.state])

  return (
    <>
      <AppContainer>
        <Overflow />
        <fieldset disabled={loading}>
          <Form.Formik
            initialValues={{
              password: '',
              login: '',
            }}
            validationSchema={schema}
            onSubmit={signIn}
          >
            {
              ({
                errors, touched, isValid, dirty,
              }) => (
                <Form.ValidationForm>
                  <Card.Base style={{ width: 300 }}>
                    <Card.Header>
                      <Flexbox.Columns className="align-center">
                        <Flexbox.Column className="col-auto">
                          <Logo />
                        </Flexbox.Column>
                        <Flexbox.Column>
                          Petis - Login
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Card.Header>
                    <Card.Body>
                      {
                        error
                          ? (
                            <Styles.Toast className="toast-error">
                              {error?.message}
                            </Styles.Toast>
                          )
                          : null
                      }
                      <Form.Group className={`${touched?.login && errors?.login ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="login">E-mail ou CPF</Form.Label>
                        <Form.ValidationField
                          name="login"
                          autoFocus
                          id="login"
                          autoComplete="off"
                          placeholder="E-mail ou CPF"
                        />
                        {
                          touched?.login && errors?.login
                            ? <Form.Hint>{errors.login}</Form.Hint> : null
                        }
                      </Form.Group>
                      <Form.Group className={`${touched?.password && errors?.password ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="password">Senha</Form.Label>
                        <Form.ValidationField
                          name="password"
                          type="password"
                          id="password"
                          placeholder="Senha super segura"
                        />
                        {
                          touched?.password && errors?.password
                            ? <Form.Hint>{errors.password}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                      <Flexbox.Columns className="align-center">
                        <Flexbox.Column />
                        <Flexbox.Column className="col-auto">
                          <Styles.Button
                            type="submit"
                            className="btn-primary"
                            disabled={!dirty || !isValid}
                          >
                            <i className="fas fa-sign-in-alt" />
                              &nbsp;Entrar
                          </Styles.Button>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Card.Footer>
                  </Card.Base>
                </Form.ValidationForm>
              )
            }
          </Form.Formik>
        </fieldset>
      </AppContainer>
      <Footer />
    </>
  )
}

export default Login
