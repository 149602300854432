import { Sidebar } from '@/components'

import About from '@/views/app/About'
import BreedsList from '@/views/app/categories/breeds/List'
import CategoriesList from '@/views/app/categories/List'
import Dashboard from '@/views/app/Dashboard'
import OnlineFair from '@/views/app/OnlineFair'
import OnlineFairAnimals from '@/views/app/OnlineFair/AnimalFairs'
import OnlineFairAnimalsShow from '@/views/app/OnlineFair/AnimalFairs/Show'
import OnlineFairAnimalsShowComplaints from '@/views/app/OnlineFair/AnimalFairs/Show/Complaints'
import OnlineFairAdoptions from '@/views/app/OnlineFair/Adoptions'
import NewsList from '@/views/app/News/List'
import NewsForm from '@/views/app/News/Form'
import StatesList from '@/views/app/states/List'
import CitiesList from '@/views/app/cities/List'
import PartnerRequestsList from '@/views/app/partnerRequests/List'
import PartnerRequestsShow from '@/views/app/partnerRequests/Show'
import PartnerLicensingsList from '@/views/app/partnerLicensings/List'
import PartnerLicensingsForm from '@/views/app/partnerLicensings/Form'
import RejectionReasons from '@/views/app/rejectionReasons/List'
import CancelationReasons from '@/views/app/cancelationReasons/List'
import ResetEmails from '@/views/app/resetEmails/List'
import ResetEmailsShow from '@/views/app/resetEmails/Show'
import ResetEmailsRject from '@/views/app/resetEmails/Show/Reject'
import AnimalsList from '@/views/app/animals/List'
import AnimalsShow from '@/views/app/animals/Show'
import Castrations from '@/views/app/castrations'
import CastrationStatus from '@/views/app/castrations/Status'
import CastrationShow from '@/views/app/castrations/Show'
import BreadcrumbCastrations from '@/views/app/castrations/Status/Breadcrumb'
import VaccineModelsList from '@/views/app/vaccineModels/List'

import React from 'react'
import ReportsMenu from '@/views/app/reports/Menu'
import AnimalsTermalMap from '@/views/app/reports/AnimalsTermalMap'
import AnimalsPointMap from '@/views/app/reports/AnimalsPointMap'
import CastrationsTermalMap from '@/views/app/reports/CastrationsTermalMap'
import CastrationsPointMap from '@/views/app/reports/CastrationsPointMap'
import PrivateRoute from './components/PrivateRoute'

import UsersRoutes from './users'

function App() {
  const routes = [
    {
      key: 'dashboard',
      exact: true,
      breadcrumb: 'Dashboard',
      icon: 'fas fa-fw fa-tachometer-alt',
      path: '/app',
    },
    {
      key: 'users',
      breadcrumb: 'Usuários',
      icon: 'fas fa-fw fa-users',
      path: '/app/users',
    },
    {
      key: 'castrations',
      breadcrumb: 'Castrações',
      icon: 'fas fa-fw fa-cut',
      path: '/app/castrations',
    },
    {
      key: 'castrationsStatus',
      breadcrumb: BreadcrumbCastrations,
      path: '/app/castrations/status/:status',
      hidden: true,
    },
    {
      key: 'animals',
      breadcrumb: 'Pets',
      icon: 'fas fa-fw fa-paw',
      path: '/app/animals',
    },
    {
      key: 'onlineFair',
      breadcrumb: 'Feirinha online',
      icon: 'fas fa-fw fa-heart',
      path: '/app/online-fair/animal-fairs',
    },
    {
      key: 'onlineFairAdoptions',
      breadcrumb: 'Adoções',
      icon: 'fas fa-fw fa-heart',
      path: '/app/online-fair/adoptions',
      hidden: true,
    },
    {
      key: 'news',
      breadcrumb: 'News',
      icon: 'fas fa-fw fa-newspaper',
      path: '/app/news',
    },
    {
      key: 'newsCreate',
      breadcrumb: 'Adicionar',
      icon: 'fas fa-fw fa-newspaper',
      path: '/app/news/create',
      hidden: true,
    },
    {
      key: 'newsEdit',
      breadcrumb: 'Editar',
      icon: 'fas fa-fw fa-newspaper',
      path: '/app/news/:id/edit',
      hidden: true,
    },
    {
      key: 'partners',
      breadcrumb: 'Parceiros',
      icon: 'fas fa-fw fa-handshake',
      path: '/app/partner-licensings',
    },
    {
      key: 'newPartner',
      breadcrumb: 'Novo parceiro',
      icon: 'fas fa-fw fa-handshake',
      path: '/app/partner-licensings/add',
      hidden: true,
    },
    {
      key: 'partnerRequests',
      breadcrumb: 'Solicitações para parceria',
      icon: 'fas fa-fw fa-handshake',
      path: '/app/partner-requests',
      hidden: true,
    },
    {
      key: 'registers',
      breadcrumb: 'Registros',
      icon: 'fas fa-fw fa-archive',
      children: [
        {
          key: 'vaccineModels',
          path: '/app/vaccine-models',
          breadcrumb: 'Vacinação',
          icon: 'fas fa-fw fa-syringe',
        },
        {
          key: 'categories',
          path: '/app/categories',
          breadcrumb: 'Espécies',
          icon: 'fas fa-fw fa-dog',
        },
        {
          key: 'breeds',
          path: '/app/categories/:categoryId/breeds',
          breadcrumb: 'Raças',
          icon: 'fas fa-fw fa-paw',
          hidden: true,
        },
        {
          key: 'states',
          path: '/app/states',
          breadcrumb: 'Estados',
          icon: 'fas fa-fw fa-map',
        },
        {
          key: 'cities',
          path: '/app/cities',
          breadcrumb: 'Cidades',
          icon: 'fas fa-fw fa-city',
        },
        {
          key: 'rejectionReasons',
          path: '/app/rejection-reasons',
          breadcrumb: 'Rejeições',
          icon: 'fas fa-fw fa-ban',
        },
        {
          key: 'cancelationReasons',
          path: '/app/cancelation-reasons',
          breadcrumb: 'Cancelamentos',
          icon: 'fas fa-fw fa-times',
        },
      ],
    },
    {
      key: 'resetEmails',
      breadcrumb: 'Troca de e-mail',
      icon: 'fas fa-fw fa-envelope',
      path: '/app/reset-emails',
    },
    {
      key: 'resetEmails',
      breadcrumb: 'Rejeitar',
      hidden: true,
      path: '/app/reset-emails/:id/reject',
    },
    {
      key: 'reports',
      breadcrumb: 'Relatórios',
      icon: 'fas fa-fw fa-chart-line',
      path: '/app/reports',
    },
    {
      key: 'reportsAnimalsTermalMap',
      breadcrumb: 'Densidade de pets',
      icon: 'fas fa-fw fa-map',
      hidden: true,
      path: '/app/reports/animals-termal-map',
    },
    {
      key: 'reportsAnimalsPointMap',
      breadcrumb: 'Pets no mapa',
      icon: 'fas fa-fw fa-map-marker',
      hidden: true,
      path: '/app/reports/animals-point-map',
    },
    {
      key: 'reportsCastrationsTermaltMap',
      breadcrumb: 'Densidade de castrações',
      icon: 'fas fa-fw fa-map',
      hidden: true,
      path: '/app/reports/castrations-termal-map',
    },
    {
      key: 'about',
      breadcrumb: 'Sobre',
      icon: 'fas fa-fw fa-info-circle',
      path: '/app/about',
    },
  ]

  return (
    <Sidebar routes={routes}>
      <PrivateRoute exact path="/app">
        <Dashboard title="Petis Owner - Dashboard" />
      </PrivateRoute>

      <UsersRoutes />

      <PrivateRoute exact path="/app/castrations">
        <Castrations title="Petis Owner - Castrações" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/castrations/status/:status">
        <CastrationStatus title="Petis Owner - Castrações" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/castrations/status/:status/:id">
        <CastrationShow title="Petis Owner - Castrações" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/animals">
        <AnimalsList title="Petis Owner - Pets" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/animals/:id">
        <AnimalsShow title="Petis Owner - Pet" />
      </PrivateRoute>

      <PrivateRoute path="/app/online-fair">
        <OnlineFair title="Petis Owner - Feira online" />
      </PrivateRoute>
      <PrivateRoute path="/app/online-fair/animal-fairs">
        <OnlineFairAnimals title="Petis Owner - Feira online / Animais" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/online-fair/animal-fairs/:id">
        <OnlineFairAnimalsShow title="Petis Owner - Feira online / Animais / Animal" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/online-fair/animal-fairs/:id/complaints">
        <OnlineFairAnimalsShowComplaints title="Petis Owner - Feira online / Animais / Animal / Denúncias" />
      </PrivateRoute>
      <PrivateRoute path="/app/online-fair/adoptions">
        <OnlineFairAdoptions title="Petis Owner - Feira online / Adoções" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/news">
        <NewsList title="Petis Owner - News" />
      </PrivateRoute>
      <PrivateRoute path="/app/news/create">
        <NewsForm title="Petis Owner - News / Adicionar" />
      </PrivateRoute>
      <PrivateRoute path="/app/news/:id/edit">
        <NewsForm title="Petis Owner - News / Editar" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/vaccine-models">
        <VaccineModelsList title="Petis Owner - Modelos de vacina" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/categories">
        <CategoriesList title="Petis Owner - Espécies" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/categories/:categoryId/breeds">
        <BreedsList title="Petis Owner - Espécies / Raças" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/states">
        <StatesList title="Petis Owner - Estados" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/cities">
        <CitiesList title="Petis Owner - Cidades" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/partner-requests">
        <PartnerRequestsList title="Petis Owner - Solicitações" />
      </PrivateRoute>
      <PrivateRoute path="/app/partner-requests/:id">
        <PartnerRequestsShow title="Petis Owner - Solicitações / Solicitação" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/partner-licensings">
        <PartnerLicensingsList title="Petis Owner - Licenças" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/partner-licensings/add">
        <PartnerLicensingsForm title="Petis Owner - Licenças / Nova" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/rejection-reasons">
        <RejectionReasons title="Petis Owner - Razões de rejeição" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/cancelation-reasons">
        <CancelationReasons title="Petis Owner - Razões de cancelamento" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/reports">
        <ReportsMenu title="Petis Owner - Relatórios" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/reports/animals-termal-map">
        <AnimalsTermalMap title="Petis Owner - Densidade de pets" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/reports/animals-point-map">
        <AnimalsPointMap title="Petis Owner - Pets no mapa" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/reports/castrations-termal-map">
        <CastrationsTermalMap title="Petis - Owner / Mapa térmico de castrações" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/reports/castrations-point-map">
        <CastrationsPointMap title="Petis - Owner / Castrações no mapa" />
      </PrivateRoute>

      <PrivateRoute exact path="/app/reset-emails">
        <ResetEmails title="Petis Owner - Alterar e-mails" />
      </PrivateRoute>
      <PrivateRoute path="/app/reset-emails/:id">
        <ResetEmailsShow title="Petis Owner - Alterar e-mails / E-mail" />
      </PrivateRoute>
      <PrivateRoute exact path="/app/reset-emails/:id/reject">
        <ResetEmailsRject title="Petis Owner - Alterar e-mails / E-mail / Rejeitar" />
      </PrivateRoute>

      <PrivateRoute path="/app/about">
        <About title="Petis Owner - Sobre" />
      </PrivateRoute>
    </Sidebar>
  )
}

export default App
