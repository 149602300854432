import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@/components'
import dateMask from '@/lib/masks/date'
import { Link } from 'react-router-dom'

function Row({ animalFair }) {
  return (
    <tr key={animalFair?.id}>
      <td>{animalFair?.id}</td>
      <td>
        <Avatar avatarSize="sm" name={animalFair?.animal?.name} photoURL={animalFair?.animal?.photoURL} />
        <span className="ml-2">{animalFair?.animal?.name}</span>
      </td>
      <td>
        <Avatar avatarSize="sm" name={animalFair?.person?.fullName} photoURL={animalFair?.person?.photoURL} />
        <span className="ml-2">{animalFair?.person?.fullName}</span>
      </td>
      <td>{dateMask(animalFair?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
      <td className="text-center">
        {animalFair?.adopted ? 'Sim' : 'Não'}
      </td>
      <td className="text-center">{animalFair?.complaints}</td>
      <td className="text-right" width="100">
        <Link
          to={`/app/online-fair/animal-fairs/${animalFair?.id}`}
          className="btn btn-info btn-action btn-sm tooltip tooltip-left"
          data-tooltip="Visualizar"
        >
          <i className="fas fa-eye" />
        </Link>
      </td>
    </tr>
  )
}

Row.propTypes = {
  animalFair: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Row
