import React from 'react'
import {
  ErrorBox, Styles, Flexbox, Box, Card,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import dashboard from '@/services/api/dashboard'
import { LICENSE_TYPE, PARTNER_REQUEST_STATUS } from '@/lib/enums'
import { Link } from 'react-router-dom'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import ChartLine from './ChartLine'

function Dashboard({ title }) {
  usePageTitle({ title })
  const { data, status, error } = useAxiosRequest(dashboard.home)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  if (!data) return null

  return (
    <Styles.Container className="grid-xl">
      <Flexbox.Columns>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box to="/app/partner-requests" icon="fas fa-edit" className="box-primary" title="Solicitações" value={data?.counts?.partnerRequests} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box to="/app/users" icon="fas fa-users" className="box-info" title="Usuários" value={data?.counts?.people} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box to="/app/animals" icon="fas fa-users" className="box-success" title="Pets" value={data?.counts?.animals} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box to="/app/castrations" icon="fas fa-cut" className="box-error" title="Castrações" value={data?.counts?.castrations} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box icon="fas fa-heart" className="box-info" title="Total de adoções" value={data?.counts?.totalAdoptions} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box icon="fas fa-heart" className="box-success" title="Adoções confirmadas" value={data?.counts?.confirmedAdoptions} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box icon="fas fa-dog" className="box-error" title="Pets nas feiras" value={data?.counts?.countAnimalsInFairs} />
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-md-6 col-sm-12 mb-2">
          <Box to="/app/reset-emails" icon="fas fa-envelope" className="box-primary" title="Troca de e-mails" value={data?.counts?.countPendingResetEmails} />
        </Flexbox.Column>
      </Flexbox.Columns>

      <Flexbox.Columns className="align-center">
        <Flexbox.Column className="col-12">
          <Card.Base>
            <Card.Header>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column className="col-auto">
                  <i className="fas fa-user fa-lg" />
                </Flexbox.Column>
                <Flexbox.Column>
                  <div className="h5">Contas criadas nos últimos meses</div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Header>
            <Card.Body>
              <ChartLine data={data?.usersInLastMonths} lineDataKey="Contas criadas" xAxisDataKey="monthString" />
            </Card.Body>
          </Card.Base>
        </Flexbox.Column>
        <Flexbox.Column className="col-12 mt-2">
          <Card.Base>
            <Card.Header>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column className="col-auto">
                  <i className="fas fa-cut fa-lg" />
                </Flexbox.Column>
                <Flexbox.Column>
                  <div className="h5">Castrações solicitadas nos últimos meses</div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </Card.Header>
            <Card.Body>
              <ChartLine data={data?.castrationsInLastMonths} lineDataKey="Castrações solicitadas" xAxisDataKey="monthString" />
            </Card.Body>
          </Card.Base>
        </Flexbox.Column>
      </Flexbox.Columns>

      {
        data?.lastPartnerRequests?.length > 0
        && (
          <div className="mt-2">
            <Card.Base>
              <Card.Header>
                <Flexbox.Columns className="align-center">
                  <Flexbox.Column className="col-auto">
                    <i className="fas fa-edit fa-lg" />
                  </Flexbox.Column>
                  <Flexbox.Column>
                    <div className="h5">Últimos pedidos de adesão</div>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </Card.Header>
              <Card.Body>
                <table className="table text-small table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Cidade</th>
                      <th>Tipo de licença</th>
                      <th>Solicitante</th>
                      <th>Data</th>
                      <th>Status</th>
                      <th className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.lastPartnerRequests.map((item) => (
                        <tr key={`item-${item?.id}`}>
                          <td>{item?.id}</td>
                          <td>{`${item?.city?.name} - ${item?.city?.uf}`}</td>
                          <td>{LICENSE_TYPE.t(item?.licenseType)}</td>
                          <td>{item?.requesterName}</td>
                          <td>{dateMask(item?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                          <td className={`text-bold ${PARTNER_REQUEST_STATUS.class[item?.status]}`}>
                            {PARTNER_REQUEST_STATUS.t(item?.status)}
                          </td>
                          <td className="text-right">
                            <Link
                              to={`/app/partner-requests/${item?.id}`}
                              className="btn btn-action btn-sm btn-info tooltip tooltip-left"
                              data-tooltip="Visualizar"
                            >
                              <i className="fas fa-eye" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Card.Body>
            </Card.Base>
          </div>
        )
      }
    </Styles.Container>
  )
}

export default Dashboard
