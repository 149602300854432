import { jsEncode } from 'paliari-js-utils'

const subdomain = window.location.hostname.split('.')[0]

const environment = {
  'owner-staging': {
    REACT_APP_BASE_API_URL: 'https://api-staging.petis.me',
    REACT_APP_BASE_AUTH_URL: 'https://api-staging.petis.me/auth',
    REACT_APP_BASE_WEB_SHOW_URL: 'https://web-show-staging.petis.me',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
  owner: {
    REACT_APP_BASE_API_URL: 'https://api.petis.me',
    REACT_APP_BASE_AUTH_URL: 'https://api.petis.me/auth',
    REACT_APP_BASE_WEB_SHOW_URL: 'https://web-show.petis.me',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
  development: {
    REACT_APP_BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000',
    REACT_APP_BASE_AUTH_URL: process.env.REACT_APP_BASE_AUTH_URL || 'http://localhost:4000',
    REACT_APP_BASE_WEB_SHOW_URL: process.env.REACT_APP_BASE_WEB_SHOW_URL || 'https://web-show-staging.petis.me',
    REACT_APP_GOOGLE_MAPS_API_KEY: 'AIzaSyCKGJyEvGZNf4xyDvQ3br7hZ5J9apqDO8A',
    REACT_THUMBOR_BASE_URL: 'https://thumb.paliari.com.br',
    REACT_THUMBOR_SECURITY_KEY: jsEncode('ffrariCCiiXaViaVlslrXBfYEaaXXaX8'),
  },
}

export default environment[subdomain] || environment.development
