import api from './api'

export default {
  castrationsInMap(params = {}) {
    return api.get('/reports/castrations-in-map', { params })
  },
  castrationsPointMap(params = {}) {
    return api.get('/reports/castrations-point-map', { params })
  },
  animalsInMap(params = {}) {
    return api.get('/reports/animals/animals-in-map', { params })
  },
  animalsPointMap(params = {}) {
    return api.get('/reports/animals/animals-point-map', { params })
  },

}
