/* eslint-disable max-len */
/* eslint-disable no-unreachable */
import {
  PageHeader, Styles, Form, Flexbox,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import { NEWS_DISTRIBUTION, NEWS_TYPE } from '@/lib/enums'
import yup from '@/lib/yupPt'
import news from '@/services/api/news'
import { Field } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// import { Container } from './styles';
const schema = yup.object().shape({
  title: yup.string().required(),
  data: yup.string().required(),
  type: yup.string().required(),
  distribution: yup.string().required(),
})
function FormNews({ title }) {
  const [data, setData] = useState({
    title: '',
    data: '',
    type: NEWS_TYPE.enum.HTML,
    distribution: NEWS_DISTRIBUTION.enum.ALL,
  })
  const history = useHistory()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  usePageTitle({ title })
  const { id } = useParams()

  const onSubmit = useCallback(async (form) => {
    try {
      setSaving(true)
      if (id) {
        await news.update(id, form)
        toast.success('Publicação atualizada com sucesso')
        history.goBack()
      } else {
        await news.create(form)
        toast.success('Publicação salva com sucesso')
        history.goBack()
      }
    } catch (e) {
      setSaving(false)
      toast.error(e?.message)
    }
  }, [history, id])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const res = await news.show(id)
      setData(res.data)
      setLoading(false)
    } catch (e) {
      toast.error(e?.message)
      history.goBack()
    }
  }, [history, id])

  useEffect(() => {
    if (id) fetchData()
  }, [fetchData, id])

  if (loading) return <Styles.Loading className="full-height loading-lg" />

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title={id ? 'Editar news' : 'Adicionar news'}
        icon="fas fa-newspaper"
      />
      <fieldset disabled={loading || saving}>
        <Form.Formik
          initialValues={data}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {
            ({
              errors, touched, isValid,
            }) => (
              <Form.ValidationForm>
                <Flexbox.Columns>
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.title && errors?.title ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="title">Título <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="title"
                        autoFocus
                        id="title"
                        autoComplete="off"
                        placeholder="Título da publicação"
                      />
                      {
                        touched?.title && errors?.title
                          ? <Form.Hint>{errors.title}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 col-sm-12">
                    <Form.Group className={`${touched?.type && errors?.type ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="type">Tipo <span className="text-error">*</span></Form.Label>
                      <Field
                        className="form-select"
                        as="select"
                        name="type"
                        id="type"
                      >
                        {
                          Object.values(NEWS_TYPE.enum).map((value) => (
                            <option key={value} value={value}>{NEWS_TYPE.t(value)}</option>
                          ))
                        }
                      </Field>
                      {
                        touched?.type && errors?.type
                          ? <Form.Hint>{errors.type}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 col-sm-12">
                    <Form.Group className={`${touched?.distribution && errors?.distribution ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="distribution">Distribuição <span className="text-error">*</span></Form.Label>
                      <Field
                        className="form-select"
                        as="select"
                        name="distribution"
                        id="distribution"
                      >
                        {
                          Object.values(NEWS_DISTRIBUTION.enum).map((value) => (
                            <option key={value} value={value}>{NEWS_DISTRIBUTION.t(value)}</option>
                          ))
                        }
                      </Field>
                      {
                        touched?.distribution && errors?.distribution
                          ? <Form.Hint>{errors.distribution}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.data && errors?.data ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="data">Conteúdo <span className="text-error">*</span></Form.Label>
                      <Field
                        name="data"
                        id="data"
                      >
                        {
                          ({ field }) => (
                            <textarea
                              name="data"
                              className="form-input"
                              rows={5}
                              placeholder="Escreva o conteúdo da publicação..."
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }
                      </Field>
                      {
                        touched?.data && errors?.data
                          ? <Form.Hint className="text-error">{errors?.data}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="text-right mt-2">
                  <Styles.Button className={`btn-primary ${saving ? 'loading' : ''}`} disabled={!isValid}>
                    <i className="fas fa-check" />
                    &nbsp;Salvar
                  </Styles.Button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </Styles.Container>
  )
}

export default FormNews
