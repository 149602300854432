import { Avatar, Card, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function ClinicCard({ clinic, preApproved }) {
  function renderMessage() {
    if (preApproved) {
      return (
        <div>
          A castração <b>já foi aprovada</b>, quando uma clínica estiver disponível o <b className="text-primary">Petis</b> irá selecioná-la para realizar a castração
        </div>
      )
    }
    return (
      <div>
        Após a <b>aprovação</b> o <b className="text-primary">Petis</b> irá selecionar a clínica mais próxima ao tutor
      </div>
    )
  }

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Clínica</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-clinic-medical" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          clinic
            ? (
              <>
                <Flexbox.Columns>
                  <Flexbox.Column className="col-auto">
                    <Avatar
                      avatarSize="lg"
                      name={clinic?.fullName}
                      photoURL={clinic?.photoURL}
                      alt="Imagem de usuário"
                    />
                  </Flexbox.Column>
                  <Flexbox.Column>
                    <Link to={`/app/users/${clinic?.id}`}>
                      <div className="text-primary text-bold">{clinic?.fullName}</div>
                    </Link>
                    <div className="text-small">{clinic?.documentNumber ? documentNumberMask(clinic?.documentNumber) : '-'}</div>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="divider" />
                <Flexbox.Columns className="mb-1">
                  <Flexbox.Column>E-mail</Flexbox.Column>
                  <Flexbox.Column className="col-auto text-bold">
                    {clinic?.email}
                  </Flexbox.Column>
                </Flexbox.Columns>
                <Flexbox.Columns className="mb-1">
                  <Flexbox.Column>Fone</Flexbox.Column>
                  <Flexbox.Column className="col-auto text-bold">
                    {phoneNumber(clinic?.phoneNumber) || '-'}
                  </Flexbox.Column>
                </Flexbox.Columns>
              </>
            )
            : renderMessage()
        }
      </Card.Body>
      {
        Boolean(clinic?.formattedAddress)
        && (
          <Card.Footer>
            <div className="divider" />
            <div className="text-small">{clinic?.formattedAddress}</div>
          </Card.Footer>
        )
      }
    </Card.Base>
  )
}

ClinicCard.propTypes = {
  clinic: PropTypes.objectOf(Object),
  preApproved: PropTypes.bool,
}

ClinicCard.defaultProps = {
  clinic: null,
  preApproved: false,
}

export default ClinicCard
