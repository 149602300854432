import {
  Filter, Form,
  Mask, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import castrations from '@/services/api/castrations'
import React from 'react'
import { useParams } from 'react-router-dom'
import mapTitles from './mapTitles'
import CastrationsQuickFilter from '../components/QuickFilter'
import ListStatus from './List'

function CastrationStatus({ title }) {
  usePageTitle(title)
  const params = useParams()
  const query = useQuery()
  const { status } = params
  const { data } = useAxiosRequest(castrations.list, { status, ...query })
  const titleParams = mapTitles(params.status)
  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title={titleParams.title}
        icon={titleParams.icon}
        subtitle={titleParams.subtitle}
        rightAction={() => (
          <Filter
            initialValues={{
              'tutor.fullName_cont': '', 'tutor.email_eq': '', 'tutor.phoneNumber_eq': '', 'tutor.documentNumber_eq': '', 'animal.name_cont': '', 'animal.uid_eq': '', 'clinic.fullName_cont': '', 'clinic.documentNumber_eq': '',
            }}
            title="Filtrar castrações"
            renderForm={({ setFieldValue }) => (
              <>
                <div className="divider text-center" data-content="Tutor" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.fullName_cont">Nome do tutor</Form.Label>
                  <Form.ValidationField
                    name="['tutor.fullName_cont']"
                    className="input-sm"
                    autoFocus
                    id="tutor.fullName_cont"
                    autoComplete="none"
                    placeholder="Fulano da Silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.email_eq">Email do tutor</Form.Label>
                  <Form.ValidationField
                    name="['tutor.email_eq']"
                    className="input-sm"
                    autoFocus
                    id="tutor.email_eq"
                    autoComplete="none"
                    placeholder="fulano@email.com"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.phoneNumber_eq">Telefone do tutor</Form.Label>
                  <Form.ValidationField name="['tutor.phoneNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.Phone
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="Número do telefone"
                          id="['tutor.phoneNumber_eq']"
                          onAccept={(value) => {
                            setFieldValue("['tutor.phoneNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="tutor.documentNumber_eq">CPF/CNPJ do tutor</Form.Label>
                  <Form.ValidationField name="['tutor.documentNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF/CNPJ do tutor"
                          id="['tutor.documentNumber_eq']"
                          onAccept={(value) => {
                            setFieldValue("['tutor.documentNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <div className="divider text-center" data-content="Pet" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="animal.name_cont">Nome do animal</Form.Label>
                  <Form.ValidationField
                    name="['animal.name_cont']"
                    className="input-sm"
                    id="animal.name_cont"
                    autoComplete="none"
                    placeholder="Totó da Silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="animal.uid_eq">RGA</Form.Label>
                  <Form.ValidationField name="['animal.uid_eq']">
                    {
                      ({ field }) => (
                        <Mask.Rga
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="####.####.####.####.####"
                          id="['animal.uid_eq']"
                          onAccept={(value) => {
                            setFieldValue("['animal.uid_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <div className="divider text-center" data-content="Clínica" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinic.fullName_cont">Nome da clínica</Form.Label>
                  <Form.ValidationField
                    name="['clinic.fullName_cont']"
                    className="input-sm"
                    autoFocus
                    id="clinic.fullName_cont"
                    autoComplete="none"
                    placeholder="Clínica do pet"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinic.documentNumber_eq">CPF/CNPJ da clínica</Form.Label>
                  <Form.ValidationField name="['clinic.documentNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF/CNPJ da clínica"
                          id="['clinic.documentNumber_eq']"
                          onAccept={(value) => {
                            setFieldValue("['clinic.documentNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <CastrationsQuickFilter data="castration.animal" />
      <ListStatus data={data} title={titleParams.title} params={params} />
    </Styles.Container>
  )
}

export default CastrationStatus
