export default {
  emailAlreadyInUse: 'Email já está em uso',
  invalidEmail: 'Email inválido',
  unverifiedEmail: 'Email não verificado',
  emailAlreadyVerified: 'Email já verificado',
  weakPassword: 'A senha precisa ter 6 caracteres ou mais',
  wrongPassword: 'Senha incorreta',
  invalidDisplayName: 'Apelido inválido',
  invalidAppleFullName: 'Nome inválido',
  invalidPhotoURL: 'Imagem de perfil inválida',
  userNotFound: 'Usuário não encontrado',
  userInactive: 'Usuário inativo',
  wrongToken: 'Token incorreto',
  tokenExpirated: 'Token expirado',
  unknown: 'Erro desconhecido',
  internal: 'Erro interno',
  invalidStrategy: 'Estratégia inválida. Use local, google, facebook ou apple',
  loginWithPasswordNotConfigured: 'Login com email e senha não configurado',
  notImplemented: 'Não implementado',
  facebookLoginError: 'Erro de login com o Facebook',
  googleLoginError: 'Erro de login com o Google',
  appleLoginError: 'Erro de login com a Apple',
  unauthorized: 'Usuário não autenticado',
}
