import React from 'react'
import { registerLocale } from 'react-datepicker'
import ptBr from 'date-fns/locale/pt-BR'
import Routes from '../routes'
import GlobalStyle from '../styles/globals'

import 'react-datepicker/dist/react-datepicker.css'

export default function App() {
  registerLocale('ptBR', ptBr)
  return (
    <div>
      <Routes />
      <GlobalStyle />
    </div>
  )
}
