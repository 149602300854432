import authApi from './auth/api'
import { LOCAL_STORAGE_AUTH_TOKEN } from './auth/constants'

export default {
  /**
   *
   * @param {{ email: string, password: string }} body
   *
   * @returns {Promise<{token: string}>}
   */
  async signIn(body) {
    const { data } = await authApi.post('/sessions/sign-in/local', body)

    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, data?.token)

    return data
  },

  async signOut() {
    await authApi.delete('/sessions/sign-out')
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN)
  },

  currentUser() {
    return authApi.get('/current-user')
  },
}
