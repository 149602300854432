/**
 *
 * @param {string} dateString
 * @param {Intl.DateTimeFormatOptions} options
 * @returns
 */
 export default function dateMask(dateString, options = {}) {
  try {
    const date = new Date(dateString)
    return date.toLocaleDateString('pt-BR', { ...options })
  } catch (error) {
    return dateString
  }
}
