import { PageHeader, Styles } from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Menu({ title }) {
  usePageTitle({ title })
  const { pathname } = useLocation()

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Relatórios"
        icon="fas fa-chart-line"
        subtitle="Lista de relatórios disponíveis"
      />
      <table className="table">
        <thead>
          <tr>
            <th>Relatório</th>
            <th width="1" className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map" />
              Densidade demográfica de pets
              <small> (Mostra as áreas com a maior concentração de pets)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/animals-termal-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map-marker-alt" />
              Pets no mapa
              <small> (Mostra os pets no lugar onde os tutores moram)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/animals-point-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map" />
              Densidade de castrações
              <small> (Mostra as áreas com maior frequência de solicitações)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-termal-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <i className="s-rounded p-1 mr-2 fas fa-fw fa-map-marker-alt" />
              Castrações no mapa
              <small> (Mostra as castrações no lugar onde foram solicitadas)</small>
            </td>
            <td className="text-right">
              <Link to={`${pathname}/castrations-point-map`} className="btn btn-action btn-sm btn-primary">
                <i className="fas fa-eye" />
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </Styles.Container>
  )
}

export default Menu
