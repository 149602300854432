import {
  Flexbox, Form, Modal, Styles,
} from '@/components'
import yup from '@/lib/yupPt'
import cancelationReasons from '@/services/api/cancelationReasons'
import React, {
  forwardRef, useCallback, useImperativeHandle, useState,
} from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  code: yup.string().required(),
  description: yup.string().required(),
})
const cancelationReasonForm = { code: '', description: '' }

const FormCancelationReason = forwardRef(({ onUpdate, onCreate }, ref) => {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState(cancelationReasonForm)

  function open(preload = cancelationReasonForm) {
    setReason(preload)
    setOpened(true)
  }

  useImperativeHandle(ref, () => ({ open }))

  function close() {
    setReason(cancelationReasonForm)
    setOpened(false)
  }

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      if (reason?.id) {
        const { data } = await cancelationReasons.update(reason?.id, form)
        onUpdate(data)
        toast(`${form?.description} atualizado`, { type: 'success' })
      } else {
        const { data } = await cancelationReasons.create(form)
        onCreate(data)
        toast(`${form?.description} adicionado`, { type: 'success' })
      }
      close()
    } catch (e) {
      toast(e?.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }, [reason?.id, onCreate, onUpdate])

  if (!opened) return null

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={reason}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {
          ({
            errors, touched, isValid, dirty,
          }) => (
            <Form.ValidationForm>
              <Modal.Base className="active">
                <Modal.Overlay onClick={close} />
                <Modal.Container>
                  <Modal.Header>
                    <Modal.Close onClick={close} />
                    <Modal.Title>Motivo do cancelamento</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Modal.Content>
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-auto">
                          <Form.Group className={`${touched?.code && errors?.code ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="code">Código <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              disabled={reason?.id}
                              name="code"
                              autoFocus
                              id="code"
                              autoComplete="off"
                              placeholder="Código"
                            />
                            {
                              touched?.code && errors?.code
                                ? <Form.Hint>{errors.code}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column>
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              id="description"
                              autoComplete="off"
                              placeholder="Motivo para o cancelamento"
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint>{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Modal.Content>
                  </Modal.Body>
                  <Modal.Footer>
                    <Styles.Button
                      type="submit"
                      className="btn-primary"
                      disabled={!dirty || !isValid}
                    >
                      <i className="fas fa-save" />
                      &nbsp;Salvar
                    </Styles.Button>
                  </Modal.Footer>
                </Modal.Container>
              </Modal.Base>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
})

FormCancelationReason.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default FormCancelationReason
