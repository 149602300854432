import React from 'react'
import { Avatar, ErrorBox } from '@/components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default function AnimalsRow({ animals }) {
  if (!animals) return null

  return (
    <>
      {
        animals?.length
          ? (
            <table className="table text-small">
              <thead>
                <tr>
                  <th width="80px">#</th>
                  <th>Nome</th>
                  <th>Espécie/Raça</th>
                  <th width="1" className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  animals.map((animal) => (
                    <tr key={animal?.id}>
                      <td>{animal?.id}</td>
                      <td>
                        <Avatar avatarSize="sm" name={animal?.name} photoURL={animal?.photoURL} />
                        <span>&nbsp;{animal?.name}</span>
                      </td>
                      <td>
                        <small>
                          {animal?.category?.description} - {animal?.breed?.description}
                        </small>
                      </td>
                      <td className="text-right">
                        <Link
                          to={`/app/animals/${animal?.id}`}
                          className="btn btn-info btn-action btn-sm tooltip tooltip-left"
                          data-tooltip="Visualizar"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fas fa-paw fa-2x',
                title: 'Nenhum pet',
                message: 'Esse usuário não possui pets cadastrados',
              }}
            />
          )
      }
    </>
  )
}

AnimalsRow.propTypes = {
  animals: PropTypes.arrayOf(Object),
}

AnimalsRow.defaultProps = {
  animals: [],
}
