import React from 'react'
import PropTypes from 'prop-types'

function Row({ item }) {
  return <div>{item?.name} - {item?.uf}</div>
}

Row.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    uf: PropTypes.string.isRequired,
  }).isRequired,
}

export default Row
