import api from './api'

export default {
  list() {
    return api.get('/cancelation-reasons')
  },

  update(id, body) {
    return api.put(`/cancelation-reasons/${id}`, body)
  },

  create(body) {
    return api.post('/cancelation-reasons', body)
  },
}
