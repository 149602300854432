import styled from 'styled-components'
import { IMaskInput } from 'react-imask'
import { Field as FormikField, Formik as FormikBase, Form as FormikForm } from 'formik'

export const Group = styled.div.attrs(({ className }) => ({ className: `form-group ${className}` }))``

export const HasIconLeft = styled.div.attrs(({ className }) => ({ className: `has-icon-left ${className}` }))``

export const HasIconRight = styled.div.attrs(({ className }) => ({ className: `has-icon-right ${className}` }))``

export const Icon = styled.i.attrs(({ className }) => ({ className: `form-icon ${className}` }))``

export const Label = styled.label.attrs(({ className }) => ({ className: `form-label ${className}` }))``

export const Input = styled.input.attrs(({ className }) => ({ className: `form-input ${className}` }))``

export const Select = styled.select.attrs(({ className }) => ({ className: `form-select ${className}` }))``

export const Hint = styled.div.attrs(({ className }) => ({ className: `form-input-hint ${className}` }))``

export const InputMask = styled(IMaskInput).attrs(({ className }) => ({
  className: `form-input ${className}`,
  onChange: null,
}))``

// -- Validation --
export const Formik = styled(FormikBase)``

export const ValidationField = styled(FormikField).attrs(({ className }) => ({ className: `form-input ${className}` }))``

export const ValidationForm = styled(FormikForm)``
// -- End validation --
