import api from './api'

export default {
  current() {
    return api.get('/online-fair')
  },
  createOrUpdate(body) {
    return api.post('/online-fair', body)
  },
  animalFairs(params) {
    return api.get('/online-fair/animal-fairs', { params })
  },
  adoptions(params) {
    return api.get('/online-fair/adoptions', { params })
  },
}
