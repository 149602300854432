import {
  Card, Flexbox, Styles, Footer,
} from '@/components'
import useAuth from '@/hooks/useAuth'
import usePageTitle from '@/hooks/usePageTitle'

import React from 'react'
import { AppContainer, Logo, Overflow } from './styles'

function Home({ title }) {
  usePageTitle({ title })
  const auth = useAuth()

  return (
    <>
      <AppContainer>
        <Overflow />
        <Card.Base style={{ maxWidth: 350, height: 'auto' }}>
          <Card.Header>
            <Flexbox.Columns className="align-center">
              <Flexbox.Column className="col-auto">
                <Logo />
              </Flexbox.Column>
              <Flexbox.Column>
                Petis Gestor - Bem vindo
              </Flexbox.Column>
            </Flexbox.Columns>
          </Card.Header>
          <Card.Body className="text-center my-2">
            <div>
              Plataforma para os <b>DESENVOLVEDORES</b> e <b>GESTORES</b> da
              plataforma.
            </div>
            <div className="mt-2">
              Configurações e indicadores!
            </div>
          </Card.Body>
          <Card.Footer>
            <Styles.Link to={auth.token ? '/app' : '/login'} className="btn-block btn-primary">
              {auth.token ? 'Entrar' : 'Fazer login'}
            </Styles.Link>
          </Card.Footer>
        </Card.Base>
      </AppContainer>
      <Footer />
    </>
  )
}

export default Home
