import {
  ErrorBox, Filter, Flexbox, Form,
  ListManager, Mask, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import onlineFair from '@/services/api/onlineFair'
import React from 'react'
import Row from './Row'

function Adoptions({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, error, status,
  } = useAxiosRequest(onlineFair.adoptions, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="my-2 py-2 loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="my-2 py-2 flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <Flexbox.Columns className="align-center" style={{ marginTop: 10, marginBottom: 5 }}>
        <Flexbox.Column>
          <div className="h5">Adoções</div>
        </Flexbox.Column>
        <Flexbox.Column className="col-auto">
          <Filter
            initialValues={{
              'animal.name_cont': '',
              'donor.fullName_cont': '',
              'donor.documentNumber_eq': '',
              'newTutor.fullName_cont': '',
              'newTutor.documentNumber_eq': '',
              status_eq: '',
            }}
            title="Filtrar adoções"
            renderForm={({ setFieldValue }) => (
              <>
                <div className="divider text-center" data-content="Pet" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="animal.name_cont">Nome do pet</Form.Label>
                  <Form.ValidationField
                    name="['animal.name_cont']"
                    className="input-sm"
                    autoFocus
                    id="animal.name_cont"
                    autoComplete="none"
                    placeholder="Pet da silva"
                  />
                </Form.Group>
                <div className="divider text-center" data-content="Doador" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="donor.fullName_cont">Nome do doador</Form.Label>
                  <Form.ValidationField
                    name="['donor.fullName_cont']"
                    className="input-sm"
                    id="donor.fullName_cont"
                    autoComplete="none"
                    placeholder="Fulano da silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="donor.documentNumber_eq">Documento do doador</Form.Label>
                  <Form.ValidationField name="['donor.documentNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF ou CNPJ"
                          id="['donor.documentNumber_eq']"
                          autoComplete="none"
                          onAccept={(value) => {
                            setFieldValue("['donor.documentNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <div className="divider text-center" data-content="Novo tutor" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="newTutor.fullName_cont">Nome do novo tutor</Form.Label>
                  <Form.ValidationField
                    name="['newTutor.fullName_cont']"
                    className="input-sm"
                    id="newTutor.fullName_cont"
                    autoComplete="none"
                    placeholder="Fulano da silva"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="newTutor.documentNumber_eq">Documento do novo tutor</Form.Label>
                  <Form.ValidationField name="['newTutor.documentNumber_eq']">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF ou CNPJ"
                          id="['newTutor.documentNumber_eq']"
                          autoComplete="none"
                          onAccept={(value) => {
                            setFieldValue("['newTutor.documentNumber_eq']", value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <div className="divider text-center" data-content="Adoção" />
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="status_eq">Status da adoção</Form.Label>
                  <Form.ValidationField name="status_eq">
                    {
                      ({ field }) => (
                        <Form.Select
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          id="status_eq"
                          className="select-sm"
                          placeholder="Status da adoção"
                        >
                          <option value="">Todos os status</option>
                          <option value="requested">Solicitada</option>
                          <option value="confirmed">Confirmada</option>
                          <option value="rejected">Rejeitada</option>
                          <option value="canceled">Cancelada</option>
                        </Form.Select>
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small">
            <thead>
              <tr>
                <th width={50}>#</th>
                <th>Pet</th>
                <th>Doador</th>
                <th>Novo tutor</th>
                <th width={50}>Status</th>
                <th className="text-center">Adotado em</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((adoption) => <Row adoption={adoption} key={adoption?.id} />)
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                {Boolean(data?.rows) && <Paginator paginatedList={data} />}
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Adoptions
