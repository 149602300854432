import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import * as Styles from './styles'

function Box({
  title, value, icon, className, to,
}) {
  const history = useHistory()

  return (
    <Styles.Base onClick={() => Boolean(to) && history.push(to)} className={className}>
      <Styles.Content>
        <Styles.Container>
          <Styles.Title>{title}</Styles.Title>
          <Styles.Body>
            <Styles.Value>{value}</Styles.Value>
          </Styles.Body>
        </Styles.Container>
        <Styles.Icon>
          <i className={icon} />
        </Styles.Icon>
      </Styles.Content>
    </Styles.Base>
  )
}

Box.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
}

Box.defaultProps = {
  className: '',
  to: '',
  value: null,
}

export default Box
