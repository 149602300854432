import { Avatar, Flexbox } from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import MapLocation from './components/MapLocation'
import mapEvents from './mapEvents'
import { ImageEvent } from './styles'

function TimelineItem({ event }) {
  const mappedEvent = mapEvents(event?.status)

  if (!mappedEvent) return null

  return (
    <>
      <div className="timeline-item">
        <div className="timeline-left">
          <span
            className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
            data-tooltip={singularStatusMapLabel[event.status]}
          >
            <i className={`${mappedEvent?.icon}`} />
          </span>
        </div>
        <div className="timeline-content">
          <div className="tile">
            <div className="tile-content">
              <p className="tile-subtitle">
                <span className="text-bold">
                  {dateMask(event?.createdAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(event?.createdAt).toLocaleTimeString()}
                </small>
              </p>
              <p className="tile-title pre">{mappedEvent?.title}</p>
              {
                Boolean(event?.description) && (
                  <p className="tile-title">
                    <cite className="pre">{event?.description}</cite>
                  </p>
                )
              }
              {
                event?.correctionRequired?.length > 0 && (
                  <div className="mb-2 pb-2">
                    <div>
                      <small>Itens definidos como obrigatórios à correção: </small>
                    </div>
                    {
                       event?.correctionRequired?.map((item) => (
                        <div>
                          <small>
                            - {item}
                          </small>
                        </div>
                       ))
                      }
                  </div>
                )
              }
              {
                (event?.photoURL || event?.location) && (
                  <div className="tile-title mb-2 pb-2">
                    <Flexbox.Columns>
                      {
                        Boolean(event?.photoURL) && (
                          <Flexbox.Column className="col-auto">
                            <a href={event?.photoURL} target="_blank" rel="noreferrer" className="c-hand">
                              <ImageEvent src={event?.photoURL} alt={`Imagem do evento ${event?.id}`} />
                            </a>
                          </Flexbox.Column>
                        )
                      }
                      {
                        Boolean(event?.location) && Boolean(event?.photoURL) && (
                          <Flexbox.Column className="col-auto flex-centered">
                            <i className="fas fa-arrow-right" />
                          </Flexbox.Column>
                        )
                      }
                      {
                        Boolean(event?.location) && (
                          <Flexbox.Column className="col-auto">
                            <MapLocation
                              location={event?.location}
                            />
                          </Flexbox.Column>
                        )
                      }

                    </Flexbox.Columns>
                  </div>
                )
              }
              {
                Boolean(event?.selfieURL) && (
                  <>
                    <div>Selfie com documento:</div>
                    <div className="mb-2 pb-2">
                      <a href={event?.selfieURL} target="_blank" rel="noreferrer" className="c-hand">
                        <img src={event?.selfieURL} style={{ maxHeight: 150 }} className="img-responsive" alt="selfie" />
                      </a>
                    </div>
                  </>
                )
              }
              <Flexbox.Columns className="align-center col-gapless">
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="sm"
                    name={event?.author?.fullName}
                    photoURL={event?.author?.photoURL}
                    alt="Imagem de usuário"
                  />
                </Flexbox.Column>
                <Flexbox.Column className="text-small ml-2">{event?.author?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TimelineItem.propTypes = {
  event: PropTypes.objectOf(Object).isRequired,
}

export default TimelineItem
