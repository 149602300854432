import styled from 'styled-components'

export const Chip = styled.div.attrs({
  className: 'chip',
})``

export const Remove = styled.a.attrs({
  className: 'btn btn-clear',
  ariaLabel: 'Close',
  role: 'button',
})``
