import React, { useCallback, useRef, useState } from 'react'
import useQuery from '@/hooks/useQuery'
import { useParams } from 'react-router-dom'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import breeds from '@/services/api/breeds'
import {
  ErrorBox, PageHeader, Paginator, Styles,
} from '@/components'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { ANIMAL_SIZE } from '@/lib/enums'
import usePageTitle from '@/hooks/usePageTitle'
import Form from '../components/Form'

function List({ title }) {
  usePageTitle({ title })
  const formRef = useRef(null)
  const { categoryId } = useParams()
  const [deleting, setDeleting] = useState(null)
  const query = useQuery()
  const {
    data, setData, status, error,
  } = useAxiosRequest(breeds.list, categoryId, query)

  const onCreate = useCallback((breed) => {
    setData({ ...data, rows: [breed, ...data?.rows] })
  }, [data, setData])

  const onUpdate = useCallback((breed) => {
    const rows = data?.rows?.map((item) => (item?.id === breed?.id ? breed : item))
    setData({ ...data, rows })
  }, [data, setData])

  const onDelete = useCallback(async (breed) => {
    try {
      setDeleting(breed?.id)
      await breeds.delete(categoryId, breed?.id)
      const rows = data?.rows?.filter((item) => item?.id !== breed?.id)
      setData({ ...data, rows })
      toast(`${breed?.description} excluído`, { type: 'success' })
    } catch (e) {
      toast(e.message, { type: 'error' })
    } finally {
      setDeleting(null)
    }
  }, [categoryId, data, setData])

  const checkDelete = useCallback(async (breed) => {
    confirmAlert({
      title: 'Excluir raça',
      message: `Você está certo que deseja excluir ${breed?.description}?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: () => onDelete(breed),
        },
      ],
    })
  }, [onDelete])

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de raças"
        icon="fas fa-paw"
        rightAction={() => (
          <Styles.Button onClick={() => formRef.current?.open()} className="btn-primary">
            <i className="fas fa-plus" />
            &nbsp;Adicionar
          </Styles.Button>
        )}
      />
      <table className="table text-small table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Descrição</th>
            <th>Criação</th>
            <th>Alteração</th>
            <th>Ordem</th>
            <th>Tamanho</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((item) => (
              <tr key={`row-${item.id}`}>
                <td>{item?.id}</td>
                <td>{item?.description}</td>
                <td>{new Date(item?.createdAt).toLocaleString()}</td>
                <td>{new Date(item?.updatedAt).toLocaleString()}</td>
                <td>{item?.order}</td>
                <td>{ANIMAL_SIZE.t(item?.size)}</td>
                <td className="text-right" width="100">
                  <button
                    disabled={item?.id === deleting}
                    onClick={() => formRef.current?.open(item)}
                    type="button"
                    className="btn btn-primary btn-action btn-sm tooltip tooltip-left"
                    data-tooltip="Editar"
                  >
                    <i className="fas fa-pen" />
                  </button>
                  <button
                    disabled={item?.id === deleting}
                    onClick={() => checkDelete(item)}
                    type="button"
                    className="btn btn-error btn-action btn-sm tooltip tooltip-left ml-1"
                    data-tooltip="Excluir"
                  >
                    <i className="fas fa-trash" />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <div className="float-right my-2">
        <Paginator paginatedList={data} />
      </div>
      <Form categoryId={categoryId} ref={formRef} onCreate={onCreate} onUpdate={onUpdate} />
    </Styles.Container>
  )
}

export default List
