import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import mapStatus from './mapStatus'

function Row({ item }) {
  const mappedItem = mapStatus(item.status)

  return (
    <tr key={item.status}>
      <td>
        <i className={`s-rounded p-1 mr-2 ${mappedItem.icon}`} />&nbsp;
        {mappedItem.label}
      </td>
      <td className="text-center">
        {item.count}
      </td>
      <td className="text-right">
        <Link
          className="btn btn-action btn-sm btn-primary"
          to={`/app/castrations/status/${item.status}`}
        >
          <i className="fas fa-folder-open" />
        </Link>
      </td>
    </tr>
  )
}

Row.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
}

export default Row
