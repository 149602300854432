import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'

export const Container = styled.div.attrs({
  className: 'container',
})``

export const Divider = styled.div.attrs(({ className }) => ({
  className: `divider text-center ${className}`,
}))``

export const Toast = styled.div.attrs(({ className }) => ({
  className: `toast ${className}`,
}))``

export const Loading = styled.div.attrs(({ className }) => ({
  className: `loading ${className}`,
}))``

// -- Clickables --
export const Button = styled.button.attrs(({ className }) => ({
  className: `btn ${className}`,
}))``

export const A = styled.a.attrs(({ className }) => ({
  className: `btn ${className}`,
}))``

export const Link = styled(ReactRouterLink).attrs(({ className }) => ({
  className: `btn ${className}`,
}))``
// -- End Clickables --
