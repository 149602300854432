import api from './api'

export default {
  show(id) {
    return api.get(`/animal-fairs/${id}`)
  },
  complaints(id, params) {
    return api.get(`/animal-fairs/${id}/complaints`, { params })
  },
  clearComplaints(id) {
    return api.delete(`/animal-fairs/${id}/complaints/clear`)
  },
  delete(id) {
    return api.delete(`/animal-fairs/${id}`)
  },
}
