import React from 'react'
import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import AnimalsRow from './AnimalsRow'

export default function AnimalsCard({ lastAnimals }) {
  const { pathname } = useLocation()

  if (!lastAnimals) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw fa-lg" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Pets do usuário</div>
            <small>Últimos pets cadastrados pelo usuário</small>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 400, overflowY: 'auto' }}>
        <AnimalsRow animals={lastAnimals} />
      </Card.Body>
      <Card.Footer className="text-right">
        <Link to={`${pathname}/animals`} className="btn btn-sm btn-primary">
          <i className="fas fa-search-plus" />
          &nbsp;Ver todos
        </Link>
      </Card.Footer>
    </Card.Base>
  )
}

AnimalsCard.propTypes = {
  lastAnimals: PropTypes.arrayOf(Object),
}

AnimalsCard.defaultProps = {
  lastAnimals: [],
}
