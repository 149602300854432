import userContext from '@/contexts/userContext'
import userReducer, { INITIAL_STATE } from '@/reducers/user'
import React, { useReducer } from 'react'
import { useRouteMatch } from 'react-router-dom'
import UsersList from '@/views/app/users/List'
import UsersShow from '@/views/app/users/Show'
import AnimalsList from '@/views/app/users/Show/components/AnimalsCard/AnimalsList'
import CastrationsList from '@/views/app/users/Show/components/CastrationsCard/CastrationsList'
import DonationsList from '@/views/app/users/Show/components/DonationsCard/DonationsList'
import AdoptionsList from '@/views/app/users/Show/components/AdoptionsCard/AdoptionsList'
import PrivateRoute from './components/PrivateRoute'

function UsersRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(userReducer, INITIAL_STATE)

  return (
    <div>
      <PrivateRoute exact path={`${path}/users`}>
        <UsersList title="Petis Owner - Usuários" />
      </PrivateRoute>
      <userContext.Provider value={{ state, dispatch }}>
        <PrivateRoute path={`${path}/users/:id`}>
          <UsersShow title="Petis Owner - Usuário" />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/:id/animals`}>
          <AnimalsList title="Petis Owner - Animais do usuários" />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/:id/castrations`}>
          <CastrationsList title="Petis Owner - Castrações do usuários" />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/:id/donations`}>
          <DonationsList title="Petis Owner - Doações do usuários" />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/:id/adoptions`}>
          <AdoptionsList title="Petis Owner - Animais do usuários" />
        </PrivateRoute>
      </userContext.Provider>
    </div>
  )
}

export default UsersRoutes
