import mask from 'slim-mask-js'

function formatCpf(cpf) {
  return mask(cpf, '###.###.###-##')
}
function formatCnpj(cnpj) {
  return mask(cnpj, '##.###.###/####-##')
}

export default function documentNumberMask(str) {
  if (!str) return ''
  const string = str.replace(/D/g, '')
  if (string.length <= 11) return formatCpf(string)

  return formatCnpj(string)
}
