import React from 'react'
import { ListManager, Paginator } from '@/components'
import { useHistory, useParams } from 'react-router-dom'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import people from '@/services/api/people'
import useQuery from '@/hooks/useQuery'
import usePageTitle from '@/hooks/usePageTitle'
import DonationsRow from '../DonationsRow'

export default function DonationsList({ title }) {
  usePageTitle({ title })

  const { id } = useParams()
  const history = useHistory()
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(people.donations, id, query)

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Doações do usuário</div>
          <small>
            {data?.countCastrations === 1 ? '1 doação' : `${data?.countDonations} doações`}
          </small>
        </div>
        <div className="modal-body">
          <div className="content">
            <ListManager loading={loading} count={data?.count || 0} error={error}>
              <cite>Lista de todas as doações do usuário</cite>
              <div className="divider" />
              <DonationsRow donations={data?.rows} key={data?.rows?.id} />
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </ListManager>
          </div>
        </div>
      </div>
    </div>
  )
}
