/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Flexbox, Form, Modal } from '@/components'
import yup from '@/lib/yupPt'
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react'
import PropTypes, { func } from 'prop-types'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import categoriesService from '@/services/api/categories'
import vaccineModelsService from '@/services/api/vaccineModels'
import { Field, getIn, useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { VACCINE_TYPE } from '@/lib/enums'

const schema = yup.object().shape({
  description: yup.string().required(),
  requiredObs: yup.boolean(),
  periodic: yup.boolean(),
  active: yup.boolean(),
  info: yup.mixed(),
  type: yup.string().required(),
  cover: yup.string().url(),
  order: yup.number().min(0),
  daysUntilNextDose: yup.number().min(0),
  daysUntilFirstDose: yup.number().min(0),
  nextVaccineModel: yup.mixed(),
  category: yup.object().shape({ id: yup.mixed().required() }).required(),
})

const emptyForm = () => ({
  id: null,
  description: '',
  requiredObs: false,
  periodic: false,
  cover: '',
  type: '',
  info: '',
  order: 0,
  active: true,
  daysUntilNextDose: 0,
  daysUntilFirstDose: 0,
  nextVaccineModel: { id: null },
  category: { id: null },
})

const FormVaccine = forwardRef(({ onUpdate, onCreate }, ref) => {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const [form, setForm] = useState(emptyForm())
  const { data: categories } = useAxiosRequest(categoriesService.all)
  const { data: vaccineModels } = useAxiosRequest(vaccineModelsService.all, form?.category?.id)

  function open(preload) {
    if (preload) setForm(preload)
    setOpened(true)
  }

  useImperativeHandle(ref, () => ({ open }))

  function close() {
    setForm(emptyForm())
    setOpened(false)
  }

  async function create(values) {
    try {
      setLoading(true)
      const { id, ...body } = values
      const { data } = await vaccineModelsService.create(body)
      toast.success('Modelo de vacina criado')
      onCreate(data)
      close()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  async function update(values) {
    try {
      setLoading(true)
      const { data } = await vaccineModelsService.update(values.id, values)
      toast.success('Modelo de vacina atualizado')
      onUpdate(data)
      close()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  function onSubmit(values) {
    if (values.id) return update(values)
    return create(values)
  }

  if (!opened) return null

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={form}
        validationSchema={schema}
        validateOnMount
        onSubmit={onSubmit}
      >
        {
          ({
            errors, touched, isValid, values,
          }) => (
            <Form.ValidationForm>
              <Modal.Base className="active">
                <Modal.Overlay onClick={close} />
                <Modal.Container>
                  <Modal.Header>
                    <Modal.Close onClick={close} />
                    <Modal.Title>Modelo de vacina</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Modal.Content>
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              id="description"
                              autoFocus
                              autoComplete="off"
                              placeholder="Vacina de gripe..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint>{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-4 col-sm-12 pt-2">
                          <Form.Group className={`${touched?.category?.id && errors?.category?.id ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="category.id">Espécie do pet <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField name="category.id">
                              {
                                ({ field }) => {
                                  const context = useFormikContext()
                                  useEffect(() => {
                                    setForm({ ...form, category: values.category })
                                  }, [context?.values?.category?.id])
                                  return (
                                    <Form.Select
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...field}
                                      id="category.id"
                                      disabled={Boolean(values.id)}
                                      placeholder="Espécie do pet"
                                    >
                                      <option value="">Escolha a espécie</option>
                                      {
                                        (categories || []).map((category) => (
                                          <option
                                            key={category.id}
                                            value={category.id}
                                          >
                                            {category.description}
                                          </option>
                                        ))
                                      }
                                    </Form.Select>
                                  )
                                }
                              }
                            </Form.ValidationField>
                            {
                              touched?.category?.id && errors?.category?.id
                                ? <Form.Hint>{errors.category?.id}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-8 col-sm-12 pt-2">
                          <Form.Group className={`${touched?.nextVaccineModel?.id && errors?.nextVaccineModel?.id ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="nextVaccineModel.id">Próxima vacina</Form.Label>
                            <Form.ValidationField name="nextVaccineModel.id">
                              {
                                ({ field }) => (
                                  <Form.Select
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    id="nextVaccineModel.id"
                                    placeholder="Espécie do pet"
                                  >
                                    <option value="">Escolha a vacina</option>
                                    {
                                      (vaccineModels || []).map((nextVaccineModel) => (
                                        <option
                                          key={nextVaccineModel.id}
                                          value={nextVaccineModel.id}
                                        >
                                          ({nextVaccineModel.category.description})
                                          {nextVaccineModel.description} {
                                            nextVaccineModel.id === values.id ? '(Esta)' : ''
                                          }
                                        </option>
                                      ))
                                    }
                                  </Form.Select>
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.nextVaccineModel?.id && errors?.nextVaccineModel?.id
                                ? <Form.Hint>{errors.nextVaccineModel?.id}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-8 col-sm-12">
                          <Form.Group className={`${touched?.type && errors?.type ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="type">Tipo de vacina</Form.Label>
                            <Form.ValidationField name="type">
                              {
                                ({ field }) => (
                                  <Form.Select
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    id="type"
                                    placeholder="Tipo da vacina"
                                  >
                                    <option value="">Escolha o tipo</option>
                                    {
                                      Object.values(VACCINE_TYPE.enum).map((type) => (
                                        <option
                                          key={type}
                                          value={type}
                                        >
                                          {VACCINE_TYPE.t(type)}
                                        </option>
                                      ))
                                    }
                                  </Form.Select>
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.type && errors?.type
                                ? <Form.Hint>{errors.type}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-4 col-sm-12">
                          <Form.Group className={`${touched?.order && errors?.order ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="order">Ordem</Form.Label>
                            <Form.ValidationField
                              name="order"
                              id="order"
                              type="number"
                            />
                            {
                              touched?.order && errors?.order
                                ? <Form.Hint>{errors.order}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6 col-sm-12">
                          <Form.Group className={`${touched?.daysUntilFirstDose && errors?.daysUntilFirstDose ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="daysUntilFirstDose">Dias para primeira dose</Form.Label>
                            <Form.ValidationField
                              name="daysUntilFirstDose"
                              id="daysUntilFirstDose"
                              type="number"
                            />
                            {
                              touched?.daysUntilFirstDose && errors?.daysUntilFirstDose
                                ? <Form.Hint>{errors.daysUntilFirstDose}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6 col-sm-12">
                          <Form.Group className={`${touched?.daysUntilNextDose && errors?.daysUntilNextDose ? 'has-error' : ''}`}>
                            <Form.Label className="label-sm" htmlFor="daysUntilNextDose">Dias para a próxima dose</Form.Label>
                            <Form.ValidationField
                              name="daysUntilNextDose"
                              id="daysUntilNextDose"
                              type="number"
                            />
                            {
                              touched?.daysUntilNextDose && errors?.daysUntilNextDose
                                ? <Form.Hint>{errors.daysUntilNextDose}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.info && errors?.info ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="info">Informativo da vacina</Form.Label>
                            <Field
                              name="info"
                              id="info"
                            >
                              {
                                ({ field }) => (
                                  <textarea
                                    name="info"
                                    className="form-input"
                                    rows={2}
                                    placeholder="Escreva o conteúdo da publicação..."
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                  />
                                )
                              }
                            </Field>
                            {
                              touched?.info && errors?.info
                                ? <Form.Hint className="text-error">{errors?.info}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                        <div className="mt-2 column col-12">
                          <div className="divider " />
                        </div>
                        <Flexbox.Column className="col-12">
                          <div className="form-group">
                            <label className="form-switch">
                              <Field type="checkbox" name="active" />
                              <i className="form-icon" />
                              Esta vacina está ativa
                            </label>
                          </div>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-12">
                          <div className="form-group">
                            <label className="form-switch">
                              <Field type="checkbox" name="periodic" />
                              <i className="form-icon" />
                              Esta vacina é periódica
                            </label>
                          </div>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-12">
                          <div className="form-group">
                            <label className="form-switch">
                              <Field type="checkbox" name="requiredObs" />
                              <i className="form-icon" />
                              Esta vacina obriga informar a observação
                            </label>
                          </div>
                        </Flexbox.Column>
                        <div className="column col-12 mt-2">
                          <div className="divider" />
                        </div>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.cover && errors?.cover ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="cover">Adicionar uma imagem para esta vacina</Form.Label>
                            <Form.FileUploadField
                              name="cover"
                              id="cover"
                              type="number"
                            />
                            {
                              touched?.cover && errors?.cover
                                ? <Form.Hint>{errors.cover}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </Modal.Content>
                  </Modal.Body>
                  <Modal.Footer>
                    <button disabled={!isValid || loading} type="submit" className={`btn btn-primary ${loading && 'loading'}`}>
                      <i className="fas fa-check" />
                      &nbsp;Salvar
                    </button>
                  </Modal.Footer>
                </Modal.Container>
              </Modal.Base>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
})

FormVaccine.propTypes = {
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
}

FormVaccine.defaultProps = {
  onUpdate: () => null,
  onCreate: () => null,
}

export default FormVaccine
