import {
  ErrorBox, Filter, Form, ListManager,
  PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { NEWS_DISTRIBUTION } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import news from '@/services/api/news'
import React from 'react'
import { Link } from 'react-router-dom'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, error, status,
  } = useAxiosRequest(news.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-newspaper"
        title="News"
        rightAction={() => (
          <>
            <Filter
              initialValues={{ title_cont: '', data_cont: '' }}
              title="Filtrar news"
              renderForm={() => (
                <>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="title_cont">Título</Form.Label>
                    <Form.ValidationField
                      name="title_cont"
                      className="input-sm"
                      autoFocus
                      id="title_cont"
                      autoComplete="none"
                      placeholder="Título..."
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="data_cont">Conteúdo</Form.Label>
                    <Form.ValidationField
                      name="data_cont"
                      className="input-sm"
                      autoFocus
                      id="data_cont"
                      autoComplete="none"
                      placeholder="Conteúdo..."
                    />
                  </Form.Group>
                </>
              )}
            />
            <Styles.Link to="/app/news/create" className="btn btn-primary">
              <i className="fas fa-plus" />
              &nbsp;Adicionar
            </Styles.Link>
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Título</th>
                <th>Distribuição</th>
                <th>Publicado em</th>
                <th width={50} className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                rows.map((item) => (
                  <tr key={`show-button-${item.id}`}>
                    <td>{item?.id}</td>
                    <td>{item?.title}</td>
                    <td>{NEWS_DISTRIBUTION.t(item?.distribution)}</td>
                    <td>{dateMask(item?.createdAt, { minute: '2-digit', hour: '2-digit' })}</td>
                    <td className="text-right">
                      <Link
                        to={`/app/news/${item?.id}/edit`}
                        className="btn btn-primary btn-action btn-sm tooltip tooltip-left"
                        data-tooltip="Editar"
                      >
                        <i className="fas fa-pen" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default List
