/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Avatar, Card, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import phoneNumberMask from '@/lib/masks/phoneNumber'
import { Link } from 'react-router-dom'

export default function TutorCard({ person }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user fa-lg" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="h5">Tutor</div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="lg"
              name={person?.fullName}
              photoURL={person?.photoURL}
              alt="Imagem de usuário"
            />
          </Flexbox.Column>
          <Flexbox.Column className="text-ellipsis">
            <div className="h5 text-ellipsis">{person?.fullName}</div>
            <div className="text-ellipsis">{person?.email}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Telefone:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {phoneNumberMask(person?.phoneNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Documento:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {documentNumberMask(person?.documentNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Administrador:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {person?.isOwner ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>Criado em:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {dateMask(person?.createdAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="py-1 text-ellipsis align-center">
          <Flexbox.Column>
            <b>E-mail verificado:</b>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis">
            {person?.emailVerified ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
      <Card.Footer>
        <div className="text-right">
          <Link
            to={`/app/users/${person?.id}`}
            className="btn btn-primary btn-sm"
          >
            <i className="fas fa-eye" />
            &nbsp;Exibir
          </Link>
        </div>
      </Card.Footer>
    </Card.Base>
  )
}

TutorCard.propTypes = {
  person: PropTypes.objectOf(Object).isRequired,
}
