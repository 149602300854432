import React from 'react'
import authContext from '../../contexts/authContext'
import useProvideAuth from '../../hooks/useProvideAuth'

// eslint-disable-next-line react/prop-types
export default function ProvideAuth({ children }) {
  const auth = useProvideAuth()

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}
