/* eslint-disable react/jsx-props-no-spreading */
import { Filter, Form } from '@/components'
import React from 'react'

function FilterCastrations() {
  return (
    <Filter
      initialValues={{
        createdAt_gteq: null,
        createdAt_lteq: null,
        startedAt_gteq: null,
        startedAt_lteq: null,
        performedAt_gteq: null,
        performedAt_lteq: null,
        'animal.categoryId_eq': '',
        'animal.gender_eq': '',
      }}
      title="Filtrar castrações"
      renderForm={({ values, setFieldValue }) => (
        <>
          <div className="divider text-center" data-content="Períodos" />
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="createdAt_gteq">Solicitadas a partir de</Form.Label>
            <Form.DatePickerField
              placeholderText="Solicitadas a partir de"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('createdAt_gteq', value)}
              selected={values.createdAt_gteq}
              isClearable
              name="createdAt_gteq"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="createdAt_lteq">Solicitadas até</Form.Label>
            <Form.DatePickerField
              placeholderText="Solicitadas até"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('createdAt_lteq', value)}
              selected={values.createdAt_lteq}
              isClearable
              name="createdAt_lteq"
            />
          </Form.Group>
          <div className="divider" />
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="startedAt_gteq">Iniciadas a partir de</Form.Label>
            <Form.DatePickerField
              placeholderText="Iniciadas a partir de"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('startedAt_gteq', value)}
              selected={values.startedAt_gteq}
              isClearable
              name="startedAt_gteq"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="startedAt_lteq">Iniciadas até</Form.Label>
            <Form.DatePickerField
              placeholderText="Iniciadas até"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('startedAt_lteq', value)}
              selected={values.startedAt_lteq}
              isClearable
              name="startedAt_lteq"
            />
          </Form.Group>
          <div className="divider" />
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="performedAt_gteq">Atendimento a partir de</Form.Label>
            <Form.DatePickerField
              placeholderText="Atendimento a partir de"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('performedAt_gteq', value)}
              selected={values.performedAt_gteq}
              isClearable
              name="performedAt_gteq"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="performedAt_lteq">Atendimento até</Form.Label>
            <Form.DatePickerField
              placeholderText="Atendimento até"
              dateFormat="dd/MM/yyyy"
              className="form-input input-sm"
              onChange={(value) => setFieldValue('performedAt_lteq', value)}
              selected={values.performedAt_lteq}
              isClearable
              name="performedAt_lteq"
            />
          </Form.Group>
          <div className="divider text-center" data-content="Animal" />
          <Form.Group>
            <Form.Label htmlFor="animal.categoryId_eq">Espécie do pet</Form.Label>
            <Form.ValidationField name="['animal.categoryId_eq']">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animal.categoryId_eq"
                    className="select-sm"
                    placeholder="Espécie do pet"
                  >
                    <option value="">Todos</option>
                    <option value="1">Cachorro</option>
                    <option value="2">Gato</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="gender_eq">Gênero do pet</Form.Label>
            <Form.ValidationField name="['animal.gender_eq']">
              {
                ({ field }) => (
                  <Form.Select
                    {...field}
                    id="gender_eq"
                    className="select-sm"
                    placeholder="Gênero do pet"
                  >
                    <option value="">Todos</option>
                    <option value="male">Macho</option>
                    <option value="female">Fêmea</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
        </>
      )}
    />
  )
}

export default FilterCastrations
