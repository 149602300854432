import { Form, Modal, Styles } from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import resetEmails from '@/services/api/resetEmails'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  rejectionReason: yup.string().required(),
})
function Reject({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const params = useParams()
  const history = useHistory()

  function close() {
    history.goBack()
  }

  async function onSubmit(form) {
    try {
      setLoading(true)
      await resetEmails.reject(params?.id, form)
      toast('Solicitação rejeitada', { type: 'success' })
      setLoading(false)
      history.replace('/app/reset-emails')
    } catch (e) {
      toast(e?.message, { type: 'error' })
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ rejectionReason: '' }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {
          ({
            errors, touched, isValid, dirty,
          }) => (
            <Form.ValidationForm>
              <Modal.Base className="active">
                <Modal.Overlay onClick={close} />
                <Modal.Container>
                  <Modal.Header>
                    <Modal.Close onClick={close} />
                    <Modal.Title>Motivo do cancelamento</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Modal.Content>
                      <Form.Group className={`${touched?.rejectionReason && errors?.rejectionReason ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="rejectionReason">Motivo da rejeição <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="rejectionReason"
                          autoFocus
                          id="rejectionReason"
                          className="form-input"
                          forwardedAs="textarea"
                          placeholder="Motivo para a rejeição"
                        />
                        {
                          touched?.rejectionReason && errors?.rejectionReason
                            ? <Form.Hint>{errors.rejectionReason}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Modal.Content>
                  </Modal.Body>
                  <Modal.Footer>
                    <Styles.Button
                      type="submit"
                      className="btn-primary"
                      disabled={!dirty || !isValid}
                    >
                      <i className="fas fa-ban" />
                      &nbsp;Rejeitar solicitação
                    </Styles.Button>
                  </Modal.Footer>
                </Modal.Container>
              </Modal.Base>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Reject
