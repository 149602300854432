import {
  Filter,
  Form,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import vaccineModels from '@/services/api/vaccineModels'
import React, { useRef } from 'react'
import { VACCINE_TYPE } from '@/lib/enums'
import FormVaccine from '../components/Form'
import Row from '../components/Row'

function List({ title }) {
  const formRef = useRef()
  usePageTitle({ title })

  const query = useQuery()
  const {
    data, loading, error, refreshData,
  } = useAxiosRequest(vaccineModels.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-syringe"
        title="Modelos de vacina"
        subtitle="Configuração para as vacinações"
        rightAction={() => (
          <div className="d-flex">
            <Filter
              initialValues={{
                categoryId_eq: '',
                type_eq: '',
              }}
              title="Filtrar castrações"
              renderForm={() => (
                <>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="categoryId_eq">Espécie</Form.Label>
                    <Form.ValidationField name="categoryId_eq">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            id="categoryId_eq"
                            className="select-sm"
                            placeholder="Espécie do pet"
                          >
                            <option value="">Todos</option>
                            <option value="1">Cachorro</option>
                            <option value="2">Gato</option>
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="type_eq">Tipo</Form.Label>
                    <Form.ValidationField name="type_eq">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            id="type_eq"
                            className="select-sm"
                            placeholder="Tipo da vacina"
                          >
                            <option value="">Todos</option>
                            {
                              Object.values(VACCINE_TYPE.enum).map((type) => (
                                <option key={type} value={type}>{VACCINE_TYPE.t(type)}</option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                </>
              )}
            />
            <button type="button" onClick={() => formRef.current?.open()} className="btn btn-primary">
              <i className="fas fa-plus" />
              &nbsp;Adicionar
            </button>
          </div>
        )}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Descrição</th>
                    <th>Periódica?</th>
                    <th>Espécie</th>
                    <th>Ordem</th>
                    <th>Ativa?</th>
                    <th className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((vaccineModel) => (
                      <Row
                        onPresUpdate={formRef.current?.open}
                        key={vaccineModel.id}
                        vaccineModel={vaccineModel}
                        onDestroy={refreshData}
                      />
                    ))
                  }
                </tbody>
              </table>

              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            </>
          )
        }
      </ListManager>
      <FormVaccine onCreate={refreshData} onUpdate={refreshData} ref={formRef} />
    </Styles.Container>
  )
}

export default List
