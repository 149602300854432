import api from './api'

export default {
  list(params = {}) {
    return api.get('/animals', { params })
  },
  show(id) {
    return api.get(`/animals/${id}`)
  },
}
