import styled from 'styled-components'

export const Base = styled.div.attrs({
  className: 'modal',
})``

export const Overlay = styled.div.attrs({
  className: 'modal-overlay',
})``

export const Container = styled.div.attrs({
  className: 'modal-container',
})``

export const Header = styled.div.attrs({
  className: 'modal-header',
})``

export const Close = styled.a.attrs({
  className: 'btn btn-clear float-right',
  ariaLabel: 'Close',
})``

export const Title = styled.div.attrs({
  className: 'modal-title h5',
})``

export const Body = styled.div.attrs({
  className: 'modal-body',
})``

export const Content = styled.div.attrs({
  className: 'content',
})``

export const Footer = styled.div.attrs({
  className: 'modal-footer',
})``
