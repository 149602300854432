/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Flexbox, Form, Mask, Modal,
} from '@/components'
import { Field } from 'formik'
import people from '@/services/api/people'
import { toast } from 'react-toastify'
import moment from 'moment'

export default function EditUser({ person, onUpdate }) {
  const [updating, setUpdating] = useState(false)
  const [active, setActive] = useState(false)

  function close(resetForm) {
    setActive(false)
    resetForm()
  }

  async function onSubmit(values) {
    try {
      setUpdating(true)

      const currentDateTime = moment()
      let dateToSave = null

      if (values.birthdayDate) {
        const formattedBirthdayDate = moment(values.birthdayDate, 'DD/MM/YYYY')
        if (!formattedBirthdayDate.isValid()) {
          throw new Error('Data inválida')
        }
        dateToSave = `${formattedBirthdayDate.format('YYYY-MM-DD')} ${currentDateTime.format('HH:mm:ss')}`
      }

      const { data } = await people.update(person.id, {
        ...values,
        birthdayDate: dateToSave,
      })

      toast.success('Usuário atualizado')
      setActive(false)
      onUpdate(data)
    } catch (error) {
      toast.error(error?.message || 'Erro ao atualizar usuário')
    } finally {
      setUpdating(false)
    }
  }

  if (!person) return null

  return (
    <div>
      <div className="text-right">
        <a onClick={() => setActive(true)} aria-hidden="true" className="btn btn-primary btn-sm">
          <i className="fas fa-edit" />
          &nbsp;Editar
        </a>
      </div>
      <fieldset disabled={updating}>
        <Form.Formik
          initialValues={{
            fullName: person.fullName,
            email: person.email,
            phoneNumber: person.phoneNumber,
            documentNumber: person.documentNumber,
            isOwner: person.isOwner,
birthdayDate: person.birthdayDate ? moment(person.birthdayDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : '',
}}
          onSubmit={onSubmit}
        >
          {
            ({ resetForm, setFieldValue }) => (
              <Form.ValidationForm>
                <Modal.Base className={active ? 'active' : ''}>
                  <Modal.Overlay onClick={() => close(resetForm)} />
                  <Modal.Container>
                    <Modal.Header>
                      <Modal.Close onClick={() => close(resetForm)} />
                      <Modal.Title>Editar usuário</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Modal.Content>
                        <Flexbox.Columns>
                          <Flexbox.Column className="col-12">
                            <Form.Group>
                              <Form.Label htmlFor="fullName">Nome</Form.Label>
                              <Form.ValidationField
                                name="fullName"
                                autoFocus
                                id="fullName"
                                autoComplete="off"
                                placeholder="Nome"
                              />
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-12">
                            <Form.Group>
                              <Form.Label htmlFor="email">E-mail</Form.Label>
                              <Form.ValidationField
                                name="email"
                                autoFocus
                                id="email"
                                type="email"
                                autoComplete="off"
                                placeholder="usuario@email.com"
                              />
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-6">
                            <Form.Group>
                              <Form.Label htmlFor="documentNumber">Documento</Form.Label>
                              <Form.ValidationField name="documentNumber">
                                {
                                  ({ field }) => (
                                    <Mask.CpfCnpj
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...field}
                                      placeholder="CPF/CNPJ do usuário"
                                      id="documentNumber"
                                      onAccept={(value) => {
                                        setFieldValue('documentNumber', value)
                                      }}
                                    />
                                  )
                                }
                              </Form.ValidationField>
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-6">
                            <Form.Group>
                              <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
                              <Form.ValidationField name="phoneNumber">
                                {
                                  ({ field }) => (
                                    <Mask.Phone
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...field}
                                      placeholder="(00) 00000-0000"
                                      id="phoneNumber"
                                      onAccept={(value) => {
                                        setFieldValue('phoneNumber', value)
                                      }}
                                    />
                                  )
                                }
                              </Form.ValidationField>
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-6">
                            <Form.Group>
                              <Form.Label htmlFor="birthdayDate">Data de nascimento</Form.Label>
                              <Form.ValidationField name="birthdayDate">
                                {
                                  ({ field }) => (
                                    <Mask.Date
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...field}
                                      placeholder="dd/mm/aaaa"
                                      id="birthdayDate"
                                      onAccept={(value) => {
                                        setFieldValue('birthdayDate', value)
                                      }}
                                    />
                                  )
                                }
                              </Form.ValidationField>
                            </Form.Group>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-12">
                            <label className="form-switch">
                              <Field type="checkbox" name="isOwner" />
                              <i className="form-icon" />
                              Este é um usuário administrador
                            </label>
                          </Flexbox.Column>
                        </Flexbox.Columns>
                      </Modal.Content>
                    </Modal.Body>
                    <Modal.Footer>
                      <button type="submit" className="btn btn-primary">
                        <i className="fas fa-check" />
                        &nbsp;Salvar
                      </button>
                    </Modal.Footer>
                  </Modal.Container>
                </Modal.Base>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </div>
  )
}

EditUser.propTypes = {
  person: PropTypes.objectOf(Object).isRequired,
  onUpdate: PropTypes.func.isRequired,
}
