import {
  ErrorBox, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { RESET_EMAIL_STATUS } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import resetEmails from '@/services/api/resetEmails'
import React from 'react'
import { Link } from 'react-router-dom'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    data, status, error,
  } = useAxiosRequest(resetEmails.list, query)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  const rows = data?.rows || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Lista de solicitações para alteração de e-mail"
        icon="fas fa-envelope"
      />
      <table className="table text-small table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Solicitante</th>
            <th>E-mail</th>
            <th>Data</th>
            <th>Status</th>
            <th className="text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row) => (
              <tr key={row?.id}>
                <td>{row?.id}</td>
                <td>{row?.fullName}</td>
                <td>{row?.email}</td>
                <td>{dateMask(row?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                <td className={RESET_EMAIL_STATUS.class[row?.status]}>
                  {RESET_EMAIL_STATUS.t(row?.status)}
                </td>
                <td className="text-right">
                  <Link
                    to={`/app/reset-emails/${row?.id}`}
                    className="btn btn-action btn-sm btn-info tooltip tooltip-left"
                    data-tooltip="Visualizar"
                  >
                    <i className="fas fa-eye" />
                  </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {
        Boolean(data) && (
          <div className="float-right my-2">
            <Paginator paginatedList={data} />
          </div>
        )
      }
    </Styles.Container>
  )
}

export default List
