import {
  ErrorBox, Flexbox, PageHeader, Styles,
} from '@/components'
import useAxiosRequest, { API_STATUS } from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import animals from '@/services/api/animals'
import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import AdoptionsCard from './components/AdoptionsCard'
import PetCard from './components/PetCard'
import TutorCard from './components/TutorCard'
import CastrationsCard from './components/CastrationsCard'

export default function Show({ title }) {
  usePageTitle({ title })

  const { id } = useParams()
  const { data, status, error } = useAxiosRequest(animals.show, id)

  if (status === API_STATUS.LOADING) {
    return <Styles.Loading className="full-height loading-lg" />
  }

  if (status === API_STATUS.ERROR) {
    return (
      <div className="full-height flex-centered">
        <ErrorBox error={error} />
      </div>
    )
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Pet"
        icon="fas fa-paw"
      />
      <Flexbox.Columns>
        <Flexbox.Column className="col-6 col-md-12 my-2">
          <PetCard pet={data} />
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-md-12 my-2">
          <TutorCard person={data?.tutor} />
        </Flexbox.Column>
        <Flexbox.Column className="col-12 my-2">
          <CastrationsCard castrations={data?.castrations} />
        </Flexbox.Column>
        <Flexbox.Column className="col-12 my-2">
          <AdoptionsCard adoptions={data?.adoptions} />
        </Flexbox.Column>
      </Flexbox.Columns>
    </Styles.Container>
  )
}
