const mapping = {
  requested: {
    title: 'Solicitadas',
    icon: 'fas fa-question',
  },
  rejected: {
    title: 'Rejeitadas',
    icon: 'fas fa-thumbs-down',
  },
  canceled: {
    title: 'Canceladas',
    icon: 'fas fa-ban',
  },
  scheduled: {
    title: 'Agendada',
    icon: 'fas fa-calendar',
  },
  correction: {
    title: 'Em correção',
    icon: 'fas fa-edit',
  },
  inTreatment: {
    title: 'Em tratamento',
    icon: 'fas fa-briefcase-medical',
  },
  corrected: {
    title: 'Corrigidas',
    icon: 'fas fa-edit',
  },
  preApproved: {
    title: 'Pré aprovadas',
    icon: 'fas fa-check-circle',
  },
  approved: {
    title: 'Aprovadas',
    icon: 'fas fa-thumbs-up',
  },
  revision: {
    title: 'Em revisão',
    icon: 'fas fa-glasses',
  },
  attendance: {
    title: 'Em atendimento',
    icon: 'fas fa-clinic-medical',
  },
  awaitingConfirmation: {
    title: 'Aguardando confirmação',
    icon: 'fas fa-user-clock',
  },
  completed: {
    title: 'Concluídas',
    icon: 'fas fa-check',
  },
  all: {
    title: 'Todas as castrações',
    icon: 'fas fa-cut',
  },
}

export default (status) => mapping[status] || mapping.all
