import api from './api'

export default {
  countStatus() {
    return api.get('/castrations/count-status')
  },
  list(data = {}) {
    const { status, ...params } = data
    return api.get(`/castrations/${status}/list`, { params })
  },
  show(id) {
    return api.get(`/castrations/${id}`)
  },
}
