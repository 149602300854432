import styled from 'styled-components'
import { IMaskInput } from 'react-imask'

export const InputMask = styled(IMaskInput).attrs(({ className }) => ({
  className: `form-input ${className}`,
  autoComplete: 'none',
  onChange: null,
  unmask: true,
}))``

export const Phone = styled(InputMask).attrs({
  mask: [{ mask: '(00) 0000-0000' }, { mask: '(00) 0 0000-0000' }],
  type: 'tel',
})``

export const Cpf = styled(InputMask).attrs({
  mask: '000.000.000-00',
  type: 'tel',
})``

export const Cnpj = styled(InputMask).attrs({
  mask: '00.000.000/0000-00',
  type: 'tel',
})``

export const CpfCnpj = styled(InputMask).attrs({
  mask: [{ mask: '000.000.000-00' }, { mask: '00.000.000/0000-00' }],
  type: 'tel',
})``

export const Rga = styled(InputMask).attrs({
  mask: '0000.0000.0000.0000.0000',
  type: 'tel',
})``

export const Date = styled(InputMask).attrs({
  mask: '00/00/0000',
  type: 'tel',
})``
